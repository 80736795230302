import useParseDate from "../useParseDate";

const useParseOffer = () => {

    const parseOffer = (offer) => {

        //activities, name, modalities, validity
        const isLastMinute = {
            value: offer.isLastMinute ? offer.isLastMinute : false,
            error: false,
            errorMessage: false,
            label: "Attribué l’offre",
            description: "Attribuez votre offre spéciale à vos activités sur Frimity.",
            subtitle: "Sélectionnez une activité",
        }

        const status = {
            value: offer.status ? offer.status : "",
            error: false,
            errorMessage: false,
            label: "Statut de l’offre",
            description: "Statut de l’offre",
            subtitle: "Statut de l’offre",
        }

        const activities = {
            value: offer.activityID ? offer.activityID._id : "",
            error: false,
            errorMessage: false,
            label: "Attribué l’offre",
            description: "Attribuez votre offre spéciale à vos activités sur Frimity.",
            subtitle: "Sélectionnez une activité",
            isRequired: true,
        }
        const name = {
            value: offer.name ? offer.name : "",
            error: false,
            errorMessage: false,
            validationType: "text",
            label: "Titre de l'offre",
            placeholder: "Promo de Noël",
            isRequired: true,
        }

        const conditions = {
            value: offer.conditions ? offer.conditions[0] : [
                {
                    conditionValue: "",
                    options: []
                }
            ],
            error: false,
            errorMessage: false,
            label: "Conditions",
            placeholder: "Condition...",
            type: "text",
            validationType: "text",
            isRequired: true,
        }

        const modalities = {
            discount: {
                value: offer.discount ? offer.discount : "",
                error: false,
                errorMessage: false,
                label: "Réduction/Avantage",
                placeholder: "-10€ , -50% ou 1 achèté 1 offert...",
                type: "text",
                validationType: "text",
                isRequired: true,
            },
            quantity: {
                value: offer.quantity ? offer.quantity : "",
                error: false,
                errorMessage: false,
                label: "Quantité disponible de l’offre",
                placeholder: "Choisissez un nombre",
                validationType: "number",
                type: "number",
                isRequired: false,
            },
            personNumber: {
                value: offer.personNumber ? offer.personNumber : "",
                error: false,
                errorMessage: false,
                label: "Pour combien de personnes",
                placeholder: "Choisissez un nombre",
                type: "number",
                validationType: "text",
                isRequired: false,
            }
        }
        const validity = {
            startDate: {
                value: offer.startDate ? offer.startDate : "",
                error: false,
                errorMessage: false,
                label: "Date de début",
                placeholder: "01/01/2024",
                validationType: "date",
                type: "date",
                isRequired: false,
            },
            endDate:{
                value: offer.endDate ? offer.endDate : "",
                error: false,
                errorMessage: false,
                label: "Date de fin",
                placeholder: "02/01/2024",
                validationType: "date",
                type: "date",
                isRequired: false,
            },
            excludedDates: {
                value: offer.excludedDates ? offer.excludedDates : [],
                error: false,
                errorMessage: false,
                label: "Dates exclues",
                placeholder: "Dates exclues",
                validationType: "date",
                type: "date",
                isRequired: false,
            },
            recurringDays: {
                value: offer.recurringDays ? offer.recurringDays : [],
                error: false,
                errorMessage: false,
                label: "Jours récurrents",
                placeholder: "Jours récurrents",
                validationType: "text",
                type: "text",
                isRequired: false,
            },
            lastMinuteTimes: {
                value: offer.lastMinuteTimes ? offer.lastMinuteTimes : [],
                error: false,
                errorMessage: false,
                label: "Horaires de dernière minute",
                placeholder: "Horaires de dernière minute",
                validationType: "text",
                type: "text",
                isRequired: false,
            },
            duration: {
                value: offer.duration ? offer.duration : 0,
                error: false,
                errorMessage: false,
                label: "Durée de l’offre",
                placeholder: "24 heures",
                validationType: "text",
                subtitle: "Modifiable uniquement via les dates de début et de fin",
                isRequired: false,
            }
        }

        const parsedOffer = {
            isLastMinute: isLastMinute,
            status: status,
            activities: activities,
            name: name,
            conditions: conditions,
            modalities: modalities,
            validity: validity
        }

        return parsedOffer;
    }

    const parseOfferForApi = (offer) => {
            //check if offer start date is today
            const parseOffer = {
                name: offer.name ? offer.name.value : "",
                discount: offer.modalities.discount ? offer.modalities.discount.value : "",
                personNumber: offer.modalities.personNumber ? offer.modalities.personNumber.value : "",
                duration: offer.validity.duration ? offer.validity.duration.value : "",
                conditions: offer.conditions ? offer.conditions.value : [],
                quantity: offer.modalities.quantity ? offer.modalities.quantity.value : "",
                startDate: offer.validity.startDate ? offer.validity.startDate.value : "",
                endDate: offer.validity.endDate ? offer.validity.endDate.value : "",
                isLastMinute: offer.isLastMinute &&  offer.isLastMinute.value ? offer.isLastMinute.value : false,
                excludedDates: offer.validity.excludedDates ? offer.validity.excludedDates.value : [],
                recurringDays: offer.validity.recurringDays ? offer.validity.recurringDays : [],
                lastMinuteTimes: offer.validity.lastMinuteTimes ? offer.validity.lastMinuteTimes.value : [],
                isVerified: false,
                activityID: offer.activities ? offer.activities.value : "",
                status: "waiting"
            }

            return parseOffer;
    }

    const parseOfferforUpdate = (offer) => {

        const statusValue = offer.validity && offer.validity.startDate && offer.validity.startDate.value ?
                useParseDate().parseDate(offer.validity.startDate.value) === useParseDate().parseDate(new Date()) ? "active" : "waiting"
                : "";


        const parseOffer = {
            activityID: offer.activities ? offer.activities.value : "",
            name: offer.name ? offer.name.value : "",
            discount: offer.modalities && offer.modalities.discount ? offer.modalities.discount.value : "",
            personNumber: offer.modalities && offer.modalities.personNumber ? offer.modalities.personNumber.value : "",
            conditions: offer.conditions ? offer.conditions.value : "",
            duration: offer.validity && offer.validity.duration ? offer.validity.duration.value : "",
            quantity: offer.modalities && offer.modalities.quantity ? offer.modalities.quantity.value : "",
            startDate: offer.validity && offer.validity.startDate ? offer.validity.startDate.value : "",
            endDate: offer.validity && offer.validity.endDate ? offer.validity.endDate.value : "",
            excludedDates: offer.validity && offer.validity.excludedDates ? offer.validity.excludedDates.value : "",
            recurringDays: offer.validity && offer.validity.recurringDays ? offer.validity.recurringDays : "",
            lastMinuteTimes: offer.validity && offer.validity.lastMinuteTimes ? offer.validity.lastMinuteTimes.value : "",
            isVerified: false,
            status: statusValue
        };

        // Filter out empty values
        const nonEmptyValues = Object.entries(parseOffer)
            .filter(([_, value]) => value !== "" && value !== null && value !== undefined)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        return nonEmptyValues;
    }

    return {parseOffer, parseOfferForApi, parseOfferforUpdate};
}

export default useParseOffer