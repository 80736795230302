import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const StripeServices = {

    getProducts: async () => {
        return await getRequest('/stripe/get-products');
    },

    getProductById: async (data) => {
        return await postRequestJson(`/stripe/get-product`, data);
    },

    getInvoicesByCustomerId: async (data) => {
        return await postRequestJson(`/stripe/get-invoices`, data);
    },

    getConnectedAccounts: async () => {
        return await getRequest(`/stripe/get-connected-accounts`);
    },

    deleteStripeAccount: async (accountId) => {
        return await deleteRequest(`/stripe/delete-connected-account/${accountId}`);
    },

    webhook: async (data) => {
        return await postRequestJson(`/stripe/webhook`, data);
    },
}

export default StripeServices;