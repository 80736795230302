import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Input from "../../../components/form/inputText/input";

const Subtitle = ({subtitle, setActivity, isDisabled = false}) => {

    const handleChange= (event) => {
        event.preventDefault();
        const { value } = event.target;

        setActivity(`subtitle.value`, value);
    }


    return(
        <FormSectionRow title={"Sous titre de l'activité"} isRequired={true}>
            <Input
                name={"subtitle"}
                valueInput={subtitle.value}
                placeholder={subtitle.placeholder}
                validationType={subtitle.validationType}
                onChangeForm={handleChange}
                errorMessage={subtitle.errorMessage}
                errorEmpty={subtitle.error}
                disabled={isDisabled}
            />
        </FormSectionRow>
    )

}

export default Subtitle;