import check from "../../../assets/images/svg/check_round_green.svg";
import Button from "../../../components/button/button";
import React from "react";
import {useParams} from "react-router-dom";
import {
    updatePartnerConnectedAccount,
    userPartnerUpdateBooking,
    userUpdateSubscription
} from "../../../redux/slice/authSlice";
import BookingPartnersServices from "../../../api/services/bookingPartners";
import {useLoader} from "../../../context/loaderContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import '../../../styles/views/payments.css';
import {useSelector} from "react-redux";
import BookingPaymentServices from "../../../api/services/bookingPayment";



const BookingStripeSuccess = () => {

    const { partnerId, connectedAccountId } = useParams();
    const { setLoading } = useLoader();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);

    const handleCreateBookingPartners = async () => {
        setLoading(true);

        try {
            const response = await BookingPartnersServices.createBookingPartner(partnerId, {
                bookingType: "internal",
            });

            const responseStripe = await BookingPaymentServices.checkStatusStripeAccount({
                connectedAccountId,
            });

            if ((response.status === 201 || response.status === 200) && responseStripe.status === 200) {
                const bookingPartner = response.data.bookingPartner;
                dispatch(userPartnerUpdateBooking({ partnerID: partnerId, bookingPartner }));

                const stripeStatus =
                    responseStripe.status === 200 && responseStripe.data.account.charges_enabled;

                // Group partners by connectedAccountId
                const groupedAccounts = user.partnerID.reduce((acc, partner) => {
                    const accountId = partner.subscriptionID?.connectedAccountId;
                    if (!acc[accountId]) {
                        acc[accountId] = [];
                    }
                    acc[accountId].push(partner._id);
                    return acc;
                }, {});

                // Iterate through grouped accounts and update Redux for each group
                Object.keys(groupedAccounts).forEach((accountId) => {
                    const partnerIDs = groupedAccounts[accountId];
                    dispatch(
                        updatePartnerConnectedAccount({
                            partnerIDs,
                            connectedAccountId: accountId,
                            isStripeCompleted: accountId === connectedAccountId ? stripeStatus : false,
                        })
                    );
                });

                // Navigate based on the number of partners
                if (user.partnerID.length === 1) {
                    navigate(`/dashboard/${user.partnerID[0]._id}`);
                } else {
                    navigate(`/dashboard-partners`);
                }
            } else {
                throw new Error(`Failed to create booking partner for partner ${partnerId}`);
            }
        } catch (error) {
            console.error("Error creating booking partners:", error);
            alert("Une erreur s'est produite lors de la configuration. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main stripeBooking">
            <div className="main__container__full">
                <div className="icon__image">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__content">
                    <h1 className={"title-25 center"}>Configuration de votre compte de paiement Stripe réussie</h1>
                    <p className={"text-16 center"}>Vous avez été correctement connecté à notre partenaire de paiement
                        Stripe. Vous avez maintenant accès à la gestion de votre système de réservation et recevoir des
                        réservations des utilisateurs de notre application</p>
                </div>
                <div className="main__container__col32 marginTop">
                <Button text={"Accéder au tableau de bord"} isPrimary={true} isBig={true}
                            onClick={() => handleCreateBookingPartners()}/>
                </div>
            </div>
        </div>
    )
}

export default BookingStripeSuccess;