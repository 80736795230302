import React, { useEffect, useState, useContext } from 'react';
import Form from "../form/form";
import Input from "../form/inputText/input";
import InputIcon from "../form/inputText/inputIcon";
import mailIcon from "../../assets/icones/global/mail.svg";
import phoneIcon from "../../assets/icones/global/phone.svg";
import InputSelect from "../form/inputSelect/inputSelect";
import close from "../../assets/icones/global/close-stroke.svg";
import lock from "../../assets/icones/global/lock.svg";
import '../../styles/components/sidebar.css';
import userParseUser from "../../hooks/parseDataApi/userParseUser";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import useFormValidation from "../../hooks/form/useFormValidation";
import UsersServices from "../../api/services/users";
import PartnersServices from "../../api/services/partners";
import { ErrorContext } from "../../context/errorContext";
import SelectDropdownCustom from "../form/inputSelect/selectDropdownCustom";
import {usePartner} from "../../context/partnerContext";

// Function to generate random password
const generatePassword = (length) => {
    // add letter/number/special character
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
}

const SidebarUser = ({ user = [], type, setRefresh, closeModal, isModalOpen, partnerID = false, isFromAdmin = false }) => {

    const { parseUserUpdate, parseUser, parseUserForApi, parseUserForApiUpdate } = userParseUser();

    // Initialize form state and include partnerID if it's provided
    const initialFormState = type === "add" ? parseUser(user) : parseUserUpdate(user);
    if (type === "add" && partnerID) {
        initialFormState.partnerID = {
            value: [partnerID],
            error: false,
            errorMessage: "",
        };
    }

    const { formState, setFormState, handleFormChange, getFormChanges } = useFormUpdate(initialFormState);
    const { validateForm } = useFormValidation();

    const [partners, setPartners] = useState();
    const [users, setUsers] = useState([]);
    const [userPartners, setUserPartners] = useState(partnerID ? [{ _id: partnerID, name: "Partner Name" }] : []);


    const [usersAssociated, setUsersAssociated] = useState([]);
    const { showError } = useContext(ErrorContext);


    useEffect(() => {
        // Generate password for admin if needed and directly set it in form state
        if (isFromAdmin && type === "add") {
            const generatedPassword = generatePassword(20);
            setFormState((prevState) => ({
                ...prevState,
                hasTemporaryPassword: true,
                password: {
                    value: generatedPassword,
                    error: false,
                    errorMessage: "",
                },
                passwordConfirm: {
                    value: generatedPassword,
                    error: false,
                    errorMessage: "",
                },
            }));
        }

        const fetchPartners = async () => {
            try {
                const response = await PartnersServices.getAllPartners();
                if (response.status === 200) {
                    const allPartners = response.data; // All available partners
                    setPartners(allPartners);

                    // Map user.partnerID to match fetched partners or fallback if not found
                    const associatedPartners = user.partnerID.map((partner) => {
                        const foundPartner = allPartners.find((p) => p._id === partner._id);
                        return foundPartner || { _id: partner._id, name: partner.name || "Unknown Partner" };
                    });

                    // Update `userPartners` state with associated partners only
                    setUserPartners(associatedPartners);

                    // Update `formState` to reflect associated partner IDs
                    setFormState((prevState) => ({
                        ...prevState,
                        partnerID: {
                            value: associatedPartners.map((partner) => partner._id), // Selected IDs
                            error: false,
                            errorMessage: "",
                        },
                    }));
                }
            } catch (error) {
                console.error("Failed to fetch partners:", error);
            }
        };

        fetchPartners();
    }, []);

    const handleSelectPartnerChange = (updatedSelectedValues, typeAction) => {
        if (typeAction === "add") {
            // Filter only new partners that are not already in userPartners
            const newPartners = updatedSelectedValues
                .filter((id) => !userPartners.some((partner) => partner._id === id))
                .map((id) => partners.find((partner) => partner._id === id));

            if (newPartners.length > 0) {
                setUserPartners((prevPartners) => [...prevPartners, ...newPartners]);

                setFormState((prevState) => ({
                    ...prevState,
                    partnerID: {
                        ...prevState.partnerID,
                        value: updatedSelectedValues,
                    },
                }));
            }
        } else if (typeAction === "remove") {
            // Remove partners that are no longer selected
            const remainingPartners = userPartners.filter((partner) =>
                updatedSelectedValues.includes(partner._id)
            );

            setUserPartners(remainingPartners);

            setFormState((prevState) => ({
                ...prevState,
                partnerID: {
                    ...prevState.partnerID,
                    value: updatedSelectedValues,
                },
            }));
        }
    };



    const handleAddUser = (event) => {
        event.preventDefault();

        const updatedFormState = {
            ...formState,
            partnerID: {
                ...formState.partnerID,
                value: partnerID ? [partnerID] : userPartners.map((partner) => partner._id),
            },
            hasTemporaryPassword: isFromAdmin
        };

        const isFormValid = validateForm(updatedFormState, setFormState);

        if (updatedFormState.password.value !== updatedFormState.passwordConfirm.value) {
            setFormState({
                ...updatedFormState,
                passwordConfirm: {
                    ...updatedFormState.passwordConfirm,
                    errorMessage: "Les mots de passe ne correspondent pas",
                },
            });

            isFormValid.isFormValid = false;
        }

        if (isFormValid.isFormValid) {

            const userForApi = parseUserForApi(updatedFormState);

            UsersServices.createUser(userForApi)
                .then((response) => {
                    if (response.status === 200) {
                        setRefresh(true);
                        closeModal();
                    }
                })
                .catch((error) => {
                    showError(error);
                });
        }
    };

    const handleUpdateUser = async (event) => {
        event.preventDefault();
        setFormState({
            ...formState,
            partnerID: {
                ...formState.partnerID,
                value: userPartners,
            },
        });
        const isFormValid = validateForm(formState, setFormState);

        if (isFormValid.isFormValid) {
            const userForApi = parseUserForApiUpdate(formState);
            UsersServices.updateUser(user._id, userForApi)
                .then((response) => {
                    if (response.status === 200) {
                        setRefresh(true);
                        closeModal();
                    }
                })
                .catch((error) => {
                    showError(error);
                });
        } else {
            showError("Le formulaire n'est pas valide");
        }
    };

    const handleAssociate = async () => {
        try {
            const usersId = usersAssociated.map((user) => user._id);

            const response = await UsersServices.associateUsersWithPartner(partnerID, { users: usersId });

            if (response.status === 200) {
                setRefresh(true);
                closeModal();
            }
        } catch (error) {
            showError(error);
        }
    }

    return (
        <div className={`bg_sidebar ${isModalOpen ? "open" : ""}`}>
            <div className={`sidebar ${isModalOpen ? "open" : ""}`}>
                <div className={"sidebar__container"}>
                    <div className={"sidebar__container__user"}>
                        <div className={"sidebar__container__user__avatar"}>
                            <h2 className={"title-16"}>{type === "add" ? "Ajouter un utilisateur" : type === "edit" ? "Modifier un utilisateur" : "Associer des utilisateurs existants"}</h2>
                            <img src={close} alt={"Fermer"} className={"sidebar__container__user__avatar__close"} onClick={closeModal} />
                        </div>
                        <div className={"sidebar__container__user__infos"}>
                            <Form
                                children={
                                    <>
                                        <Input
                                            label={"Prénom"}
                                            name={"firstname"}
                                            type={"text"}
                                            placeholder={"Jean"}
                                            valueInput={formState.firstname.value}
                                            isColumn={true}
                                            validationType={"text"}
                                            errorMessage={formState.firstname.errorMessage}
                                            errorEmpty={formState.firstname.error}
                                            onChangeForm={(e) => handleFormChange(`firstname.value`, e.target.value)}
                                        />
                                        <Input
                                            label={"Nom"}
                                            name={"lastname"}
                                            type={"text"}
                                            placeholder={"Didier"}
                                            errorEmpty={formState.lastname.error}
                                            errorMessage={formState.lastname.errorMessage}
                                            valueInput={formState.lastname.value}
                                            isColumn={true}
                                            validationType={"text"}
                                            onChangeForm={(e) => handleFormChange(`lastname.value`, e.target.value)}
                                        />
                                        <InputIcon
                                            title={"Email"}
                                            name={"email"}
                                            type={"email"}
                                            placeholder={"j.didier@gmail.com"}
                                            valueInput={formState.email.value}
                                            isColumn={true}
                                            icon={mailIcon}
                                            validationType={"email"}
                                            errorMessage={formState.email.errorMessage}
                                            errorEmpty={formState.email.error}
                                            onChangeForm={(e) => handleFormChange(`email.value`, e.target.value)}
                                        />
                                        <InputIcon
                                            title={"Téléphone"}
                                            name={"phoneNumber"}
                                            type={"text"}
                                            placeholder={"0876565765"}
                                            valueInput={formState.phoneNumber.value}
                                            validationType={"phone"}
                                            errorMessage={formState.phoneNumber.errorMessage}
                                            errorEmpty={formState.phoneNumber.error}
                                            isColumn={true}
                                            icon={phoneIcon}
                                            onChangeForm={(e) => handleFormChange(`phoneNumber.value`, e.target.value)}
                                        />
                                        {isFromAdmin && type === "edit" ? (
                                            <SelectDropdownCustom
                                                label="Établissements"
                                                options={partners || []} // All partners
                                                selectedValues={userPartners.map((partner) => partner._id)} // Preselected partner IDs
                                                onChange={(updatedSelectedValues) => {
                                                    const currentSelections = formState.partnerID.value;
                                                    const typeAction = updatedSelectedValues.length > currentSelections.length ? "add" : "remove";
                                                    handleSelectPartnerChange(updatedSelectedValues, typeAction);
                                                }}
                                            />


                                        ) : (
                                        isFromAdmin && type === "add" ? (
                                            <>
                                                <InputIcon
                                                    title={"Mot de passe"}
                                                    icon={lock}
                                                    name={"password"}
                                                    placeholder={"Mot de passe généré"}
                                                    valueInput={formState.password.value}
                                                    errorEmpty={formState.password.error}
                                                    errorMessage={formState.password.errorMessage}
                                                    validationType={"password"}
                                                    isColumn={true}
                                                    onChangeForm={(e) => handleFormChange(`password.value`, e.target.value)}
                                                />
                                                <SelectDropdownCustom
                                                    label="Établissements"
                                                    options={partners || []} // All partners
                                                    selectedValues={userPartners.map((partner) => partner._id)} // Preselected partner IDs
                                                    onChange={(updatedSelectedValues) => {
                                                        const currentSelections = formState.partnerID.value;
                                                        const typeAction = updatedSelectedValues.length > currentSelections.length ? "add" : "remove";
                                                        handleSelectPartnerChange(updatedSelectedValues, typeAction);
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            !isFromAdmin && type === "add" && (
                                                <>
                                                    <InputIcon
                                                        title={"Mot de passe"}
                                                        icon={lock}
                                                        name={"password"}
                                                        placeholder={"Mot de passe"}
                                                        valueInput={formState.password.value}
                                                        errorEmpty={formState.password.error}
                                                        errorMessage={formState.password.errorMessage}
                                                        validationType={"password"}
                                                        isColumn={true}
                                                        onChangeForm={(e) => handleFormChange(`password.value`, e.target.value)}
                                                    />
                                                    <InputIcon
                                                        title={"Confirmation mot de passe"}
                                                        icon={lock}
                                                        name={"passwordConfirm"}
                                                        placeholder={"Confirmation mot de passe"}
                                                        valueInput={formState.passwordConfirm.value}
                                                        errorEmpty={formState.passwordConfirm.error}
                                                        errorMessage={formState.passwordConfirm.errorMessage}
                                                        isColumn={true}
                                                        validationType={"password"}
                                                        onChangeForm={(e) => handleFormChange(`passwordConfirm.value`, e.target.value)}
                                                    />
                                                </>
                                            )
                                        ))}
                                    </>
                                }
                                onSubmit={type === "add" ? (e) => handleAddUser(e) : (e) => handleUpdateUser(e)}
                                mode={type !== "add" ? "edit" : "add"}
                                cancelledNavigate={() =>  closeModal()}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SidebarUser;
