import React, {useEffect, useState} from 'react';
import Button from "../button/button";
import check from "../../assets/icones/global/check_circle.svg";
import useSubscriptionName from "../../hooks/parseText/useSubscriptionName";
import '../../styles/components/stripe.css';
import ModalNotSubscription from "../modal/modalInfos/modalNotSubscription";

const SubscriptionCard = ({subscription, active, activePlan, plan, isFirst = false, onClick, isButton = true}) => {

    const {getSubscriptionName} = useSubscriptionName();

    const [currentPlan, setCurrentPlan] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (subscription && activePlan !== null) {
            setCurrentPlan(getPlanLevel(activePlan));
        }
    }, []);

    const getPlanLevel = (planName) => {
        switch(planName) {
            case "essentiel":
                return 1;
            case "professional":
                return 2;
            case "premium":
                return 3;
            default:
                return 0;
        }
    };


    return (
        <div className={`subscription__plan ${active ? "active" : ""}`} style={{borderColor:  "#FFD700"}}>
            <div className={"subscription__plan__header"}>
                <div className={"subscription__plan__header__infos"}>
                    <div className={"subscription__plan__title"}>
                        <p className={"text-20 bold"}>{getSubscriptionName(plan.name)}</p>

                    </div>
                    {
                        plan.name === "essentiel" ? null :
                            plan.name === "professional" ? <div className={"subscription__plan__discount"}>-30%</div> :
                                plan.name === "premium" ? <div className={"subscription__plan__discount"}>-20%</div> :
                                    null
                    }

                </div>

                <div className={"subscription__plan__price"}>
                    {plan.commission}% <span className={"text-14"}>de commission</span>
                </div>
                <p className={"text-14 light"}>* sur chaque réservation effectuée via Frimity</p>

                {
                    isButton && isFirst &&
                        <Button text={
                            plan.name === "essentiel" ? "Choisir le plan essentiel 🚀" :
                                plan.name === "professional" ? "Plan pro 🚀" :
                                    plan.name === "premium" ? "Plan premium 🚀" : null
                        } isPrimary={true} isFull={true} onClick={onClick}/>
                }
            </div>
            <div className={"subscription__plan__content"}>
                {
                    plan.avantages.map((avantage, index) => {
                        return (
                            <div key={index} className={"subscription__plan__content__avantages"}>
                                <p className={"text-20 bold"}>{avantage.name}</p>
                                <ul className={"subscription__plan__list"}>
                                    {
                                        avantage.list.map((list, index) => {
                                            return (
                                                <li key={index} className={"list_check"}><img src={check} alt={"check"}/>{list}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
            <ModalNotSubscription isOpen={isModalOpen} setModalOpen={setIsModalOpen}/>
        </div>
    )

}

export default SubscriptionCard;