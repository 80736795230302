import React, {useState} from 'react';
import '../../../styles/components/modal.css';
import error from "../../../assets/icones/global/shield_error.svg";
import validate from "../../../assets/icones/global/shield_validate.svg";
import close from "../../../assets/icones/global/close-stroke.svg";
import ModalVerifiedStatus from "./modalVerifiedStatus";
import useOpenModal from "../../../hooks/useOpenModal";
import {useNavigate} from "react-router-dom";
import ModalDeleting from "../modalDeleting/modalDeleting";

const ModalActivity = ({isOpen, closeModal, handleDuplicate, handleDelete, handleDraft, handleVerify, isDraft}) => {

    const [modalOpen, setModalOpen] = useState(false);

    return(
        <>
            <div className={`modal offer activity ${isOpen ? "open" : ""}`}>
                <div className={"modal__header grey"}>
                    <p className={"text-14"}>Status de l'activité</p>
                    <div className={"modal__header__actions"}>
                        <p className={"title-14"} onClick={closeModal}>
                            <img src={close} alt={"close"}/>
                        </p>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content"}>
                        {handleVerify && handleVerify !== {} &&
                            <p className={"text-14 offer_div"} onClick={() => {
                                handleVerify();
                                closeModal();
                            }}>Vérifier l'activitée</p>
                        }
                        {!isDraft &&
                            <p className={"text-14 offer_div"} onClick={() => {
                                handleDraft();
                                closeModal();
                            }}>Mettre en brouillon</p>
                        }
                        <p className={"text-14 offer_div"} onClick={() => {
                            handleDuplicate();
                            closeModal();
                        }}>Dupliquer</p>
                        <p className={"text-14 offer_div"} onClick={() => setModalOpen(true)}>Supprimer</p>
                    </div>
                </div>
        </div>
    <ModalDeleting isOpen={modalOpen} closeModal={() => setModalOpen(false)} deleteItem={handleDelete}
                   itemName={"l'activité"}/>

</>
    )
}

export default ModalActivity;
