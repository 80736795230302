import React, {useEffect, useState} from 'react';
import key from '../../../assets/images/svg/rocket_icon.svg';
import { Link } from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import StepBar from "../../../components/stepBar/stepBar";
import {useParams} from "react-router";
import check from "../../../assets/icones/global/yellow_check.svg";
import Button from "../../../components/button/button";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useStep} from "../../../context/login/stepContext";



const FirstLoginInfos = () => {
    // Use stepsConfig in your hook
    const { step, stepArray, nextStep, setStep } = useStep();
    const user = useSelector((state) => state.auth.user);

    const navigate = useNavigate();

    const handleStart = () => {
        nextStep(user, navigate);
    };


    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(step => step.label)} />
            </div>
            <div className="main__container__col width-400">
                <div className="main__container__col32">
                    <img src={key} alt="logo"/>
                </div>
                <div className="main__container__col32 center">
                    <h1 className={"title-25"}>Bienvenue sur votre espace client</h1>
                    <p className={"text-14 light"}>
                        Avant de pouvoir accéder à votre espace client, nous avons besoin que vous remplissiez
                        ces {stepArray.length} étapes pour finaliser votre compte.
                    </p>
                </div>

                <div className="main__container__col32 noMaxWidth">
                    <ul className={"subscription__plan__list"}>
                        {stepArray.map((step, index) => (
                            <li key={index} className={"subscription__plan__list__item"}>
                                <img src={check} alt="check"/>
                                <p className={"text-14"}>{step.label}</p> {/* Use `label` for display */}
                            </li>
                        ))}
                    </ul>
                </div>

                <Button text={"Commencer"} onClick={handleStart}
                        isFull={true} isPrimary={true}/>

            </div>
        </div>
    );
}

export default FirstLoginInfos;