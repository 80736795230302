import React from 'react';
import useInputValidation from "../../../hooks/form/useInputValidation";
import nbPersons from "../../../views/activities/formActivity/nbPersons";

const Input = ({type, label, className = false, name, placeholder, errorEmpty = false, errorMessage = false, validationType, onChangeForm, valueInput, isColumn, readOnly, subtitle= false, disabled = false, isRequired = false}) => {

    const { value, error, onChange } = useInputValidation('', validationType);

    const handleChange = (event) => {
        onChange(event);
        onChangeForm(event);
    }

    const changeNumberBelowZero = (event) => {
        if(event.target.value < 0) {
            event.target.value = 0;
        }
    }

    // Format the value for display with a € symbol
    const formatValueForDisplay = (value) => {
        return `${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}€`;
    }

    return(
        <div className={`form__row ${isColumn ? 'column' : ''} ${disabled ? "disabled" : ""} ${className ? className : ""}`}>
            {
                label ? <label htmlFor={name} className="form__row__label">{label} {isRequired ? <span className={"is__required"}>*</span> : null}</label> : null
            }
            <div className="form__col">
                <input
                    type={type}
                    className={`form__input ${error || errorMessage ? 'error' : ''} ${disabled ? "disabled" : ""} ${errorEmpty ? 'error' : ''} ${readOnly ? 'readOnly' : ''}`}
                    name={name}
                    id={name}
                    placeholder={errorEmpty || errorMessage ? "Remplissez ce champ" : placeholder}
                    value={valueInput !== undefined ? valueInput : value} // Prioritize valueInput if provided
                    onChange={(event) => {
                        if(type === 'number') {
                            changeNumberBelowZero(event);
                        }
                        handleChange(event);
                    }}
                    readOnly={readOnly}
                    disabled={disabled}
                    min={type === 'number' ? 0 : null}
                />
                {name === "price" && <span className="price-symbol">€</span>}
                {subtitle && <p className={"text-12 subtitle"}>{subtitle}</p>}
                {error ? <div className="error-message">{error}</div> : null}
                {name === "postalCode" || name === "siretNumber" ? <p className={"error-message"}>{errorMessage}</p> : null}
                { name === "nbPersonsMin" || name === "nbPersonsMax" && error ? <p className={"error-message"}>Le nombre de personnes minimum doit être inférieur au nombre de personnes maximum</p> : null}
            </div>
        </div>
    )

}

export default Input;