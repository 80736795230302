import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const BookingPartnersServices = {

        getBookingPartners: async () => {
            return await getRequest('/booking-partners');
        },

        getBookingPartnerById: async (bookingPartnerId) => {
            return await getRequest(`/booking-partners/${bookingPartnerId}`);
        },

        createBookingPartner: async (partnerID, data) => {
            return await postRequestJson(`/booking-partners/create/${partnerID}`, data);
        },

        updateBookingPartner: async (bookingPartnerId, data) => {
            return await patchRequest(`/booking-partners/update/${bookingPartnerId}`, data);
        },

        deleteBookingPartner: async (bookingPartnerId) => {
            return await deleteRequest(`/booking-partners/${bookingPartnerId}`);
        }
}

export default BookingPartnersServices;