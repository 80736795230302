import React, {useContext, useEffect, useState} from 'react';
import key from '../../../assets/images/svg/rocket_icon.svg';
import '../../../styles/views/verifications.css';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Button from "../../../components/button/button";
import {useStripeAccountToken} from "../../../hooks/stripe/useStripeToken";
import {useLoader} from "../../../context/loaderContext";
import {ErrorContext} from "../../../context/errorContext";
import BookingPaymentServices from "../../../api/services/bookingPayment";
import {useDispatch} from "react-redux";
import {updatePartnerConnectedAccount} from "../../../redux/slice/authSlice";
import {useParams} from "react-router";

const groupByConnectedAccount = (partners) => {
    return partners.reduce((acc, partner) => {
        const accountId = partner.subscriptionID?.connectedAccountId;
        if (accountId) {
            if (!acc[accountId]) {
                acc[accountId] = []; // Initialize as an array
            }
            acc[accountId].push(partner); // Add partner to the array
        }
        return acc;
    }, {});
};

const BookingStripeAssociate = () => {

    const { partnerID } = useParams();
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { createAccountToken, token } = useStripeAccountToken();
    const [email, setEmail] = useState(user.email);
    const userPartner = useSelector((state) => state.auth.user.partnerID);

    const { setLoading } = useLoader();
    const { showError } = useContext(ErrorContext);

    const [partnerNoAccount, setPartnerNoAccount] = useState(userPartner.filter(partner => partner._id === partnerID)[0]);
    const [selectedRadio, setSelectedRadio] = useState(""); // State to track selected radio
    const [selectedPartners, setSelectedPartners] = useState({}); // Track selected partners
    const [groupedPartners, setGroupedPartners] = useState({});


    useEffect(() => {
        const grouped = groupByConnectedAccount(user.partnerID);
        setGroupedPartners(grouped);
    }, [user.partnerID]);

    const handleCheckboxChange = (partnerId) => {
        setSelectedPartners((prevState) => ({
            ...prevState,
            [partnerId]: !prevState[partnerId], // Toggle the selection
        }));
    };

    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value); // Update selected radio state
    };

    const handleSubmit = async () => {
        try {
            // Get selected partner IDs
            const selectedPartnerIds = [partnerNoAccount._id];

            // Validate selected partners
            if (selectedPartnerIds.length === 0) {
                alert("Veuillez sélectionner au moins un établissement.");
                return;
            }

            // Validate radio selection
            if (!selectedRadio) {
                alert("Veuillez sélectionner une option (compte existant ou nouveau compte).");
                return;
            }

            //Handle existing account flow
            if (selectedRadio === "existing") {
                // Ensure a connected account is selected
                const selectedAccountId = Object.keys(groupedPartners).find(
                    (accountId) => selectedPartners[accountId]
                );

                if (!selectedAccountId) {
                    alert("Veuillez sélectionner un compte Stripe existant.");
                    return;
                }

                setLoading(true);

                // Call the server to associate the single partner
                const response = await BookingPaymentServices.associateConnectAccount({
                    partnerID: partnerID,
                    connectedAccountId: selectedAccountId});

                if (response.status === 200) {
                    // Update Redux store with the new connectedAccountId for the partner
                    dispatch(
                        updatePartnerConnectedAccount({
                            partnerIDs: [partnerID], // Update this single partner
                            connectedAccountId: selectedAccountId
                        })
                    );

                    navigate(`/booking-stripe/success/${partnerID}/${selectedAccountId}`);
                } else {
                    throw new Error("Failed to associate partner with the existing account.");
                }
            } else if (selectedRadio === "new") {
                setLoading(true);

                // Create account token for the first selected partner
                const firstPartner = user.partnerID.find(
                    (partner) => partner._id === selectedPartnerIds[0]
                );

                const accountTokenResponse = await createAccountToken(firstPartner, true);

                if (!accountTokenResponse) {
                    throw new Error("Failed to create account token.");
                }

                const response = await BookingPaymentServices.createConnectAccount({
                    email: user.email,
                    accountToken: accountTokenResponse,
                    partnerIDs: selectedPartnerIds,
                    refreshUrl: `${process.env.REACT_APP_URL}/booking-stripe/associate/${partnerID}`,
                    returnUrl: `${process.env.REACT_APP_URL}/booking-stripe/success/${partnerID}`,
                });

                if (response.status === 200) {

                    const connectedAccountId = response.data.connectedAccountId;
                    // Update Redux for all selected partners
                    dispatch(updatePartnerConnectedAccount({ partnerIDs: selectedPartnerIds, connectedAccountId }));
                    // Redirect to Stripe onboarding link
                    window.location.href = response.data.url;
                } else {
                    throw new Error("Failed to create account onboarding link.");
                }
            }
        } catch (e) {
            console.error(e);
            showError("Une erreur s'est produite lors du traitement de votre demande.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="main verification connectAccount">
            <div className="main__container__col width-400">
                <div className="main__container__col32">
                    <img src={key} alt="logo"/>
                </div>
                <div className="main__container__col32 center">
                    <h1 className={"title-25"}>Finalisez votre compte de paiement pour <span className={"is__yellow"}>{partnerNoAccount.name}</span></h1>
                </div>

                {
                    groupedPartners && Object.keys(groupedPartners).length > 0 && (
                        <div className={"form__col full gap-30"}>
                            <div>
                                <p className={"text-16"}>
                                    {Object.keys(groupedPartners).length === 1
                                        ? 'Un compte de paiement Stripe est déjà associé à un de vos établissements. Que souhaitez-vous faire ?'
                                        : 'Des comptes de paiement Stripe sont déjà associés à certains de vos établissements. Que souhaitez-vous faire ?'
                                    }
                                </p>
                            </div>

                            <div className="form__radio__group">
                                <div className={`form__radio ${selectedRadio === "existing" ? "selected" : ""}`}>
                                    <input
                                        type="radio"
                                        id="existing"
                                        name="account"
                                        value="existing"
                                        checked={selectedRadio === "existing"}
                                        onChange={handleRadioChange}
                                    />
                                    <div className={"form__radio__label"}>
                                        <label htmlFor="existing" className="text-14 bold">
                                            Associer à un compte existant
                                        </label>
                                    </div>
                                </div>
                                <div className={`form__radio ${selectedRadio === "new" ? "selected" : ""}`}>
                                    <input
                                        type="radio"
                                        id="new"
                                        name="account"
                                        value="new"
                                        checked={selectedRadio === "new"}
                                        onChange={handleRadioChange}
                                    />
                                    <div className={"form__radio__label"}>
                                        <label htmlFor="new" className="text-14">
                                            Créer un nouveau compte indépendant
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {
                                selectedRadio === "existing" && (
                                    <div className={"form__col full"}>
                                        <p className={"text-14"}>Sélectionnez l'établissement auquel vous souhaitez associer ce
                                            compte :</p>
                                        <div className={"form__connectedAccount"}>
                                            {
                                                Object.keys(groupedPartners).map((accountId, index) => {
                                                    const partners = groupedPartners[accountId]; // Should be an array
                                                    return (
                                                        <div key={index} className="stripe_partner_item">
                                                            <input
                                                                type="checkbox"
                                                                name={`partner-${accountId}`}
                                                                id={`partner-${accountId}`}
                                                                checked={selectedPartners[accountId]}
                                                                onChange={() => handleCheckboxChange(accountId)}
                                                            />
                                                            <div className="stripe_partner_item__content">
                                                                <p className="text-14 bold">Compte Stripe {accountId}</p>
                                                                <p className="text-12">{partners.length} établissement(s):</p>
                                                                <p className="text-12">{partners.map((partner) => partner.name).join(", ")}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }


                <Button text={"Suivant"} isFull={true} isPrimary={true} onClick={() => {
                    handleSubmit();
                }}/>

            </div>
        </div>
    );
}

export default BookingStripeAssociate;