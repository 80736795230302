import React, {useContext, useState, useEffect} from "react";
import useStatusName from "../../../hooks/parseText/useStatusName";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import useOpenModal from "../../../hooks/useOpenModal";
import useFilter from "../../../hooks/useFilter";
import HeaderView from "../../../components/header/headerView";
import BookingPartnersServices from "../../../api/services/bookingPartners";
import FilterTable from "../../../components/table/filterTable";
import Table from "../../../components/table/table";
import Tag from "../../../components/tag/tag";
import Button from "../../../components/button/button";
import Dot from "../../../components/dot/dot";
import ModalActivity from "../../../components/modal/modalTable/modalActivity";

import refreshIcon from "../../../assets/icones/arrows/refresh_gray.svg";
import arrow_down from "../../../assets/icones/arrows/arrow_down_red.svg";
import arrow_up from "../../../assets/icones/arrows/arrow_up_green.svg";
import RoundStat from "../../../components/stats/roundStat";
import CardStat from "../../../components/stats/cardStat";
import ModalBookingManager from "../../../components/modal/modalTable/modalBookingManager";

const BookingsPartnersManager = () => {
    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);

    const [bookingsAccountList, setBookingsAccountList] = useState([]);
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading, loading } = useLoader();

    const [isModalOpen, openModal, closeModal] = useOpenModal(bookingsAccountList.map((item) => false));

    const { updateFilters, filteredData } = useFilter([], bookingsAccountList, (stripeAccount, filters, query) => {
        const matchesFilters = filters.length === 0 || filters.includes(stripeAccount.status);
        const matchesQuery = query === '' ||
            stripeAccount?.partnerID?.name?.toLowerCase().includes(query.toLowerCase()) || // Partner Name
            stripeAccount?.partnerID?.engineID?.bookingEmail?.toLowerCase().includes(query.toLowerCase()); // Email Referent

        return matchesFilters && matchesQuery;
    });


    useEffect(() => {
         const fetchBookingsAccount = async () => {
            setLoading(true);
            try {
                const response = await BookingPartnersServices.getBookingPartners();
                if (response.status === 200) {
                    //add a data fiiels to the response
                    response.data.map((item) => {
                        item.bookings = item.bookingUsersIDs && item.bookingUsersIDs.length;
                        item.status = item.bookingtimeSlotIDs && item.bookingtimeSlotIDs.length > 0 ? "active" : "not-completed";
                    });
                    setBookingsAccountList(response.data);
                    setFilterList(['active', 'not-completed']);
                }
            } catch (error) {
                showError("Erreur lors de la récupération des données");
            }
            setLoading(false);
         }

            fetchBookingsAccount();
    }, [refresh]);


    return !loading && (
        <div className={"main-container"}>
            <HeaderView title={"Gestion des réservations  ⚙️"} isGoBack={false} actions={<></>}/>

            <div className={"main-container__row"}>
                <div className={"flex_row stats_rounds"}>
                    <div className={"flex_col"}>
                        <div className={"stats_title"}>Réservations totales</div>
                        <RoundStat title={"total"}
                                   value={bookingsAccountList.map((item) => item.bookingUsersIDs && item.bookingUsersIDs.length).reduce((a, b) => a + b, 0)}
                                   color={"#FFC400"}/>
                    </div>
                    <div className={"flex_col"}>
                        <div className={"stats_title"}>Réservations complétées</div>
                        <RoundStat title={"complété"}
                                   value={bookingsAccountList.map((item) => item.bookingUsersIDs && item.bookingUsersIDs.length > 0 ? item.bookingUsersIDs.map((bookingUser) => bookingUser.bookingStatus === 'completed' ? 1 : 0).reduce((a, b) => a + b, 0) : 0).reduce((a, b) => a + b, 0)}
                                   color={"#FFE797"}/>
                    </div>
                </div>

                <div className={"flex_col stats_cards"}>
                    <div className={"grid grid_2"}>
                        <CardStat title={"Complété"}
                                  value={bookingsAccountList.map((item) => item.bookingUsersIDs && item.bookingUsersIDs.length > 0 ? item.bookingUsersIDs.map((bookingUser) => bookingUser.bookingStatus === 'completed' ? 1 : 0).reduce((a, b) => a + b, 0) : 0).reduce((a, b) => a + b, 0)}
                                  color={"#FFC400"}
                                list={bookingsAccountList.filter((item) => item.bookingUsersIDs && item.bookingUsersIDs.length > 0 ? item.bookingUsersIDs.map((bookingUser) => bookingUser.bookingStatus === 'completed' ? 1 : 0).reduce((a, b) => a + b, 0) > 0 : 0)}
                                titleModal={"Réservations complétées"} status={"completed"}/>
                        <CardStat title={"En cours"}
                                    value={bookingsAccountList.map((item) => item.bookingUsersIDs && item.bookingUsersIDs.length > 0 ? item.bookingUsersIDs.map((bookingUser) => bookingUser.bookingStatus === 'in-progress' || bookingUser.bookingStatus === 'booked' || bookingUser.bookingStatus === 'waiting-date' ? 1 : 0).reduce((a, b) => a + b, 0) : 0).reduce((a, b) => a + b, 0)}
                                    color={"#FFE797"}
                                    list={bookingsAccountList.filter((item) => item.bookingUsersIDs && item.bookingUsersIDs.length > 0 ? item.bookingUsersIDs.map((bookingUser) => bookingUser.bookingStatus === 'in-progress' || bookingUser.bookingStatus === 'booked' || bookingUser.bookingStatus === 'waiting-date' ? 1 : 0).reduce((a, b) => a + b, 0) > 0 : 0)}
                                    titleModal={"Réservations en attente"} status={"in-progress"}/>
                        <CardStat title={"Annulée"}
                                    value={bookingsAccountList.map((item) => item.bookingUsersIDs && item.bookingUsersIDs.length > 0 ? item.bookingUsersIDs.map((bookingUser) => bookingUser.bookingStatus === 'cancelled' || bookingUser.bookingStatus === 'pastDate' || bookingUser.bookingStatus === 'user-cancelled' || bookingUser.bookingStatus === 'cancelledForReason' ? 1 : 0).reduce((a, b) => a + b, 0) : 0).reduce((a, b) => a + b, 0)}
                                    color={"#DA8D1A"}
                                    list={bookingsAccountList.filter((item) => item.bookingUsersIDs && item.bookingUsersIDs.length > 0 ? item.bookingUsersIDs.map((bookingUser) => bookingUser.bookingStatus === 'cancelled' || bookingUser.bookingStatus === 'pastDate' || bookingUser.bookingStatus === 'user-cancelled' || bookingUser.bookingStatus === 'cancelledForReason' ? 1 : 0).reduce((a, b) => a + b, 0) > 0 : 0)}
                                    titleModal={"Réservations annulées"} status={"cancelled"}/>
                    </div>
                </div>

            </div>


            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h2 className={"title-18"}>Listes des réservations des partenaires </h2>
                    <p className={"text-14"}>Ici, tu retrouveras toutes les réservation reçu au partenaires Frimity</p>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 &&
                    <FilterTable
                        filterArray={filterList}
                        onFilterChange={updateFilters}
                        typeValue={"status"}
                        searchData={bookingsAccountList}
                    />
                }

                {
                    filteredData && filteredData.length > 0 ?
                        <Table
                            columns={[
                                {name: "Partenaire", align: "left", width: "10%", field: "partner"},
                                {name: "Référent", align: "left", width: "20%", field: "email"},
                                {name: "Réservation", align: "left", width: "30%", field: "bookings"},
                                {name: "Status", align: "left", width: "18%", field: "status"},
                                {
                                    name: "Actions", align: "center", width: "10%", style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }, field: "actions"}
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.map((booking, index) => {
                                        return {
                                            partner: booking?.partnerID?.name,
                                            email: booking?.partnerID?.engineID?.bookingEmail,
                                            bookings: <div className={"bookings-table-tags"}>
                                                <div className={"tag-row"}>
                                                    <div className={"tag-icon grey"}>
                                                        <img src={refreshIcon} alt={"refresh"}/>
                                                        {booking?.bookingUsersIDs && booking?.bookingUsersIDs?.length > 0 ? booking.bookingUsersIDs.map((bookingUser) => {
                                                            return bookingUser.bookingStatus === 'in-progress' || bookingUser.bookingStatus === 'booked' || bookingUser.bookingStatus === 'waiting-date' ? 1 : 0;
                                                        }).reduce((a, b) => a + b, 0) : 0}
                                                    </div>
                                                    <p>En cours</p>
                                                </div>
                                                <div className={"tag-row"}>
                                                    <div className={"tag-icon red"}>
                                                        <img src={arrow_down} alt={"refresh"}/>
                                                        {booking.bookingUsersIDs && booking.bookingUsersIDs.length > 0 ? booking.bookingUsersIDs.map((bookingUser) => {
                                                            return bookingUser.bookingStatus === 'cancelled' || bookingUser.bookingStatus === 'pastDate' || bookingUser.bookingStatus === 'user-cancelled' || bookingUser.bookingStatus === 'cancelledForReason' ? 1 : 0;
                                                        }).reduce((a, b) => a + b, 0) : 0}
                                                    </div>
                                                    <p>Manqué</p>
                                                </div>
                                                <div className={"tag-row"}>
                                                    <div className={"tag-icon green"}>
                                                        <img src={arrow_up} alt={"refresh"}/>
                                                        {booking.bookingUsersIDs && booking.bookingUsersIDs.length > 0 ? booking.bookingUsersIDs.map((bookingUser) => {
                                                            return bookingUser.bookingStatus === 'completed' ? 1 : 0;
                                                        }).reduce((a, b) => a + b, 0) : 0}
                                                    </div>
                                                    <p>Réalisé</p>
                                                </div>
                                            </div>,
                                            status: <Tag
                                                text={getStatusName(booking?.status)}
                                                status={booking?.status}/>,
                                            actions: (
                                                <Dot size={"big"} click={() => openModal(index)}>
                                                    <ModalBookingManager
                                                        isOpen={isModalOpen[index]}
                                                        closeModal={() => closeModal(index)}
                                                        partnerId={booking?.partnerID?._id}
                                                    />
                                                </Dot>
                                            ),
                                        }
                                    })
                                    :
                                    []
                            }
                        />
                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucun système de réservation trouvé</p>
                        </div>
                }
            </div>

        </div>
    )
}

export default BookingsPartnersManager;