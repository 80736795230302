import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../../context/loaderContext";
import { useStep } from "../../../../context/login/stepContext";
import { ErrorContext } from "../../../../context/errorContext";
import SubsrciptionsServices from "../../../../api/services/subscriptions";
import UserSubscriptionServices from "../../../../api/services/stripeSubscription";
import StripeServices from "../../../../api/services/stripe";
import StepBar from "../../../../components/stepBar/stepBar";
import '../../../../styles/views/subscription.css';
import SubscriptionCard from "../../../../components/stripe/subscriptionCard";
import Button from "../../../../components/button/button";
import { userUpdateSubscription } from "../../../../redux/slice/authSlice";
import { useDispatch } from "react-redux";


const SubscriptionPlan = () => {
    const [subscription, setSubscription] = useState(null);
    const [freeProductPriceID, setFreeProductPriceID] = useState(null);
    const [selectedPartners, setSelectedPartners] = useState({});
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const { setLoading } = useLoader();
    const { showError } = useContext(ErrorContext);
    const { step, stepArray, nextStep, prevStep, setStep } = useStep();

    const dispatch = useDispatch();
    const [openSidebar, setOpenSidebar] = useState(false);

    const [partnerNoSubscription, setPartnerNoSubscription] = useState(null);

    // Function to parse town and postal code from address
    const parseAddress = (address) => {
        const postalCodeMatch = address.match(/\b\d{5}\b/); // Matches a 5-digit postal code
        const townMatch = address.match(/\b\d{5}\s+([\w\s'-]+)/); // Matches postal code and captures the town
        const postalCode = postalCodeMatch ? postalCodeMatch[0] : "Non renseigné";
        const town = townMatch ? townMatch[1].split(",")[0] : "Non renseigné"; // Extract town before the comma
        return { postalCode, town };
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await SubsrciptionsServices.getSubscriptions();

                if (response.status === 200) {
                    setSubscription(response.data);

                    const response2 = await StripeServices.getProductById({
                        stripeProductId: response.data[0].stripeSubscriptionId,
                    });

                    if (response2.status === 200) {
                        setFreeProductPriceID(response2.data.default_price);
                    }
                }

                // Initialize selectedPartners state with all checkboxes selected
                const initialSelection = {};
                setPartnerNoSubscription(user.partnerID.filter((partner) => !partner.subscriptionID || partner.subscriptionID === null));

                user.partnerID.forEach((partner) => {
                    initialSelection[partner._id] = true;
                });

                setSelectedPartners(initialSelection);
            } catch (error) {
                showError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user, showError, setLoading]);

    const handleCheckboxChange = (partnerId) => {
        const updatedSelection = {
            ...selectedPartners,
            [partnerId]: !selectedPartners[partnerId],
        };

        // Check if any checkbox is deselected
        if (!Object.values(updatedSelection).every((isSelected) => isSelected)) {
            alert("You must agree to all plans to continue.");
            return; // Prevent deselection
        }
        setSelectedPartners(updatedSelection);
    };


    const handleAllSubscriptions = async () => {
        setLoading(true);
        try {
            // Get selected partner IDs
            const selectedPartnerIDs = Object.entries(selectedPartners)
                .filter(([_, isSelected]) => isSelected)
                .map(([partnerId]) => partnerId);

            // Create subscriptions for each selected partner
            const subscriptionResponses = await Promise.all(
                selectedPartnerIDs.map(async (partnerId) => {
                    const partner = user.partnerID.find(p => p._id === partnerId);

                    const data = {
                        priceId: freeProductPriceID,
                        partnerId,
                        email: partner.email,
                        name: partner.name,
                    };

                    const response = await UserSubscriptionServices.createFreeSubscription(data);

                    if (response.status === 200) {
                        return {
                            partnerId,
                            partnerSubscription: response.data.partnerSubscription, // Full subscription data from backend
                        };
                    } else {
                        throw new Error(`Failed to create subscription for partner ${partnerId}`);
                    }
                })
            );

            // Update Redux with detailed subscription data for each partner
            subscriptionResponses.forEach(({ partnerId, partnerSubscription }) => {
                dispatch(userUpdateSubscription({
                    partnerID: partnerId,
                    partnerSubscription, // Store the full subscription object in Redux
                }));
            });

            // Proceed to the next step
            nextStep(user, navigate);
        } catch (error) {
            console.error("Error updating subscriptions:", error);
            showError("Une erreur s'est produite lors de la mise à jour des abonnements.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="main verification subscription-main">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(item => item.label)} />
            </div>
            <div className="main__container subscription has-margin-top">
                <div className={"header__container"}>
                    <div className="main__container__col32">
                        <h1 className={"title-25"}>Choisissez votre tarification</h1>
                        <p className={"text-14 light"}>Choisissez le plan de tarification qui vous convient le
                            mieux.</p>
                    </div>
                </div>
                <div className={"subscription__grid"}>
                    <div className={"subscription__grid-area-1"}>
                        <h2 className={"title-18"}>{user.partnerID.length > 1 ? "Vos établissements" : "Votre établissement"}</h2>
                    </div>
                    <div className={"subscription__grid-area-2"}>
                        <h2 className={"title-18"}>Plans de comissions</h2>
                    </div>
                    <div className={"subscription__grid-area-3"}>
                        {subscription &&
                            partnerNoSubscription && partnerNoSubscription.map((partner, index) => (
                                <div className={"subscription__grid-item"} key={index}>
                                    <div className={"subscription__grid-item__partner"}>
                                        <p className={"text-16 bold"}>{partner.name}</p>
                                        <p className={"text-14"}>Ville
                                            : {parseAddress(partner.address).town}, {parseAddress(partner.address).postalCode}</p>
                                    </div>
                                    <div className={"subscription__grid-item__plan"}>
                                        <input
                                            type="checkbox"
                                            name={`partner-${partner._id}`}
                                            id={`partner-${partner._id}`}
                                            checked={selectedPartners[partner._id]}
                                            onChange={() => handleCheckboxChange(partner._id)}
                                        />
                                        <div className={"subscription__grid-item__plan__content"}>
                                            <p className={"text-16"}>Plan {subscription[0].name}</p>
                                            <p className={"text-14"}>
                                                Commission de {subscription[0].commission}%{" "}
                                                <p className={"text-10"}>* sur chaque réservation via Frimity</p>
                                            </p>
                                        </div>

                                        <Button
                                            text={"Voir les détails"}
                                            onClick={() => setOpenSidebar(true)}
                                            isPrimary={true}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className={"area-end"}>
                        <p className={"text-14 light"}>Besoin d'aide ? <a href={"mailto:contact.frimity@gmail.com"}
                                                                          className={"text-14 bold"}>Contact.frimity@gmail.com</a>
                        </p>
                    </div>
                </div>


                <button
                    onClick={() => handleAllSubscriptions()}
                    className="btn btn-primary"
                >
                    Continuer
                </button>
            </div>

            <div className={`sidebar-subscription subscription__container ${openSidebar ? "open" : ""}`}
                 onClick={() => setOpenSidebar(!openSidebar)}>
                <div className={"sidebar-subscription__container"}>
                <div className={"subscription__content welcome__subscription"}>
                        {
                            subscription && subscription.map((item, index) => {
                                return (
                                    <SubscriptionCard
                                        subscription={subscription}
                                        plan={item}
                                        key={index}
                                        isFirst={true}
                                        activePlan={null}
                                        isButton={false}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlan;
