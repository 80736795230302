import React from 'react';
import '../../styles/components/form/form.css';
import SubmitButton from "../button/submitButton";
import Button from "../button/button";
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Form = ({children, onSubmit, mode, moreSubmitOptions = false, isSubmitDisabled = false, cancelledNavigate = () => {}}) => {

    const isAdding = mode === 'add';
    const isSending = mode === 'send';
    const isOffer = mode === 'offer';
    const isAssociate = mode === 'associate';
    const sendValidation = mode === 'sendValidation';
    const navigate = useNavigate();

    return(
        <div className={"form-container"}>
            <form onSubmit={onSubmit} className={`${isSubmitDisabled ? "is__disabled" : ""}`}>
                {
                    children
                }

                <div className="form__row button">
                    <Button text={"Annuler"} Secondary={true} onClick={() => {
                        if(cancelledNavigate) {
                           cancelledNavigate();
                        } else {
                            navigate(-1);
                        }
                    }} />
                    {/* Additional Submit Options */}
                    {moreSubmitOptions && moreSubmitOptions.length > 0 && moreSubmitOptions.map((option, index) => (
                        <Button
                            key={index}
                            text={option.label}
                            onClick={option.onClick}
                            isPrimary={false} // Customize button styles as needed
                        />
                    ))}

                    <SubmitButton text={
                        isAdding ? "Ajouter"
                            : isSending ? "Envoyer"
                                : isOffer ? "Soumettre mon l’offre"
                                    : isAssociate ? "Associer"
                                        : sendValidation ? "Envoyer pour validation"
                                            : "Sauvegarder"} />
                </div>
            </form>
        </div>
    )

}

export default Form;