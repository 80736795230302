import React, {useEffect, useState} from 'react';
import Searchbar from "../searchbar/searchbar";
import logo from '../../assets/images/svg/logo_row.svg';
import {Link} from "react-router-dom";
import '../../styles/components/navbar.css';
import useAuth from "../../hooks/useAuth";
import {useSelector} from "react-redux";
import PartnersServices from "../../api/services/partners";
import Avatar from "../avatar/avatar";
import {usePartner} from "../../context/partnerContext";
import IconButton from "../button/iconButton";
import chevron from "../../assets/icones/arrows/left_chevron.svg";
import BookingUsersService from "../../api/services/bookingUsers";
import {useNavigate} from "react-router-dom";

import arrow_up from "../../assets/icones/arrows/chevron-up.svg";

const Navbar = () => {

    const { logout } = useAuth();
    const [partners, setPartners] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const { currentPartnerId, clearPartnerId } = usePartner();
    const [activeTab, setActiveTab] = useState("");
    const [bookingInWaiting, setBookingInWaiting] = useState(0);
    const navigate = useNavigate();
    const currentLocation = window.location.pathname.split("/")[1]; // Consider using React Router's useLocation for SPA navigation
    const complexLocation = window.location.pathname;

    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
    //get media queries for the navbar
    const [mediaQuery, setMediaQuery] = useState(window.matchMedia('(max-width: 991px)').matches);


    const [expandedItems, setExpandedItems] = useState({}); // Track expanded state for each parent `li`

    const handleToggle = (key) => {
        setExpandedItems((prevState) => ({
            ...prevState,
            [key]: !prevState[key], // Toggle the state for the clicked `li`
        }));
    };



    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await PartnersServices.getAllPartners();
                if (response.status === 200) {
                    setPartners(response.data);
                }

                const bookingPartnerId = currentPartnerId ? response.data.find(partner => partner._id === currentPartnerId).bookingPartnersID._id : false;

                if(bookingPartnerId) {
                    const bookingInWaiting = await BookingUsersService.getBookingInWaiting(bookingPartnerId);
                    if (bookingInWaiting.status === 200) {
                        setBookingInWaiting(bookingInWaiting.data.length);
                    }
                }

            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [user, currentPartnerId]);


    // Define when to show the support and settings based on user role and location
    const showSupportAndSettings = (user && user.role === "partner" || (user.role === "partners-admin" && ["dashboard-partners", "settings", "support"].includes(currentLocation)));

    // Determine if the user is a partners-admin outside of specific pages
    const isPartnersAdminOutsideSpecificPages = user && user.role === "partners-admin" && !["dashboard-partners", "settings", "support"].includes(currentLocation);
    /*const isAdminOutsideSpecificPages = user.role === "admin" && !["", "verification", "manager", "users", "settings", "support"].includes(currentLocation) && !["/partners/add", "/partners"].includes(complexLocation);*/

     const handleRemovePartnerId = () => {
         clearPartnerId();
            setActiveTab("");
            if(mediaQuery) {
                setBurgerMenuOpen(false);
            } else if(user && user.role === "super-admin" || user.role === "admin") {
                setActiveTab("dashboard");
                navigate("/partners");
            } else {
                navigate("/dashboard-partners");
            }
     }

    return !mediaQuery ? (
        <div className="navbar fixed-navbar">
            <div className="navbar__container">
                <div className="navbar__logo">
                    <img src={logo} alt="logo" />
                </div>

                {/* Display admin */}
                {user && user.role === "admin" && (
                    currentPartnerId ? (
                        // Render the partner-specific navbar if currentPartnerId exists
                        <PartnerNav
                            user={user}
                            activeTab={activeTab}
                            handleSetActiveTab={setActiveTab}
                            bookingInWaiting={bookingInWaiting}
                            isAdmin={true}
                            allpartners={partners}
                        />
                    ) : (
                        <AdminNav partners={partners} activeTab={activeTab} handleSetActiveTab={setActiveTab} handleToggle={handleToggle} expandedItems={expandedItems} />
                    )
                )}

                {/*{isAdminOutsideSpecificPages && <PartnerNav user={user} activeTab={activeTab} handleSetActiveTab={setActiveTab} bookingInWaiting={bookingInWaiting} />}*/}

                {/* Display partner single */}
                {user && user.role === "partner" && <PartnerNav user={user} activeTab={activeTab} handleSetActiveTab={setActiveTab} bookingInWaiting={bookingInWaiting} allpartners={partners}/>}

                {/* Display partner multi account */}
                {user && user.role === "partners-admin" && !isPartnersAdminOutsideSpecificPages && <PartnersAdminNav user={user} activeTab={activeTab} handleSetActiveTab={setActiveTab} />}
                {isPartnersAdminOutsideSpecificPages && <PartnerNav user={user} activeTab={activeTab} handleSetActiveTab={setActiveTab} bookingInWaiting={bookingInWaiting} allpartners={partners}/>}


                {user && user.role === "super-admin" && (
                    currentPartnerId ? (
                        // Render the partner-specific navbar if currentPartnerId exists
                        <PartnerNav
                            user={user}
                            activeTab={activeTab}
                            handleSetActiveTab={setActiveTab}
                            bookingInWaiting={bookingInWaiting}
                            isAdmin={true}
                            allpartners={partners}
                        />
                    ) : (
                        // Render the general super-admin navbar if no currentPartnerId
                        <SuperAdminNav
                            partners={partners}
                            activeTab={activeTab}
                            handleSetActiveTab={setActiveTab}
                            handleToggle={handleToggle}
                            expandedItems={expandedItems}
                        />
                    )
                )}


            </div>
            <div className={"navbar__container"}>
                {/* Conditional rendering for support and settings based on the user role and location */}
                {showSupportAndSettings && (
                    <nav className="navbar__nav">
                        <ul className="navbar__nav__items">
                            <li className={`navbar__nav__item ${activeTab === "support" ? "active" : ""}`} onClick={() => setActiveTab("support")}>
                                <Link to={"/support"}>
                                    <div className={"navbar__nav__icon support"}></div>
                                    Support
                                </Link>
                            </li>
                            <li className={`navbar__nav__item ${activeTab === "settings" ? "active" : ""}`} onClick={() => setActiveTab("settings")}>
                                <Link to={"/settings"}>
                                    <div className={"navbar__nav__icon settings"}></div>
                                    Paramètres
                                </Link>
                            </li>
                        </ul>
                    </nav>
                )}

                {user && user.role !== "partner" && currentPartnerId && (
                    <IconButton
                        icon={chevron}
                        onClick={handleRemovePartnerId}
                        text={"Les établissements"}
                    />
                )}


                {/* User info and logout */}
                <div className="navbar__user">
                    <Avatar avatarURL={user && user.userAvatarID ? user.userAvatarID.avatarURL : null} name={user ? user.firstname : ""} />
                    <div className="navbar__user__infos">
                        <p className={"text-14 bold"}>{user ? user.firstname : ""} {user ? user.lastname : ""}</p>
                        <span className={"text-12"}>{user ? user.email : ""}</span>
                    </div>
                    <div className="navbar__user__actions">
                        <div className={"navbar__nav__icon logout"} onClick={logout}></div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="navbar fixed-navbar">
            <div className="navbar__container">
                <div className="navbar__logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div className={`hamburger ${burgerMenuOpen ? "open" : "close"}`}
                     onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
            <div className={`navbar__menu ${burgerMenuOpen ? 'active' : ''}`}>
                {/* Menu content goes here */}
                <div className="navbar__menu__container">
                    <nav className="navbar__nav">
                        <div onClick={() => setBurgerMenuOpen(false)}>
                            {user.role === "partners-admin" && (
                                <IconButton icon={chevron} link={"/dashboard-partners"} text={"Vos établissements"} onClick={() => setBurgerMenuOpen(false)} />
                            )}
                        </div>

                        {user.role === "partner" && <PartnerNav user={user} activeTab={activeTab} handleSetActiveTab={(tab) => { setActiveTab(tab); setBurgerMenuOpen(false)}} bookingInWaiting={bookingInWaiting} mediaQuery={mediaQuery} />}
                        {isPartnersAdminOutsideSpecificPages && <PartnerNav user={user} activeTab={activeTab} handleSetActiveTab={(tab) => {setActiveTab(tab); setBurgerMenuOpen(false)}} bookingInWaiting={bookingInWaiting} mediaQuery={mediaQuery} />}

                        {/* Super-admin viewing a specific partner's account */}
                        {user && user.role === "super-admin" && currentPartnerId && (
                            <PartnerNav
                                user={user}
                                activeTab={activeTab}
                                handleSetActiveTab={(tab) => { setActiveTab(tab); setBurgerMenuOpen(false)}}
                                bookingInWaiting={bookingInWaiting}
                                mediaQuery={mediaQuery}
                            />
                        )}

                    </nav>


                    <div className={"navbar__bottom"}>
                        {showSupportAndSettings && (
                            <nav className="navbar__nav is__bottom">
                                <ul className="navbar__nav__items">
                                    <li className={`navbar__nav__item ${activeTab === "support" ? "active" : ""}`}
                                        onClick={() => { setActiveTab("support"); setBurgerMenuOpen(false)}}>
                                        <Link to={"/support"}>
                                            <div className={"navbar__nav__icon support"}></div>
                                            Support
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        )}
                        {/* User info and logout */}
                        <div className="navbar__user">
                            <Avatar avatarURL={user && user.userAvatarID ? user.userAvatarID.avatarURL : null}
                                    name={user ? user.firstname : ""}/>
                            <div className="navbar__user__infos">
                                <p className={"text-14 bold"}>{user ? user.firstname : ""} {user ? user.lastname : ""}</p>
                                <span className={"text-12"}>{user ? user.email : ""}</span>
                            </div>
                            <div className="navbar__user__actions">
                                <div className={"navbar__nav__icon logout"} onClick={logout}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};


const PartnersAdminNav = ({user, activeTab, handleSetActiveTab}) => {

    const mediaQuery = window.matchMedia('(max-width: 991px)');

    return (
        <nav className="navbar__nav">
            <ul className="navbar__nav__items">
                <li
                    className={`navbar__nav__item ${activeTab === "userDashboard" ? "active" : ""}`}
                >
                    <Link to={`/dashboard-partners`}
                          onClick={() => handleSetActiveTab("userDashboard")}
                    >
                        <div className={"navbar__nav__icon dashboard"}></div>
                        Mes établissements
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

const PartnerNav = ({isAdmin = false, allpartners, user, activeTab, handleSetActiveTab, bookingInWaiting, mediaQuery}) => {
    const { currentPartnerId } = usePartner();

    // Only display the navbar for the specific partner
    if (!currentPartnerId) {
        return null;
    }

    const currentPartner = allpartners.find(partner => partner._id === currentPartnerId);

    return !mediaQuery ? (
        <nav className="navbar__nav">
            <div className="navbar__partner">
                <Avatar avatarURL={currentPartner ? currentPartner.avatarURL : null} name={currentPartner ? currentPartner.name : ""}/>
                <div className="navbar__partner__infos">
                    <p className={"text-12"}>Partneraire :</p>
                    <span className={"text-14 bold"}>{currentPartner ? currentPartner.name : ""}</span>
                </div>
            </div>
            <ul className="navbar__nav__items">
                <li
                    className={`navbar__nav__item ${activeTab === "userDashboard" ? "active" : ""}`}
                >
                    <Link to={`/dashboard/${currentPartnerId}`}
                          onClick={() => handleSetActiveTab("userDashboard")}
                    >
                        <div className={"navbar__nav__icon dashboard"}></div>
                        Tableau de bord
                    </Link>
                    <ul className="navbar__nav__subitems visible">
                        <li
                            className={`navbar__nav__subitem ${activeTab === "partner" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("partner")}
                        >
                            <Link to={`/partners/${currentPartnerId}`}>Mes informations</Link>
                        </li>
                        <li
                            className={`navbar__nav__subitem ${activeTab === "activities" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("activities")}
                        >
                            <Link to={`/partners/${currentPartnerId}/activities`}>Mes activités</Link>
                        </li>
                        <li
                            className={`navbar__nav__subitem ${activeTab === "offers" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("offers")}
                        >
                            <Link to={`/partners/${currentPartnerId}/offers`}>Gérer mes offres</Link>
                        </li>
                        <li
                            className={`navbar__nav__subitem ${activeTab === "users" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("users")}
                        >
                            <Link to={`/partners/${currentPartnerId}/users`}>Utilisateurs</Link>
                        </li>
                        <li
                            className={`navbar__nav__subitem ${activeTab === "subscription" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("subscription")}
                        >
                            <Link to={`/partners/${currentPartnerId}/subscription`}>Plan tarifaire</Link>
                        </li>
                    </ul>
                </li>
                <li
                    className={`navbar__nav__item ${activeTab === "userBooking" ? "active" : ""}`}
                >

                    <Link to={`/partners/${currentPartnerId}/bookings`}
                          onClick={() => handleSetActiveTab("userBooking")}
                          style={{position: "relative"}}
                    >
                        <div className={"navbar__nav__icon booking"}></div>
                        Réservations

                        {bookingInWaiting ?
                            <div className={"navbar__nav__booking_badge_absolute"}>{bookingInWaiting}</div> : null}
                    </Link>
                    <ul className="navbar__nav__subitems visible">
                        <li
                            className={`navbar__nav__subitem ${activeTab === "availability" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("availability")}
                        >
                            <Link to={`/partners/${currentPartnerId}/bookings/schedule`}>Mes disponibilités</Link>
                        </li>
                        <li
                            className={`navbar__nav__subitem ${activeTab === "payments" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("payments")}
                        >
                            <Link to={`/partners/${currentPartnerId}/bookings/transactions`}>Mes transactions</Link>
                        </li>
                        {/* <li
                            className={`navbar__nav__subitem ${activeTab === "config" ? "active" : ""}`}
                            onClick={() => handleSetActiveTab("config")}
                        >
                            <Link to={`/partners/${currentPartnerId}/bookings/configuration-api`}>Configuration
                                API</Link>
                        </li>*/}
                    </ul>
                </li>
                {
                    //check if user is a partnerId._ID with the currentPartnerId and display the analytics tab
                    user.partnerID.find(partner => partner._id === currentPartnerId) && user.partnerID.find(partner => partner._id === currentPartnerId).subscriptionID ?
                        <li
                            className={`navbar__nav__item ${activeTab === "userAnalytics" ? "active" : ""}`}
                        >

                            <Link to={`analytics/${currentPartnerId}`}
                                  onClick={() => handleSetActiveTab("userAnalytics")}
                            >
                                <div className={"navbar__nav__icon analytics"}></div>
                                Analyse de vos données
                            </Link>
                        </li>
                        :
                        null
                }
            </ul>
        </nav>
    ) : (
        <ul className="navbar__nav__items">
            <li
                className={`navbar__nav__item ${activeTab === "userBooking" ? "active" : ""}`}
            >

                <Link to={`/partners/${currentPartnerId}/bookings`}
                      onClick={() => handleSetActiveTab("userBooking")}
                      style={{position: "relative"}}
                >
                    <div className={"navbar__nav__icon booking"}></div>
                    Réservations

                    {bookingInWaiting ? <div
                        className={"navbar__nav__booking_badge_absolute"}>{bookingInWaiting}</div> : null}
                </Link>
            </li>
        </ul>
    )
}

const AdminNav = ({partners, activeTab, handleSetActiveTab, handleToggle, expandedItems}) => {

    return (
        <>
            <div className="navbar__searchBar">
                {
                    partners.length > 0 &&
                    <Searchbar
                        searchData={partners}
                        typeValue={"partner"}
                    />
                }
            </div>
            <nav className="navbar__nav">
                <ul className="navbar__nav__items">
                    <li
                        className={`navbar__nav__item ${activeTab === "dashboard" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("dashboard")}
                    >
                        <Link to={"/partners"}>
                            <div className={"navbar__nav__icon dashboard"}></div>
                            Tableau de bord
                        </Link>
                    </li>
                    <li className={`navbar__nav__item`}>
                        <Link to={"/verification"} onClick={() => handleSetActiveTab("verif")}>
                            <div className={"navbar__nav__icon verificationIcon"}></div>
                            Vérification
                        </Link>
                        <ul className="navbar__nav__subitems">
                            <li
                                className={`navbar__nav__subitem ${activeTab === "verifPartners" ? "active" : ""}`}
                                onClick={() => handleSetActiveTab("verifPartners")}
                            >
                            <Link to={"/verification/partners"}>Partenaires</Link>
                            </li>
                            <li
                                className={`navbar__nav__subitem ${activeTab === "verifActivities" ? "active" : ""}`}
                                onClick={() => handleSetActiveTab("verifActivities")}
                            >
                                <Link to={"/verification/activities"}>Activités</Link>
                            </li>
                            <li
                                className={`navbar__nav__subitem ${activeTab === "verifOffers" ? "active" : ""}`}
                                onClick={() => handleSetActiveTab("verifOffers")}
                            >
                                <Link to={"/verification/offers"}>Offres</Link>
                            </li>
                        </ul>
                    </li>
                    <li
                        className={`navbar__nav__item ${activeTab === "manager" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("manager")}
                    >
                        <Link to={"/manager"}>
                            <div className={"navbar__nav__icon manager"}></div>
                            Gestionnaire
                        </Link>
                    </li>
                    <li
                        className={`navbar__nav__item ${activeTab === "users" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("users")}
                    >
                        <Link to={"/users"}>
                            <div className={"navbar__nav__icon users"}></div>
                            Utilisateurs
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}


const SuperAdminNav = ({partners, activeTab, handleSetActiveTab, handleToggle, expandedItems}) => {

    return (
        <>
            <div className="navbar__searchBar">
                {
                    partners.length > 0 &&
                    <Searchbar
                        searchData={partners}
                        typeValue={"partner"}
                    />
                }
            </div>
            <nav className="navbar__nav">
                <ul className="navbar__nav__items">
                    <li
                        className={`navbar__nav__item ${activeTab === "dashboard" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("dashboard")}
                    >
                        <Link to={"/partners"}>
                            <div className={"navbar__nav__icon dashboard"}></div>
                            Établissement
                        </Link>
                    </li>
                    <li
                        className={`navbar__nav__item ${
                            expandedItems["verification"] ? "expanded" : ""
                        }`}
                    >
                        <div
                            className="navbar__nav__toggle"
                            onClick={() => handleToggle("verification")}
                        >
                            <div className={'navbar__nav__toggle_row'}>
                                <div className={"navbar__nav__icon verificationIcon"}></div>
                                Vérification
                            </div>
                            <img src={arrow_up} alt="arrow"/>
                        </div>
                        <ul
                            className={`navbar__nav__subitems ${
                                expandedItems["verification"] ? "visible" : "hidden"
                            }`}
                        >
                            <li
                                className={`navbar__nav__subitem ${
                                    activeTab === "verifPartners" ? "active" : ""
                                }`}
                                onClick={() => handleSetActiveTab("verifPartners")}
                            >
                                <Link to={"/verification/partners"}>Partenaires</Link>
                            </li>
                            <li
                                className={`navbar__nav__subitem ${
                                    activeTab === "verifActivities" ? "active" : ""
                                }`}
                                onClick={() => handleSetActiveTab("verifActivities")}
                            >
                                <Link to={"/verification/activities"}>Activités</Link>
                            </li>
                            <li
                                className={`navbar__nav__subitem ${
                                    activeTab === "verifOffers" ? "active" : ""
                                }`}
                                onClick={() => handleSetActiveTab("verifOffers")}
                            >
                                <Link to={"/verification/offers"}>Offres</Link>
                            </li>
                        </ul>
                    </li>
                    <li
                        className={`navbar__nav__item ${activeTab === "manager" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("manager")}
                    >
                        <Link to={"/manager"}>
                            <div className={"navbar__nav__icon manager"}></div>
                            Gestionnaire d'activités
                        </Link>
                    </li>
                    <li
                        className={`navbar__nav__item ${activeTab === "users" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("users")}
                    >
                        <Link to={"/users"}>
                            <div className={"navbar__nav__icon users"}></div>
                            Utilisateurs
                        </Link>
                    </li>
                    <li
                        className={`navbar__nav__item ${activeTab === "stripe" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("stripe")}
                    >
                        <Link to={"/stripe-accounts"}>
                            <div className={"navbar__nav__icon stripe"}></div>
                            Compte Stripe
                        </Link>
                    </li>

                    <li
                        className={`navbar__nav__item ${activeTab === "bookings" ? "active" : ""}`}
                        onClick={() => handleSetActiveTab("bookings")}
                    >
                        <Link to={"/bookings"}>
                            <div className={"navbar__nav__icon bookings"}></div>
                            Gestion des réservations
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar;