import React, {useEffect, useState} from 'react';
import Input from "../../../components/form/inputText/input";
import FormSectionRow from "../../../components/form/formSectionRow";
import IconButton from "../../../components/button/iconButton";
import plus from "../../../assets/icones/global/add.svg";
import trash from "../../../assets/icones/global/trash_can_red.svg";
import InputPrice from "../../../components/form/inputText/inputPrice";
import Tooltip from "../../../components/tooltips/tooltip";
import InputSelect from "../../../components/form/inputSelect/inputSelect";
import InfoRedCard from "../../../components/card/infoRedCard";
import close from "../../../assets/icones/global/close-stroke.svg";
import {CenturyView} from "react-calendar";


const PricingAndExtras = ({ pricingAndExtras, setActivity, commission, isDisabled = false }) => {
    const [pricingItems, setPricingItems] = useState([]);
    const [extrasItems, setExtrasItems] = useState([]);

    const [offerPersonNumber, setOfferPersonNumber] = useState([]);
    const [hours, setHours] = useState([]);

    const [titleExtras, setTitleExtras] = useState(["Premier extras", "Deuxième extras", "Troisième extras", "Quatrième extras", "Cinquième extras", "Sixième extras", "Septième extras", "Huitième extras", "Neuvième extras", "Dixième extras"]);

    const handleAddPricingItem = () => {
        const newPricingItem = {
            name: '',
            priceInfos: [], // Initialize as an empty array
            isGroup: false,
            isGroupPricingIndividual: false,
            isSpecificHours: false,
            isSpecificDays: false,
            startTime: null,
            endTime: null,
            weekdays: [],
            minPerson: null,
            maxPerson: null,
            price: null,
        };
        setPricingItems([...pricingItems, newPricingItem]);
    };

    const handleAddExtrasItem = () => {
        const newExtrasItem = {
            name: '',
            price: null
        }

        setExtrasItems([...extrasItems, newExtrasItem]);
    };

    const handleDeletePricingItem = (index) => {
        const updatedPricingItems = [...pricingItems];
        updatedPricingItems.splice(index, 1);
        setPricingItems(updatedPricingItems);

        setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
    };

    const handleDeleteExtrasItem = (index) => {
        const updatedExtrasItems = [...extrasItems];
        updatedExtrasItems.splice(index, 1);
        setExtrasItems(updatedExtrasItems);

        setActivity('pricingAndExtras.extraPrice', updatedExtrasItems);
    };

    useEffect(() => {
        setPricingItems(pricingAndExtras.pricingOptions);
        setExtrasItems(pricingAndExtras.extraPrice);

        //1 to 50
        let personNumber = [];
        for (let i = 1; i <= 100; i++) {
            //value and name
            personNumber.push({value: i, name: i});
        }
        setOfferPersonNumber(personNumber);

        let hours = [];
        //every 30 minutes
        for (let i = 0; i < 24; i++) {
            const hour = i < 10 ? `0${i}` : i;

            hours.push({value: `${hour}:00`, name: `${hour}:00`});
            hours.push({value: `${hour}:30`, name: `${hour}:30`});
        }
        setHours(hours);
    }, [pricingAndExtras]);

    const handlePricingItemsChange = (e) => {
        const { name, value } = e.target;
        const index = name.split('-')[1];
        const field = name.split('-')[0];

        // Update the correct field based on the field name
        const updatedPricingItems = pricingItems.map((item, idx) => {
            if (idx.toString() === index) {
                if (field === "priceText") {
                    return { ...item, name: value }; // Update 'name'
                } else if (field === "price") {
                    return { ...item, price: value }; // Update 'price'
                } else if (field === "tagInput") {
                    return { ...item, newTag: value }; // Temporarily store tag input
                } else if (field === "priceInfos") {
                    // Add value to the priceInfos array (if required)
                    return {
                        ...item,
                        priceInfos: [...(item.priceInfos || []), value.trim()],
                    };
                }
            }
            return item;
        });

        setPricingItems(updatedPricingItems);

        // Propagate changes to the parent component
        setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
    };

    const handleExtrasItemsChange = (e) => {
        const { name, value } = e.target;
        const index = name.split('-')[1];
        const field = name.split('-')[0];

        // Update the correct field based on whether the input name includes 'Text' or not
        const updatedExtrasItems = extrasItems.map((item, idx) => {
            if (idx.toString() === index) {
                return field.includes('Text') ? { ...item, name: value } : { ...item, price: value };
            }
            return item;
        });

        setExtrasItems(updatedExtrasItems);
        setActivity('pricingAndExtras.extraPrice', updatedExtrasItems);
    }


    const handleAddGroup = (index) => {
        const updatedPricingItems = [...pricingItems];
        updatedPricingItems[index].isGroup = true;
        setPricingItems(updatedPricingItems);
        setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
    };

    const handleAddSpecificHours = (index) => {
        const updatedPricingItems = [...pricingItems];
        updatedPricingItems[index].isSpecificHours = true;
        setPricingItems(updatedPricingItems);
        setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
    };

    const handleAddSpecificDays = (index) => {
        const updatedPricingItems = [...pricingItems];
        updatedPricingItems[index].isSpecificDays = true;
        setPricingItems(updatedPricingItems);
        setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
    };


    return (
        <FormSectionRow title={"Tarifs"} description={"Indiquez ici les tarifs de votre activité ainsi que les descriptions pour chaque tarif, en veillant à ce qu'ils correspondent exactement à ceux pratiqués en direct."} infosSection={<InfoRedCard title={"Vérification des tarifs"} text={"Veuillez indiquer les tarifs exact de votre activité. Nous le vérifierons pour garantir la visibilité ou non de votre activité sur l'application Frimity, autrement elle sera refusée."}/>}>
            <div className={"form__col__gap gap-30"}>
                <div className={"pricing__container"}>
                    <div className={"pricing__col gap-30"}>
                        {
                            pricingItems.length > 0 ?
                                pricingItems.map((item, index) => (
                                    <div key={index} className={"pricing__col_item gap-30"}>
                                        <div className={"pricing__container_col"}>
                                            <div className={"pricing__col_section"}>
                                                <div className={"container_top"}>
                                                    <p className={"text-14 bold"}>Tarif personnalisé {index + 1}</p>
                                                    {!isDisabled && <IconButton icon={trash} isRed={true} onClick={() => handleDeletePricingItem(index)}/>}
                                                </div>
                                                <div className={"pricing__row"}>
                                                    <Input
                                                        name={`priceText-${index}`}
                                                        type={"text"}
                                                        valueInput={item.name}
                                                        label={"Titre principal"}
                                                        onChangeForm={handlePricingItemsChange}
                                                        placeholder={"Ex : Tarif adulte"}
                                                        errorEmpty={item.error}
                                                        isColumn={true}
                                                        className={"pricing__row__input"}
                                                        disabled={isDisabled}
                                                    />

                                                    <div className={"form-container"}>
                                                        <div className="tag-input-container">
                                                            <Input
                                                                name={`priceInfos-${index}`}
                                                                type={"text"}
                                                                valueInput={pricingItems[index]?.newTag || ""}
                                                                label={"Information complémentaire"}
                                                                isColumn={true}
                                                                placeholder="Ex : 3 personnes"
                                                                onChangeForm={(e) => {
                                                                    const updatedPricingItems = [...pricingItems];
                                                                    updatedPricingItems[index].newTag = e.target.value; // Temporary input storage
                                                                    setPricingItems(updatedPricingItems);
                                                                }}
                                                                disabled={isDisabled}
                                                            />
                                                            <IconButton
                                                                icon={plus}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const updatedPricingItems = [...pricingItems];
                                                                    const newTag = updatedPricingItems[index]?.newTag?.trim();
                                                                    if (newTag) {
                                                                        updatedPricingItems[index].priceInfos = [
                                                                            ...(updatedPricingItems[index].priceInfos || []),
                                                                            newTag,
                                                                        ];
                                                                        updatedPricingItems[index].newTag = ""; // Clear the input
                                                                        setPricingItems(updatedPricingItems);
                                                                        setActivity("pricingAndExtras.pricingOptions", updatedPricingItems);
                                                                    }
                                                                }}
                                                                disabled={!pricingItems[index]?.newTag?.trim() || isDisabled} // Disable if input is empty
                                                            />

                                                        </div>
                                                        <div className="tags-container">
                                                            {(pricingItems[index]?.priceInfos || []).map((tag, tagIndex) => (
                                                                <div className="tag-item" key={tagIndex}>
                                                                    {tag}
                                                                    <img src={close} alt="close" className={"close_tag"} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        const updatedPricingItems = [...pricingItems];
                                                                        updatedPricingItems[index].priceInfos = updatedPricingItems[index].priceInfos.filter(
                                                                            (_, i) => i !== tagIndex
                                                                        );
                                                                        setPricingItems(updatedPricingItems);
                                                                        setActivity("pricingAndExtras.pricingOptions", updatedPricingItems);
                                                                    }} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"pricing__row"}>
                                                    <InputPrice
                                                        name={`price-${index}`}
                                                        type={"text"}
                                                        valueInput={item.price}
                                                        label={"Le client paye"}
                                                        onChangeForm={handlePricingItemsChange}
                                                        placeholder={"Ex : 20,00€"}
                                                        errorEmpty={item.error}
                                                        isColumn={true}
                                                        typeUnit={"€"}
                                                        disabled={isDisabled}
                                                    />
                                                    <InputPrice
                                                        name={`price-${index}`}
                                                        type={"text"}
                                                        valueInput={commission}
                                                        label={"Commission"}
                                                        isColumn={true}
                                                        typeUnit={"%"}
                                                        disabled={true}
                                                    />
                                                    <InputPrice
                                                        name={`price-${index}`}
                                                        type={"text"}
                                                        valueInput={item.price - (item.price * commission / 100)}
                                                        label={"Vous recevrez"}
                                                        isColumn={true}
                                                        typeUnit={"€"}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {
                                                item.isGroup &&
                                                <div className={"pricing__col_section"}>
                                                    <p className={"text-14 bold"}>Tarif de groupe</p>
                                                    <div className={"pricing__row"}>
                                                        <InputSelect
                                                            name={"minPerson"}
                                                            label={"À partir de"}
                                                            placeholder={"Choisir..."}
                                                            value={item.minPerson}
                                                            valueSelected={item.minPerson}
                                                            onChange={(e) => {
                                                                const {value} = e.target;
                                                                const updatedPricingItems = [...pricingItems];
                                                                updatedPricingItems[index].minPerson = value;
                                                                setPricingItems(updatedPricingItems);
                                                                setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
                                                            }}
                                                            isColumn={true}
                                                            isDisabled={isDisabled}
                                                            options={offerPersonNumber}
                                                            hasTooltip={true}
                                                            tooltipContent={
                                                                <>
                                                                    <p className={"title-14"}>Nombre de
                                                                        personnes</p>
                                                                    <p className={"text-14"}>Sélectionnez un nombre si
                                                                        ce tarif est destinée à un groupe de
                                                                        participants. Cela permettra d'adapter les
                                                                        tarifs en fonction du nombre de
                                                                        personnes impliquées, offrant ainsi une
                                                                        flexibilité optimale.</p>
                                                                </>
                                                            }
                                                        />
                                                        <InputSelect
                                                            name={"maxPerson"}
                                                            label={"Jusqu'à"}
                                                            placeholder={"Choisir..."}
                                                            value={item.maxPerson}
                                                            valueSelected={item.maxPerson}
                                                            onChange={(e) => {
                                                                const {value} = e.target;
                                                                const updatedPricingItems = [...pricingItems];
                                                                updatedPricingItems[index].maxPerson = value;
                                                                setPricingItems(updatedPricingItems);
                                                                setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
                                                            }}
                                                            isColumn={true}
                                                            isDisabled={isDisabled}
                                                            options={offerPersonNumber}
                                                            hasTooltip={true}
                                                            tooltipContent={
                                                                <>
                                                                    <p className={"title-14"}>Nombre de
                                                                        personnes</p>
                                                                    <p className={"text-14"}>Sélectionnez un nombre si
                                                                        ce tarif est destinée à un groupe de
                                                                        participants. Cela permettra d'adapter les
                                                                        tarifs en fonction du nombre de
                                                                        personnes impliquées, offrant ainsi une
                                                                        flexibilité optimale.</p>
                                                                </>
                                                            }
                                                        />

                                                        <div className="form__col checkbox">
                                                            <div className={"form__row__radio"}>
                                                                <label htmlFor={"isGroupPricingIndividual"} className={"text-14"}>Prix unitaire </label>
                                                                <Tooltip children={
                                                                    <>
                                                                        <p className={"title-14"}>Prix unitaire </p>
                                                                        <p className={"text-14"}>Il s'agit du coût par personne au sein d'un groupe. Le tarif peut varier en fonction du nombre de participants et des options choisies pour l'activité.</p>
                                                                    </>
                                                                }/>
                                                            </div>
                                                            <input
                                                                type="checkbox"
                                                               name={"isGroupPricingIndividual"}
                                                               disabled={isDisabled}
                                                               id={"isGroupPricingIndividual"} checked={item.isGroupPricingIndividual}
                                                               onChange={(e) => {
                                                                  const updatedPricingItems = [...pricingItems];
                                                                  updatedPricingItems[index].isGroupPricingIndividual = e.target.checked;
                                                                  setPricingItems(updatedPricingItems);
                                                                  setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
                                                               }}/>
                                                        </div>

                                                    </div>
                                                </div>
                                            }

                                            {
                                                item.isSpecificHours &&
                                                <div className={"pricing__col_section"}>
                                                    <p className={"text-14 bold"}>Créneau horaires personnalisée</p>
                                                    <div className={"pricing__row"}>
                                                        <InputSelect
                                                            name={"startTime"}
                                                            label={"Début"}
                                                            placeholder={"Choisir..."}
                                                            value={item.startTime}
                                                            valueSelected={item.startTime}
                                                            onChange={(e) => {
                                                                const {value} = e.target;
                                                                const updatedPricingItems = [...pricingItems];
                                                                updatedPricingItems[index].startTime = value;
                                                                setPricingItems(updatedPricingItems);
                                                                setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
                                                            }}
                                                            isColumn={true}
                                                            isDisabled={isDisabled}
                                                            options={hours}
                                                            hasTooltip={true}
                                                            tooltipContent={
                                                                <>
                                                                    <p className={"title-14"}>Heure de début</p>
                                                                    <p className={"text-14"}>Sélectionnez une heure si ce tarif
                                                                        est destinée à une plage horraire spécifique.
                                                                        Cela permettra d'adapter les tarifs en fonction de l'horraire choisit,
                                                                        offrant ainsi une flexibilité
                                                                        optimale.</p>
                                                                </>
                                                            }
                                                        />
                                                        <InputSelect
                                                            name={"endTime"}
                                                            label={"Fin"}
                                                            placeholder={"Choisir..."}
                                                            value={item.endTime}
                                                            valueSelected={item.endTime}
                                                            onChange={(e) => {
                                                                const {value} = e.target;
                                                                const updatedPricingItems = [...pricingItems];
                                                                updatedPricingItems[index].endTime = value;
                                                                setPricingItems(updatedPricingItems);
                                                                setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
                                                            }}
                                                            isColumn={true}
                                                            isDisabled={isDisabled}
                                                            options={hours}
                                                            hasTooltip={true}
                                                            tooltipContent={
                                                                <>
                                                                    <p className={"title-14"}>Heure de fin</p>
                                                                    <p className={"text-14"}>Sélectionnez une heure si ce tarif
                                                                        est destinée à une plage horraire spécifique.
                                                                        Cela permettra d'adapter les tarifs en fonction de l'horraire choisit,
                                                                        offrant ainsi une flexibilité
                                                                        optimale.</p>
                                                                </>
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {
                                            item.isSpecificDays &&
                                            <div className={"pricing__col_section"}>
                                                <p className={"text-14 bold"}>Choisir le(s) jour(s) applicable(s)</p>

                                                <div className={"pricing__col_grid"}>
                                                    <p className={"text-14"}>Sélectionner le(s) jour(s) de la semaine où
                                                        le tarif s'applique</p>
                                                    <div className={"pricing__row grid"}>
                                                        {['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'].map(day => (
                                                            <div
                                                                className={`pricing__row__checkbox ${item.weekdays && item.weekdays.includes(day) ? 'active' : ''}`}
                                                                key={day}>
                                                                <input type={"checkbox"} id={day} name={day} value={day}
                                                                       onClick={(e) => {
                                                                           const updatedPricingItems = [...pricingItems];
                                                                           if (e.target.checked) {
                                                                               updatedPricingItems[index].weekdays.push(day);
                                                                           } else {
                                                                               updatedPricingItems[index].weekdays = updatedPricingItems[index].weekdays.filter(d => d !== day);
                                                                           }
                                                                           setPricingItems(updatedPricingItems);
                                                                           setActivity('pricingAndExtras.pricingOptions', updatedPricingItems);
                                                                       }}/>
                                                                <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        <div className={"pricing__row__bottom"}>
                                            <div className={`pricing__row__bottom__col ${item.isGroup ? "disabled" : ""}`} onClick={() => handleAddGroup(index)}>
                                                <div className={"bottom__icon"}>
                                                    <img src={plus} alt={"add"} style={{width: 20, height: 20}}/>
                                                </div>
                                                <p className={"text-14"}>Tarif de groupe</p>
                                            </div>

                                            <div className={`pricing__row__bottom__col ${item.isSpecificDays ? "disabled" : ""}`} onClick={() => handleAddSpecificDays(index)}>
                                                <div className={"bottom__icon"}>
                                                    <img src={plus} alt={"add"} style={{width: 20, height: 20}}/>
                                                </div>
                                                <p className={"text-14"}>Choisir le(s) jour(s) applicable(s)</p>
                                            </div>

                                            <div className={`pricing__row__bottom__col ${item.isSpecificHours ? "disabled" : ""}`} onClick={() => handleAddSpecificHours(index)}>
                                                <div className={"bottom__icon"}>
                                                    <img src={plus} alt={"add"} style={{width: 20, height: 20}}/>
                                                </div>
                                                <p className={"text-14"}>Sélectionner un créneau horaires</p>
                                            </div>
                                        </div>

                                    </div>
                                ))
                                :
                                <div className={"empty stroke"}>
                                    <p className={"text-14"}>Aucun tarif ajouté pour le moment</p>
                                </div>
                        }
                    </div>
                </div>
                {!isDisabled && <IconButton icon={plus} onClick={handleAddPricingItem} text={"Ajouter un tarif"}
                                            isFull={true}/>}
            </div>

            <div className={"extras__container"}>
                <div className={"extras__col gap-30"}>
                    {
                        extrasItems.length > 0 ?
                            extrasItems.map((item, index) => (
                                <div key={index} className={"extras__col"}>
                                    <div className={"extras__row"}>
                                        <Input
                                            name={`extrasText-${index}`}
                                            type={"text"}
                                            label={titleExtras[index]}
                                            valueInput={item.name}
                                            onChangeForm={handleExtrasItemsChange}
                                            errorEmpty={item.error}
                                            placeholder={"Ex : Location de vélo"}
                                            isColumn={true}
                                            className={"pricing__row__input"}
                                            disabled={isDisabled}
                                        />
                                        <InputPrice
                                            name={`extras-${index}`}
                                            type={"text"}
                                            valueInput={item.price}
                                            label={"Le client paye"}
                                            onChangeForm={handleExtrasItemsChange}
                                            placeholder={"Ex : 20,00€"}
                                            errorEmpty={item.error}
                                            isColumn={true}
                                            typeUnit={"€"}
                                            disabled={isDisabled}
                                        />
                                        <InputPrice
                                            name={`extras-${index}`}
                                            type={"text"}
                                            valueInput={commission}
                                            label={"Commission"}
                                            isColumn={true}
                                            typeUnit={"%"}
                                            disabled={true}
                                        />
                                        <InputPrice
                                            name={`extras-${index}`}
                                            type={"text"}
                                            valueInput={item.price - (item.price * commission / 100)}
                                            label={"Vous recevrez"}
                                            isColumn={true}
                                            typeUnit={"€"}
                                            disabled={true}
                                        />
                                        {!isDisabled && <IconButton icon={trash} isFull={false} isRed={true} onClick={() => handleDeleteExtrasItem(index)} />}
                                    </div>
                                </div>

                                ))
                                :
                                <div className={"empty stroke"}>
                                    <p className={"text-14"}>Aucun extra ajouté pour le moment</p>
                                </div>

                    }
                    {!isDisabled && <IconButton icon={plus} onClick={handleAddExtrasItem} text={"Ajouter un extra"} isFull={true}/>}
                </div>
            </div>
        </FormSectionRow>
    );
}

export default PricingAndExtras;
