import React, {useContext, useEffect, useState} from 'react';
import key from '../../../../assets/images/svg/rocket_icon.svg';
import { Link } from "react-router-dom";
import arrow from '../../../../assets/icones/arrows/left.svg';
import '../../../../styles/views/verifications.css';
import StepBar from "../../../../components/stepBar/stepBar";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Input from "../../../../components/form/inputText/input";
import SubmitButton from "../../../../components/button/submitButton";
import Button from "../../../../components/button/button";
import {usePartner} from "../../../../context/partnerContext";
import {useStripeAccountToken} from "../../../../hooks/stripe/useStripeToken";
import {useStep} from "../../../../context/login/stepContext";
import {useLoader} from "../../../../context/loaderContext";
import {ErrorContext} from "../../../../context/errorContext";
import BookingPaymentServices from "../../../../api/services/bookingPayment";
import {useDispatch} from "react-redux";
import {updatePartnerConnectedAccount} from "../../../../redux/slice/authSlice";

const StripeMultiPartner = () => {
    const { step, stepArray, nextStep, prevStep, setStep } = useStep();

    const { hasRemainingPartners } = useParams(); // Fetch param to determine if it's for remaining partners

    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const { createAccountToken, createAccount, token } = useStripeAccountToken();
    const [email, setEmail] = useState(user.email);
    const userPartner = useSelector((state) => state.auth.user.partnerID);
    const dispatch = useDispatch();

    const { setLoading } = useLoader();
    const { showError } = useContext(ErrorContext);

    const [selectedPartners, setSelectedPartners] = useState({}); // Track selected partners
    const [filteredPartners, setFilteredPartners] = useState([]); // Filtered partners to display

    // Function to parse town and postal code from address
    const parseAddress = (address) => {
        const postalCodeMatch = address.match(/\b\d{5}\b/); // Matches a 5-digit postal code
        const townMatch = address.match(/\b\d{5}\s+([\w\s'-]+)/); // Matches postal code and captures the town
        const postalCode = postalCodeMatch ? postalCodeMatch[0] : "Non renseigné";
        const town = townMatch ? townMatch[1].split(",")[0] : "Non renseigné"; // Extract town before the comma
        return { postalCode, town };
    };

    // Initialize partner selection state
    useEffect(() => {
        const partners = user.partnerID.filter((partner) => !partner.subscriptionID?.connectedAccountId) // Only show partners without connectedAccountId
        setFilteredPartners(partners);

        const initialSelections = {};
        partners.forEach((partner) => {
            initialSelections[partner._id] = true;
        });
        setSelectedPartners(initialSelections);
    }, [user.partnerID]);

    // Handle partner checkbox selection toggle
    const handleCheckboxChange = (partnerId) => {
        setSelectedPartners((prevState) => ({
            ...prevState,
            [partnerId]: !prevState[partnerId],
        }));
    };

    // Handle form submission
    const handleSubmit = async () => {
        try {

            if (!user.partnerID || user.partnerID.length === 0) {
                showError("Aucun établissement disponible pour la configuration.");
                return;
            }

            const selectedPartnerIds = Object.keys(selectedPartners).filter(
                (partnerId) => selectedPartners[partnerId]
            );

            if (selectedPartnerIds.length === 0) {
                alert("Veuillez sélectionner au moins un établissement.");
                return;
            }

            setLoading(true);

            // Create account token for the first selected partner
            const firstPartner = user.partnerID.find(
                (partner) => partner._id === selectedPartnerIds[0]
            );

            const accountToken = await createAccountToken(firstPartner, true);

            if(accountToken) {
                // Create the Stripe account for the first selected partner
                await createAccount(accountToken, email, selectedPartnerIds);
            } else {
                showError("Une erreur s'est produite lors de la configuration.");
            }
        } catch (error) {
            console.error("Error submitting multi-partner account:", error);
            showError("Une erreur s'est produite lors de la configuration.");
        }
    };

    const handleNavigateToNextStep = () => {
        nextStep(user, navigate);
    }

    const handleChangeForm = (e) => {
        setEmail(e.target.value);
    }

    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(item => item.label)} />
            </div>
            <div className="main__container__col has-margin-top width-600">
                <div className="main__container__col32">
                    <img src={key} alt="logo"/>
                </div>
                <div className="main__container__col32 center">
                    <h1 className={"title-25"}>
                        {hasRemainingPartners
                            ? "Établissements sans informations de paiement"
                            : "Vous possédez plusieurs établissements"}
                    </h1>
                    <p className={"text-14 light"}>
                        {hasRemainingPartners
                            ? `Il reste encore ${filteredPartners.length} établissements sans compte de paiement configuré. Mettez à jour leurs informations pour commencer à recevoir des paiements.`
                            : "Sélectionnez les établissements que vous souhaitez relier à ce compte de paiement Stripe."}
                    </p>
                </div>

                <div className={"form__login"}>
                    {
                        filteredPartners && filteredPartners.length > 0 && filteredPartners.map((partner, index) => (
                            <div key={index}
                                 className={`stripe_partner_item ${selectedPartners[partner._id] ? "active" : ""}`}>
                                <div className={"stripe_partner_item__content"}>
                                    <p className={"text-16 bold"}>{partner.name}</p>
                                    <p className={"text-14"}>Ville
                                        : {parseAddress(partner.address).town}, {parseAddress(partner.address).postalCode}</p>
                                </div>
                                <input
                                    type="checkbox"
                                    name={`partner-${partner._id}`}
                                    id={`partner-${partner._id}`}
                                    checked={selectedPartners[partner._id] || false}
                                    onChange={() => handleCheckboxChange(partner._id)}
                                />
                            </div>
                        ))
                    }
                </div>

                <div className={"flex-end"}>
                    <p className={"text-14 light"}>Besoin d'aide ? <a href={"mailto:contact.frimity@gmail.com"} className={"text-14 bold"}>Contact.frimity@gmail.com</a></p>
                </div>
                <Button text={"Suivant"} isFull={true} isPrimary={true} onClick={() => {
                    handleSubmit();
                }}/>

                {
                    hasRemainingPartners &&
                    <Button text={"Passer cette étape"} isFull={true} isPrimary={false} onClick={() => {
                        handleNavigateToNextStep();
                    }}/>
                }

            </div>
        </div>
    );
}

export default StripeMultiPartner;