import React, {useContext} from "react";
import '../../../styles/views/stripe-payement.css';
import Button from "../../../components/button/button";
import loop from "../../../assets/images/svg/loop.svg";
import UsersServices from "../../../api/services/users";
import {useSelector, useDispatch} from "react-redux";
import {userUpdateSubscription} from "../../../redux/slice/authSlice";
import {useNavigate} from "react-router-dom";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {usePartner} from "../../../context/partnerContext";
import check from "../../../assets/images/svg/check_round_green.svg";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";

const ConfirmPayment = () => {

    const user = useSelector((state) => state.auth.user);
    const { currentPartnerId, setPartnerId, clearPartnerId } = usePartner();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    //reset user in redux
    const handleUpdateUser = () => {
        setLoading(true);
        UsersServices.getUserById(user._id)
        .then((res) => {
            if(res.status === 200){
                clearPartnerId();

                if(user.partnerID && user.partnerID.length === 1) {
                    setPartnerId(user.partnerID[0]._id);
                    navigate(`/dashboard/${user.partnerID[0]._id}`);
                } else if (user.partnerID && user.partnerID.length > 1) {
                    navigate('/dashboard-partners');
                } else {
                    navigate('/dashboard');
                }
            }
        })
        .catch((error) => {
            showError(error);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return(
        <div className="main confirm_container">
            <div className="main__container confirm_payement">
                <div className="main__container__col32">
                    <img src={check} alt="logo"/>
                </div>
                <h1 className={"title-25"}>Votre abonnement à bien été pris en compte pour l'établissement <span
                    className={"is__yellow"}>{user.partnerID.find(partner => partner._id === currentPartnerId).name}</span>
                </h1>
                <div className={"confirm_payement__text"}>
                    <Button text={"Revenir sur votre espace"} isPrimary={true} isFull={true}
                            onClick={handleUpdateUser}/>
                </div>
            </div>
            <img src={loop} className={"frimity_loop"} alt="logo"/>
        </div>
    )

}

export default ConfirmPayment;