import React from 'react';
import Button from "../components/button/button";
import pending from "../assets/images/svg/illustration_search.svg";
import {useSelector} from "react-redux";
import {usePartner} from "../context/partnerContext";

const NotFound = () => {

    //get user from redux
    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);


    return (
        <div className={"main-container center"}>
            <div className={"center__container"}>
                <img src={pending} alt={"pending"}/>
                <h1>Oupss, je crois que tu t'es perdu !</h1>
                <p className={"text-16 center"}>
                    Cette page n'existe pas !
                    <br/>
                    tu peux retourner à l'accueil
                </p>

                <Button text={"Retour à l'accueil"} link={
                    user && user.role === "admin" || user.role === "super-admin" ?
                        "/"
                    : user && user.role === "partner" || user.role === "partners-admin" && currentPartner.subscriptionID && currentPartner.subscriptionID.subscriptionPlan.name === "essentiel" ?
                        `/partners/${currentPartner._id}`
                    : user && user.role === "partner"  || user.role === "partners-admin" ?
                        `/dashboard/${currentPartner._id}`
                    : "/"}
                    isPrimary={true} isBig={true}/>

            </div>
        </div>
    )
}

export default NotFound;