import React, {useEffect, useState} from 'react';
import HeaderView from "../../components/header/headerView";
import RoundStat from "../../components/stats/roundStat";
import CardStat from "../../components/stats/cardStat";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import Dot from "../../components/dot/dot";
import Button from "../../components/button/button";
import useStatusName from "../../hooks/parseText/useStatusName";
import useOpenModal from "../../hooks/useOpenModal";
import TabBlock from "../../components/navbar/tabBlock";
import OffersServices from "../../api/services/offers";
import ModalIsVerified from "../../components/modal/modalTable/modalIsVerified";
import FilterTable from "../../components/table/filterTable";
import useFilter from "../../hooks/useFilter";
import {useContext} from "react";
import {ErrorContext} from "../../context/errorContext";
import EntitiesVerifiedServices from "../../api/services/entitiesVerified";
import {useLoader} from "../../context/loaderContext";

import useVerificationHandlers from "../../hooks/verification/entitiesVerification";
import ActivitiesServices from "../../api/services/activities";

const VerificationOffers = () => {

    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [activities, setActivities] = useState([]);

    //get offers list from api
    const [offers, setOffers] = useState([]);
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { handleOffersVerification } = useVerificationHandlers();

    const [isModalOpen, openModal, closeModal] = useOpenModal(offers.map(() => false));
    const { updateFilters, filteredData } = useFilter([], offers, (offer, filters, query) => {
        const matchesFilters = filters.length === 0 || filters.includes(offer.status);
        const matchesQuery =
            query === '' ||
            offer.name.toLowerCase().includes(query.toLowerCase()) ||
            activities
                .find((activity) => activity._id === offer.activityID._id)
                ?.name.toLowerCase()
                .includes(query.toLowerCase());

        return matchesFilters && matchesQuery;
    });

    useEffect(() => {
        setLoading(true);
        try{
            const response =  OffersServices.getOffers();
            const activitiesResponse = ActivitiesServices.getActivities();

            if(response.status === 200){
                setOffers(response.data);
                setRefresh(false);
            }

            if(activitiesResponse.status === 200){
                setActivities(activitiesResponse.data);
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [refresh]);

    return (
        <div className={"main-container"}>
            <HeaderView title={"Vérification offre"} actions={
                <>
                    <TabBlock
                        tabs={[
                            {name: "Partenaires", link: "/verification/offers"},
                            {name: "Activités", link: "/verification/activities"},
                            {name: "Offres", link: "/verification/offers"}
                        ]}
                        activeLink={2}
                    />
                </>
            }/>


            <div className={"main-container__row"}>
                <div className={"flex_row stats_rounds"}>
                    <div className={"flex_col"}>
                        <RoundStat title={"Validée"}
                                   value={offers.filter((offer) => offer.isVerified === true).length}
                                   color={"#47B05E"}/>
                    </div>
                    <div className={"flex_col"}>
                        <RoundStat title={"En attente"} value={offers.filter((offer) => offer.isVerified === false).length} color={"#FFC107"} />
                    </div>
                </div>

                <div className={"flex_col stats_cards"}>
                    <div className={"grid grid_2"}>
                        <CardStat title={"Acquis"}
                                  value={offers.filter((offer) => offer.status === "active").length}
                                  color={"#47B05E"} list={offers.filter((offer) => offer.status === "active")}
                                  titleModal={"Partenaires acquis"} status={"active"}/>
                        <CardStat title={"En cours d'acquisition"}
                                  value={offers.filter((offer) => offer.status === "waiting").length}
                                  status={"#000000"}
                                  list={offers.filter((offer) => offer.status === "waiting")}
                                  titleModal={"Partenaires en cours d'acquisition"} status={"waiting"}/>
                        <CardStat title={"Refusé"}
                                  value={offers.filter((offer) => offer.status === "inactive").length}
                                  color={"#FF4D42"} list={offers.filter((offer) => offer.status === "inactive")}
                                  titleModal={"Partenaires refusés"} status={"inactive"}/>
                    </div>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {filterList.length > 0 && (
                    <FilterTable
                        filterArray={filterList}
                        onFilterChange={updateFilters}
                        typeValue="offer"
                        searchData={offers}
                    />
                )}
                {
                    offers.length === 0 || offers.every((offer) => offer.isVerified === true)? (
                        // No partners at all
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucunes offres à vérifier pour le moment</p>
                        </div>
                    ) : filteredData.filter((offer) => offer.isVerified === false).length === 0 ? (
                        // No results found for the current search query or filters
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucunes offres ne correspond à votre recherche</p>
                        </div>
                    ) : (
                        <Table
                            columns={[
                                {name: "Offres", align: "left", width: "30%", field: "offer"},
                                {name: "Réduction", align: "left", width: "30%", field: "reduction"},
                                {name: "Status", align: "left", width: "20%", field: "status"},
                                {
                                    name: "Actions", align: "center", width: "30%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "actions"
                                },
                                {
                                    name: "Voir plus", align: "center", width: "15%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "link"
                                },
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.filter((offer) => offer.isVerified === false).map((offer, index) => {
                                            return {
                                                offer: offer.name,
                                                reduction: offer.discount,
                                                status: <Tag text={getStatusName(offer.status)} status={offer.status}/>,
                                                actions: (
                                                    <Dot size={"big"} click={() => openModal(index)}>
                                                        <ModalIsVerified
                                                            isOpen={isModalOpen[index]}
                                                            closeModal={() => closeModal(index)}
                                                            handleChange={(status) => handleOffersVerification(offer._id, status, setRefresh)}
                                                            type={"offer"}
                                                            link={`/partners/${offer.partnerID._id}/offers/${offer._id}`}
                                                        />
                                                    </Dot>
                                                ),
                                                link: <Button text={"Voir le dossier"} link={`/partners/${offer.partnerID._id}/offers/${offer._id}`}/>
                                            }
                                        }
                                    ).reverse() : []
                            }
                        />
                    )
                }
            </div>
        </div>
    );
}

export default VerificationOffers;