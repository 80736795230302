import React, { useState } from 'react';
import searchIcon from '../../assets/icones/global/search.svg';
import '../../styles/components/searchbar.css';
import ModalSearch from "../modal/modalSearch";

const Searchbar = ({ searchData, typeValue, onSearchChange }) => {
    // State to store the search query
    const [searchQuery, setSearchQuery] = useState('');
    const [finalData, setFinalData] = useState(searchData);

    // Event handler for input change
    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        onSearchChange(query); // Pass the query to the parent
    };

    return (
        <div className="searchbar">
            <div className="searchbar__container">
                <img src={searchIcon} alt="icon search"/>
                <input
                    type="text"
                    placeholder="Rechercher"
                    value={searchQuery}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    );
}

export default Searchbar;
