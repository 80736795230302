import ActivitiesServices from "../../api/services/activities";
import EntitiesVerifiedServices from "../../api/services/entitiesVerified";
import { useContext } from "react";
import { ErrorContext } from "../../context/errorContext";
import { useLoader } from "../../context/loaderContext";
import OffersServices from "../../api/services/offers";
import PartnersServices from "../../api/services/partners";

const useVerificationHandlers = () => {
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const handleActivitiesVerification = async (activityId, partnerId, status, setRefresh) => {
        const data = {
            isVerified: true,
            status: status
        }
        setLoading(true);

        try {
            await ActivitiesServices.updateActivity(activityId, partnerId, data);
            await EntitiesVerifiedServices.sendForActivityVerified(activityId);
            setRefresh(true);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    const handleOffersVerification = async (offerId, status, setRefresh) => {
        const data = {
            isVerified: true,
            status: status
        }

        setLoading(true);

        try {
            await OffersServices.updateOffer(offerId, data);
            await EntitiesVerifiedServices.sendForOfferVerified(offerId);
            setRefresh(true);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    const handlePartnersVerification = async (partnerId, status, setRefresh) => {
        const data = {
            isVerified: true,
            status: status
        }
        setLoading(true);

        try {
            await PartnersServices.updatePartner(partnerId, data);
            await EntitiesVerifiedServices.sendForPartnerVerified(partnerId);
            setRefresh(true);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    return {
        handleActivitiesVerification,
        handleOffersVerification,
        handlePartnersVerification
    };
};

export default useVerificationHandlers;
