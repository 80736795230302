import React, {useState, useEffect, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import add from '../../assets/icones/global/add.svg';
import TabBar from "../../components/navbar/tabBar";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import Dot from "../../components/dot/dot";
import {useParams} from "react-router";
import useStatusName from "../../hooks/parseText/useStatusName";
import ActivitiesServices from "../../api/services/activities";
import useOpenModal from "../../hooks/useOpenModal";
import Button from "../../components/button/button";
import useFilter from "../../hooks/useFilter";
import FilterTable from "../../components/table/filterTable";
import '../../styles/views/activities.css';
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import ModalActivity from "../../components/modal/modalTable/modalActivity";
import {useSelector} from "react-redux";
import {usePartner} from "../../context/partnerContext";
import check from "../../assets/icones/global/check_stroke.svg";
import useVerificationHandlers from "../../hooks/verification/entitiesVerification";

const Activities = () => {
    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);

    const partnerId = useParams().partnerId;
    const [activities, setActivities] = useState([]);
    const [nbOffers, setNbOffers] = useState(0);

    const { getStatusName } = useStatusName();
    const { handleActivitiesVerification } = useVerificationHandlers();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { currentPartnerId, allPartners } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = user && user.role === "partner" ?
        user.partnerID.find(partner => partner._id === currentPartnerId) :
        allPartners.length > 0 && allPartners.find(partner => partner._id === currentPartnerId);


    // Initialize open states for modals, all set to `false`
    const [isModalOpen, openModal, closeModal] = useOpenModal([]);
    const { updateFilters, filteredData } = useFilter([], activities, (activity, filters, query) => {
        const matchesFilters = filters.length === 0 || filters.includes(activity.status);
        const matchesQuery = query === '' || activity.name.toLowerCase().includes(query.toLowerCase());
        return matchesFilters && matchesQuery;
    });

    useEffect(() => {
        setRefresh(false)
        setLoading(true);
        ActivitiesServices.getActivitiesByPartnerId(partnerId)
            .then((response) => {
                if(response.status === 200){
                    setActivities(response.data.reverse());
                    setRefresh(false);
                    setFilterList([...new Set(response.data.map((activity) => activity.status))]);
                    setNbOffers(response.data.map((activity) => activity.offers.length).reduce((a, b) => a + b, 0));

                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            })

    }, [refresh]);

    const handleDuplicate = (activityId) => {
        setLoading(true);
        ActivitiesServices.duplicateActivity(partnerId, activityId)
        .then((response) => {
            if(response.status === 200){
                setRefresh(true);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleDelete = (activityId) => {
        setLoading(true);
        ActivitiesServices.deleteActivity(activityId, partnerId)
        .then((response) => {
            if(response.status === 200){
                setRefresh(true);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleDraft = async (activityId) => {
        try{
            setLoading(true);

            const data = {
                status: "draft"
            }
            await ActivitiesServices.updateActivity(activityId, partnerId, data);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
            setRefresh(true);
        }
    }


    return activities && (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Activités proposées"} actions={
                    <>
                        <IconButton icon={add} text={"Ajouter une activité"} isPrimary={false} link={`/partners/${partnerId}/activities/add`} />
                    </>
            } isActionsWithTitle={true} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: activities.length
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`
                    },
                    {
                        name: "Utilisateurs",
                        link: `/partners/${partnerId}/users`,

                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={1}
            />


            <div className={"header__container activities"}>
                <h1 className={"title-20"}><span className={"text-20"}>Nom de votre établissement : </span>{currentPartner.name}</h1>
                <div className={"header__container__col"}>

                </div>
            </div>

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h2 className={"title-18"}>Détail des activités</h2>
                    <p className={"text-14"}>Vous pouvez voir ou modifier vos informations concernant vos activités proposées</p>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={(data) => updateFilters(data)}
                            typeValue="activity"
                            searchData={activities}
                        />
                        :
                        null
                }
                {
                    activities.length === 0 ? (
                        // No activities at all
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Vous n'avez pas encore d'activité</p>
                            <Button
                                text={"Ajouter une activité"}
                                link={`/partners/${partnerId}/activities/add`}
                                isPrimary={true}
                            />
                        </div>
                    ) : filteredData.length === 0 ? (
                        // No results found for the current search query
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucune activité ne correspond à votre recherche</p>
                        </div>
                    ) : (
                        // Render the table if there are filtered results
                        <Table
                            columns={[
                                { name: "Référence", align: "left", width: "15%", field: "reference" },
                                { name: "Activité", align: "left", width: "15%", field: "activity" },
                                { name: "Statut", align: "left", width: "15%", field: "status" },
                                {
                                    name: "Actions",
                                    align: "center",
                                    width: "10%",
                                    style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    },
                                    field: "actions",
                                },
                                {
                                    name: "Voir plus",
                                    align: "center",
                                    width: "15%",
                                    style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    },
                                    field: "link",
                                },
                            ]}
                            data={filteredData.map((activity, index) => ({
                                reference: activity.reference,
                                activity: activity.name,
                                status: <Tag text={getStatusName(activity.status)} status={activity.status} />,
                                actions: (
                                    <Dot size={"big"} click={() => openModal(index)}>
                                        <ModalActivity
                                            isOpen={isModalOpen[index] || false}
                                            closeModal={() => closeModal(index)}
                                            handleDuplicate={() => handleDuplicate(activity._id)}
                                            handleDelete={() => handleDelete(activity._id)}
                                            handleDraft={() => handleDraft(activity._id)}
                                            handleVerify={
                                                (user.role === "admin" || user.role === "super-admin") && activity.status === "waiting"
                                                    ? () => handleActivitiesVerification(activity._id, partnerId, "active", setRefresh)
                                                    : null
                                            }
                                            isDraft={activity.status === "draft"}
                                        />
                                    </Dot>
                                ),
                                link: (
                                    <Button
                                        text={"Voir l'activité"}
                                        link={`/partners/${partnerId}/activities/${activity._id}`}
                                    />
                                ),
                            }))}
                        />
                    )
                }
            </div>

        </div>
    )
}

export default Activities;