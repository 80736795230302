import React from 'react';
import '../../styles/components/avatar.css';
import useAvatarBackground from "../../hooks/useAvatarBackground";

const Avatar = ({avatarURL = null, name = null}) => {

    const avatarBackground = name ? useAvatarBackground(name) : "#DEDEDE";

    return avatarURL ? (
        <div className="avatar__div">
           <img src={avatarURL} alt={"avatar"}/>
        </div>
    ) : (
        <div className="avatar__div" style={{backgroundColor: avatarBackground}}>
            {name ? name.charAt(0).toUpperCase() : ""}
        </div>
    )
}

export default Avatar;