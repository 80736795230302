import React, {useEffect, useState, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import add from "../../assets/icones/global/add.svg";
import addUser from "../../assets/icones/global/add_user.svg";
import '../../styles/views/users.css'
import trash from "../../assets/icones/global/trash_can_red.svg";
import UsersServices from "../../api/services/users";
import FilterTable from "../../components/table/filterTable";
import Table from "../../components/table/table";
import useFilter from "../../hooks/useFilter";
import useOpenModal from "../../hooks/useOpenModal";
import Button from "../../components/button/button";
import SidebarUser from "../../components/sidebar/sidebarUser";
import {Link} from "react-router-dom";
import ModalDeleting from "../../components/modal/modalDeleting/modalDeleting";
import Avatar from "../../components/avatar/avatar";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import close from "../../assets/icones/global/close-stroke.svg";
import download from "../../assets/icones/global/download.svg";
import TabBar from "../../components/navbar/tabBar";
import {usePartner} from "../../context/partnerContext";
import {useSelector} from "react-redux";
import {useParams} from "react-router";

const UsersPartner = () => {


    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [type, setType] = useState("add");

    const [users, setUsers] = useState([]);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const [openModalAdd ,setOpenModalAdd] = useState(false);

    const [isModalOpen, openModal, closeModal] = useOpenModal(users.map(() => false));
    const [isModalOpenDelete, openModalDelete, closeModalDelete] = useOpenModal(users.map(() => false));

    //get user from redux state
    const partnerId = useParams().partnerId;
    const { currentPartnerId, allPartners } = usePartner();
    const user = useSelector(state => state.auth.user);
    const numberActivity = (() => {
        if (user.role === "partner") {
            const partner = useSelector((state) =>
                state.auth.user.partnerID.find((partner) => partner._id === currentPartnerId)
            );
            return partner?.activityID?.length || 0;
        }

        const partner = allPartners?.find((partner) => partner._id === currentPartnerId);
        return partner?.activityID?.length || 0;
    })();


    const { updateFilters, filteredData } = useFilter([], users, (user, filters, query) => {
        const matchesFilters = filters.length === 0 || filters.includes(user.role);
        const matchesQuery =
            query === '' ||
            user.firstname.toLowerCase().includes(query.toLowerCase()) ||
            user.lastname.toLowerCase().includes(query.toLowerCase()) ||
            user.email.toLowerCase().includes(query.toLowerCase());
        return matchesFilters && matchesQuery;
    });

    useEffect(() => {
        setLoading(true);
        UsersServices.getUsersByPartner(partnerId)
            .then((response) => {
                if(response.status === 200){
                    setUsers(response.data.users);
                    setRefresh(false);
                    setFilterList([...new Set(response.data.users.map((user) => user.role))]);
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [refresh]);

    const handleDelete = (id) => {
        setLoading(true);
        UsersServices.deleteUser(id)
            .then((response) => {
                if (response.status === 200) {
                    setRefresh(true);
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            });
    }



    return filteredData && (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Les utilisateurs de ce compte"} actions={
                <>
                    <IconButton icon={add} text={"Ajouter un nouvel utilisateur"} isPrimary={false} onClick={() => {setOpenModalAdd(true); setType("add")}}/>
                </>
            } isGoBack={true}/>

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: numberActivity ? numberActivity : 0
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`,
                    },
                    {
                        name: "Utilisateurs",
                        link: `/partners/${partnerId}/users`,

                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={3}
            />


            <div className={"header__container"}>
                <h2 className={"title-20"}>Informations sur les utilisateurs du compte</h2>
                <p className={"text-14"}>Vous pouvez ajouter ici les utilisateurs de votre établissement</p>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={updateFilters}
                            typeValue={"users"}
                            searchData={filteredData}
                            isFilter={false}
                        />
                        :
                        null
                }
                {
                    users.length === 0 ? (
                        // No partners at all
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucun partenaire n'a été ajouté pour le moment</p>
                        </div>
                    ) : filteredData && filteredData.filter((user) => user.role === "partner" || user.role === "partners-admin").length === 0 ?
                        // No partners matching the filter
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucun partenaire ne correspond à votre recherche</p>
                        </div>
                    : (
                        <Table
                            columns={[
                                {name: "Avatar", align: "left", width: "10%", field: "avatar"},
                                {name: "Nom/Prénom", align: "left", width: "15%", field: "name"},
                                {name: "Email", align: "left", width: "25%", field: "email"},
                                {name: "Téléphone", align: "left", width: "15%", field: "phoneNumber"},
                                {
                                    name: "Actions", align: "center", width: "20%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "actions"
                                }
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.filter((user) => user.role === "partner" || user.role === "partners-admin").map((user, index) => {
                                            return {
                                                avatar: <Avatar
                                                    avatarURL={user.userAvatarID ? user.userAvatarID.avatarURL : null}
                                                    name={user.firstname}/>,
                                                name: user.firstname + " " + user.lastname,
                                                email: user.email,
                                                phoneNumber: user.phoneNumber ? user.phoneNumber : "Aucun numéro",
                                                actions: (
                                                    <div className={"actions__row"} key={index}>
                                                        <Button text={"Voir plus"} isPrimary={false}
                                                                onClick={() => openModal(index)}></Button>
                                                        <IconButton icon={trash} isRed={true}
                                                                    onClick={() => openModalDelete(index)}/>
                                                        <ModalDeleting isOpen={isModalOpenDelete[index]}
                                                                       closeModal={() => closeModalDelete(index)}
                                                                       deleteItem={() => handleDelete(user._id)}
                                                                       itemName={user.firstname + " " + user.lastname}/>
                                                        <SidebarUser setRefresh={setRefresh} refresh={refresh}
                                                                     isModalOpen={isModalOpen[index]}
                                                                     closeModal={() => closeModal(index)}
                                                                     openModal={() => openModal(index)} user={user}
                                                                     type={"edit"} partnerID={partnerId}/>
                                                    </div>
                                                )
                                            }
                                        }
                                    ).reverse() : []
                            }
                        />

                    )
                }
            </div>
            <SidebarUser setRefresh={setRefresh} refresh={refresh} isModalOpen={openModalAdd} closeModal={() => setOpenModalAdd(false)} openModal={() => setOpenModalAdd(true)} type={type} partnerID={partnerId}/>
        </div>
    );
}
export default UsersPartner;