import React, {useContext, useEffect, useState} from "react";
import useStatusName from "../../../hooks/parseText/useStatusName";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import useOpenModal from "../../../hooks/useOpenModal";
import useFilter from "../../../hooks/useFilter";
import HeaderView from "../../../components/header/headerView";
import IconButton from "../../../components/button/iconButton";
import RoundStat from "../../../components/stats/roundStat";
import CardStat from "../../../components/stats/cardStat";
import FilterTable from "../../../components/table/filterTable";
import Table from "../../../components/table/table";
import Tag from "../../../components/tag/tag";
import Dot from "../../../components/dot/dot";
import ModalStatus from "../../../components/modal/modalTable/modalStatus";
import Button from "../../../components/button/button";
import PartnersServices from "../../../api/services/partners";
import StripeServices from "../../../api/services/stripe";
import {useNavigate} from "react-router-dom";
import {usePartner} from "../../../context/partnerContext";
import trash from "../../../assets/icones/global/trash_can_red.svg";
import ModalDeleting from "../../../components/modal/modalDeleting/modalDeleting";


const StripeAccountsManager = () => {
    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [stripeAccounts, setStripeAccounts] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false); // Control rendering until all data is ready
    const [openDeleteModal, setOpenDeleteModal] = useState(false);


    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading, loading } = useLoader();

    const { setPartnerId, allPartners } = usePartner();
    const navigate = useNavigate();

    const [isModalOpen, openModal, closeModal] = useOpenModal(stripeAccounts.map(() => false));

    const { updateFilters, filteredData } = useFilter([], stripeAccounts, (stripeAccount, filters, query) => {
        const matchesFilters = filters.length === 0 || filters.includes(stripeAccount.status);
        const matchesQuery =
            query === '' ||
            stripeAccount.stripeAccountId.toLowerCase().includes(query.toLowerCase()) || // Match by Stripe Account ID
            stripeAccount.contributor.email.toLowerCase().includes(query.toLowerCase()) || // Match by Email
            stripeAccount.society.toLowerCase().includes(query.toLowerCase()); // Match by Society Name

        return matchesFilters && matchesQuery;
    });


    useEffect(() => {
        const fetchStripeAndPartners = async () => {
            setLoading(true); // Global loader
            setIsDataLoaded(false); // Local loader

            try {
                const [stripeResponse, partnersResponse] = await Promise.all([
                    StripeServices.getConnectedAccounts(),
                    PartnersServices.getAllPartners(),
                ]);

                if (stripeResponse.status === 200 && partnersResponse.status === 200) {
                    const accounts = stripeResponse.data.data;
                    const partners = partnersResponse.data;

                    processAccounts(accounts, partners);
                } else {
                    showError("Failed to fetch data from Stripe or Partners API");
                }
            } catch (error) {
                showError(error.message || "Error fetching accounts");
            } finally {
                setLoading(false);
                setIsDataLoaded(true);
            }
        };

        fetchStripeAndPartners();
    }, [refresh]);

    const processAccounts = (accounts, partners) => {
        const partnerMap = new Map(
            partners.map((partner) => [partner.subscriptionID?.connectedAccountId, partner])
        );

        const processedAccounts = accounts.map((stripeAccount) => {
            const partner = partnerMap.get(stripeAccount.id);

            return {
                contributor: { email: stripeAccount.email || "Non renseigné" },
                society: partner?.name || stripeAccount.business_profile?.name || "Non renseigné",
                partnerName: partner?.name || "Non renseigné",
                partnerID: partner?._id || null,
                status: stripeAccount.charges_enabled
                    ? "complete"
                    : stripeAccount.details_submitted
                        ? "not-completed"
                        : "disabled",
                stripeAccountId: stripeAccount.id,
                country: stripeAccount.country || "Non renseigné",
                currency: stripeAccount.default_currency || "Non renseigné",
                created: stripeAccount.created
                    ? new Date(stripeAccount.created * 1000).toLocaleDateString()
                    : "Non renseigné",
            };
        });

        setStripeAccounts(processedAccounts);
        setFilterList(["complete", "not-completed", "disabled", "unused"]);
    };


    const handleNavigate = (partnerId) => {
        setPartnerId(partnerId); // Store partner ID in context
        navigate(`/partners/${partnerId}/bookings/transactions`); // Redirect to transactions page for the partner
    };

    const handleDelete = async (stripeAccountId) => {
        setLoading(true);
        try {
            const response = await StripeServices.deleteStripeAccount(stripeAccountId);

            if (response.status === 200) {
                setRefresh(!refresh);
            }
        } catch (error) {
            console.error("Error deleting account:", error);
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    return !loading && (
        <div className={"main-container"}>
            <HeaderView title={"Compte Stripe  🏦"} isGoBack={false} actions={<></>}/>
            <div className={"main-container__row"}>
                <div className={"flex_row stats_rounds"}>
                    <div className={"flex_col"}>
                        <div className={"stats_title"}>Comptes actif</div>
                        <RoundStat title={"Actifs"}
                                   value={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "complete").length}
                                   color={"#5433FF"}/>
                    </div>
                    <div className={"flex_col"}>
                        <div className={"stats_title"}>Comptes en attente</div>
                        <RoundStat title={"En attente"}
                                   value={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "not-completed").length}
                                   color={"#4379FF"}/>
                    </div>
                </div>

                <div className={"flex_col stats_cards"}>
                    <div className={"grid grid_2"}>
                        <CardStat title={"Actif"}
                                  value={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "complete").length}
                                  color={"#5433FF"}
                                  list={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "complete")}
                                  titleModal={"Comptes Stripe actifs"} status={"active"}/>
                        <CardStat title={"En attente"}
                                  value={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "not-completed").length}
                                  color={"#4379FF"}
                                  list={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "not-completed")}
                                  titleModal={"Comptes Stripe en attente"} status={"review"}/>
                        <CardStat title={"Information(s) manquante(s)"}
                                  value={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "disabled").length}
                                  color={"#ff948f"}
                                  list={stripeAccounts.filter((stripeAccount) => stripeAccount.status === "disabled")}
                                  titleModal={"Comptes Stripe désactivés"} status={"disabled"}/>
                    </div>
                </div>
            </div>


            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h2 className={"title-18"}>Liste des utilisateurs du dashboard</h2>
                    <p className={"text-14"}>Ici, tu retrouveras tous les comptes stripe enregistré sur Frimity.</p>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 && (
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={updateFilters}
                            typeValue={"stripeAccount"}
                            searchData={stripeAccounts}
                            searchPlaceholder="Rechercher par ID de compte, email ou société"
                            onSearchChange={(query) => updateFilters(query)}
                        />
                    )
                }
                {
                    filteredData && filteredData.length > 0 ?
                        <Table
                            columns={[
                                { name: "Numéro de compte", field: "stripeAccountId" },
                                { name: "Bénéficiaire", field: "email" },
                                { name: "Société", field: "society" },
                                { name: "Créé le", field: "created" },
                                { name: "Status", field: "status" },
                                { name: "Actions", field: "actions" },
                            ]}
                            data={filteredData.map((stripeAccount) => ({
                                stripeAccountId: stripeAccount.stripeAccountId,
                                email: stripeAccount.contributor.email,
                                society: stripeAccount.society,
                                created: stripeAccount.created,
                                status: (
                                    <Tag
                                        text={getStatusName(stripeAccount.status)}
                                        status={stripeAccount.status}
                                    />
                                ),
                                actions: (
                                    <div>
                                        {stripeAccount.partnerID && (
                                            <Button
                                                text="Voir"
                                                onClick={() => handleNavigate(stripeAccount.partnerID)}
                                            />
                                        )}
                                        <IconButton
                                            icon={trash}
                                            isRed={true}
                                            onClick={() =>
                                                setOpenDeleteModal({
                                                    open: true,
                                                    id: stripeAccount.stripeAccountId,
                                                })
                                            }
                                        />
                                    </div>
                                ),
                            }))}
                        />
                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucun compte Stripe n'a été ajouté pour le moment</p>
                        </div>
                }
            </div>

            <ModalDeleting
                isOpen={openDeleteModal.open}
                closeModal={() => setOpenDeleteModal({open: false, id: ""})}
                deleteItem={() => handleDelete(openDeleteModal.id)}
                itemName={"ce compte Stripe"}
            />
        </div>
    )
}

export default StripeAccountsManager;

