import React, { useState, useEffect, useRef } from 'react';

const SelectDropdownCustom = ({ label, options, selectedValues = [], onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]); // Initialize as empty
    const dropdownRef = useRef(null);

    // Sync `selectedOptions` with `selectedValues` when `selectedValues` changes
    useEffect(() => {
        // Avoid duplications by checking if the array is identical before setting
        if (JSON.stringify(selectedOptions) !== JSON.stringify(selectedValues)) {
            setSelectedOptions(selectedValues || []);
        }
    }, [selectedValues]);

    // Handle clicks outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle selection and deselection of options
    const handleSelectOption = (value) => {
        // Avoid adding duplicates
        let updatedSelectedOptions;
        if (selectedOptions.includes(value)) {
            // Remove the value if already selected
            updatedSelectedOptions = selectedOptions.filter((option) => option !== value);
        } else {
            // Add the value if not already selected
            updatedSelectedOptions = [...selectedOptions, value];
        }

        setSelectedOptions(updatedSelectedOptions); // Update local state
        onChange([...updatedSelectedOptions]); // Notify parent of the updated selection
    };

    return (
        <div className="select-dropdown-container" ref={dropdownRef}>
            <label className="select-dropdown-label">{label}</label>
            <div
                className="select-dropdown-input"
                onClick={() => setIsOpen(!isOpen)}
            >
                Sélectionner...
            </div>
            {isOpen && (
                <div className="select-dropdown-list">
                    {options.map((option) => (
                        <div key={option._id} className="select-dropdown-item">
                            <input
                                type="checkbox"
                                checked={selectedOptions.includes(option._id)} // Preselect based on `selectedOptions`
                                onChange={() => handleSelectOption(option._id)}
                            />
                            <label>{option.name}</label>
                        </div>
                    ))}
                </div>
            )}
            {/* Display selected items */}
            <div className="select-dropdown-selected">
                {selectedOptions.map((optionId) => {
                    const selectedOption = options.find((o) => o._id === optionId);
                    return (
                        selectedOption && (
                            <div key={selectedOption._id} className="select-dropdown-selected-item">
                                {selectedOption.name}
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default SelectDropdownCustom;
