import React, {useEffect, useState} from 'react';
import key from '../../../assets/images/svg/key_icon.svg';
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import {useParams} from "react-router";
import VerificationServices from "../../../api/services/verification";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {setEmailSent} from "../../../redux/slice/signInSlice";
import Input from "../../../components/form/inputText/input";
import InputPassword from "../../../components/form/inputText/inputPassword";
import useAuth from "../../../hooks/useAuth";
import useParseErrorMessage from "../../../hooks/parseText/useParseErrorMessage";
import SubmitButton from "../../../components/button/submitButton";
import StepBar from "../../../components/stepBar/stepBar";

const LoginVerification = () => {

    const [step, setStep] = useState(1);
    const { login, error } = useAuth();
    const {parseErrorMessage} = useParseErrorMessage();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleChangeForm = (e) => {
        if(e.target.name === 'email') {
            setEmail(e.target.value);
        } else {
            setPassword(e.target.value);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const credentials = {
            email: email,
            password: password
        };
        login(credentials);
    }


    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step}/>
            </div>
            <div className="main__container__col">
                <div className="main__container__col32">
                    <img src={key} alt="logo"/>
                </div>
                <div className="main__container__col32 center">
                    <h1 className={"title-25"}>Vérification de votre identité</h1>
                    <p className={"text-14 light"}>Nous allons d'abord vérifier votre identité. Veuillez entrer votre
                        email ainsi que votre mot de passe temporaire.</p>
                </div>

                <form className={"form__login"} onSubmit={handleSubmit}>
                    <Input
                        label={"Email"}
                        name={"email"}
                        placeholder={"Entrez votre email"}
                        type={"email"}
                        validationType={"email"}
                        isColumn={true}
                        onChangeForm={handleChangeForm}
                    />

                    <InputPassword
                        label={"Mot de passe (temporaire)"}
                        name={"password"}
                        placeholder={"Entrez votre mot de passe"}
                        type={"password"}
                        isColumn={true}
                        onChangeForm={handleChangeForm}

                    />

                    <SubmitButton text={"Continuer"} isFull={true}/>
                </form>

                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/login"} className={"text-14 bold"}>Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
}

export default LoginVerification;