import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Textarea from "../../../components/form/InputTextarea/textarea";

const Expected = ({expected, setActivity, isDisabled}) => {

      const handleChange = (event) => {
        event.preventDefault();
        const {value } = event.target;

        setActivity(`expected.value`, value);
    }


    return(
        <FormSectionRow title={"À prévoir"}>
            <Textarea
                title={expected.label}
                name="expected"
                value={expected.value}
                onChange={handleChange}
                errorEmpty={expected.error}
                errorMessage={expected.errorMessage}
                placeholder={expected.error ? "Remplissez ce champ" : expected.placeholder}
                isDisabled={isDisabled}
            />
        </FormSectionRow>
    )
}

export default Expected;