import React, { useState, useEffect, useCallback } from 'react';
import { AddressAutofill, config } from '@mapbox/search-js-react';

const InputAddress = ({title, name, placeholder, icon = false, isRequired = false, errorEmpty = false, errorMessage = false, onChangeForm, valueInput, isRow = false, disabled = false}) => {
    const [addressDetails, setAddressDetails] = useState({
        addressLine: '',
        city: '',
        country: '',
        postalCode: '',
        longitude: '',
        latitude: '',
    });
    const [token, setToken] = useState('');

    useEffect(() => {
        const accessToken = process.env.REACT_APP_MAPBOX_TOKEN; // Ensure this is the correct env variable
        setToken(accessToken);
        config.accessToken = accessToken;
    }, []);

    const handleRetrieve = useCallback((res) => {
        const feature = res.features[0];
        if (feature) {
            const { place_name, context } = feature.properties;
            const { geometry } = feature;
;
            // Extract the relevant address components based on your requirements and context structure
            const fullAddressDetails = {
                addressLine: place_name,
                // The context array structure might vary depending on the specific location and data available from Mapbox
                city: context.find(c => c.id.startsWith('place')).text,
                country: context.find(c => c.id.startsWith('country')).text,
                postalCode: context.find(c => c.id.startsWith('postcode')).text,
                longitude: geometry.coordinates[0],
                latitude: geometry.coordinates[1],
            };

            setAddressDetails(fullAddressDetails);
            onChangeForm(name, fullAddressDetails);
        }
    }, [onChangeForm, name]);

    // Synchronize external valueInput with local state
    useEffect(() => {
        setAddressDetails(prevDetails => ({
            ...prevDetails,
            addressLine: valueInput || prevDetails.addressLine,
        }));
    }, [valueInput]);

    return (
        <div className={`form__col address ${isRow ? 'row' : ''}`}>
            {title && (
                <p className="text-14 form__row__label">
                    {title} {isRequired && <span className="is__required">*</span>}
                </p>
            )}
            <div className={`access-container ${isRow ? 'col' : ''}`}>
                <div className={`form__row__input ${errorMessage || errorEmpty ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
                    {icon && <div className="form__row__icon">
                        <img src={icon} alt=""/>
                    </div>}
                    <AddressAutofill accessToken={token} onRetrieve={handleRetrieve} country="fr">
                        <input
                            type="text"
                            className="form__input__icon"
                            name={name}
                            placeholder={errorEmpty ? "Remplissez ce champ" : placeholder}
                            value={addressDetails.addressLine}
                            onChange={(e) => {
                                const newAddressLine = e.target.value;
                                setAddressDetails(prevDetails => ({
                                    ...prevDetails,
                                    addressLine: newAddressLine
                                }));
                                // Optional: Call onChangeForm with every change if you want to lift the state up immediately
                                onChangeForm(name, {...addressDetails, addressLine: newAddressLine});
                            }}
                            disabled={disabled}
                            autoComplete="off" // Changed to "off" to avoid native browser autocomplete interference
                            id="mapbox-autofill"
                        />
                    </AddressAutofill>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {errorEmpty && <div className="error-message">Remplissez ce champ</div>}
            </div>
        </div>
    );
};

export default InputAddress;
