import React, {useContext, useEffect, useState} from 'react';
import key from '../../../../assets/images/svg/rocket_icon.svg';
import { Link } from "react-router-dom";
import arrow from '../../../../assets/icones/arrows/left.svg';
import '../../../../styles/views/verifications.css';
import StepBar from "../../../../components/stepBar/stepBar";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Input from "../../../../components/form/inputText/input";
import SubmitButton from "../../../../components/button/submitButton";
import Button from "../../../../components/button/button";
import {usePartner} from "../../../../context/partnerContext";
import {useStripeAccountToken} from "../../../../hooks/stripe/useStripeToken";
import {useStep} from "../../../../context/login/stepContext";
import {useLoader} from "../../../../context/loaderContext";
import {ErrorContext} from "../../../../context/errorContext";

const StripeAccountInfos = () => {
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const { createAccountToken, createAccount } = useStripeAccountToken();

    const [email, setEmail] = useState(user.email);

    const {allPartners, callAllPartners} = usePartner();
    const userPartner = useSelector((state) => state.auth.user.partnerID);

    const { setLoading } = useLoader();
    const { showError } = useContext(ErrorContext);
    const { step, stepArray, nextStep, prevStep, setStep } = useStep();


    useEffect(() => {
        callAllPartners();
    }, []);

    const handleSubmit = async (e) => {
        try {
            if (!email) {
                showError("Email is required to create a Stripe account.");
                return;
            }

            if (userPartner.length === 1) {
                setLoading(true);
                // Single partner flow
                const partner = userPartner[0];
                const accountToken = await createAccountToken(partner, true);

                if (accountToken) {
                    // Create the Stripe account for the single partner
                    await createAccount(accountToken, email, [partner._id]);
                }
            } else if (userPartner.length > 1) {
                // Multiple partners flow
                // Navigate to the selection page for multiple partners
                navigate(`/login/first-login/stripe/multi-partner`);
            } else {
                showError("No partners available for connection.");
            }
        } catch (error) {
            console.error("Error submitting account info:", error);
            showError("An unexpected error occurred. Please try again.");
        }
    };

    const handleChangeForm = (e) => {
        setEmail(e.target.value);
    }

    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(item => item.label)} />
            </div>
            <div className="main__container__col has-margin-top width-600">
                <div className="main__container__col32">
                    <img src={key} alt="logo"/>
                </div>
                <div className="main__container__col32 center">
                    <h1 className={"title-25"}>Une dernière étape avant de commencer</h1>
                    <p className={"text-14 light"}>Stripe est une solution reconnue pour sécuriser et simplifier la
                        gestion des paiements en ligne. Elle permet de centraliser vos transactions et de garantir un
                        traitement fluide et sécurisé de vos paiements.</p>
                </div>

                <form className={"form__login margin-32"}>
                    <Input
                        label={"Choisissez l'email que vous souhaitez utiliser pour recevoir vos paiements"}
                        name={"email"}
                        placeholder={"Entrez votre email"}
                        type={"email"}
                        validationType={"email"}
                        isColumn={true}
                        valueInput={user.email}
                        onChangeForm={handleChangeForm}
                    />
                </form>

                <div className={"flex-end"}>
                    <p className={"text-14 light"}>Besoin d'aide ? <a href={"mailto:contact.frimity@gmail.com"}
                                                                      className={"text-14 bold"}>Contact.frimity@gmail.com</a>
                    </p>
                </div>

                <Button text={"Suivant"} isFull={true} isPrimary={true} onClick={() => {
                    handleSubmit();
                }}/>

            </div>
        </div>
    );
}

export default StripeAccountInfos;