import React, {useState} from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Radio from "../../../components/form/inputRadio/radio";

const BookingPolicy = ({bookingPolicy, setActivity, isDisabled}) => {

    const [checked, setChecked] = useState(bookingPolicy.value);

    const handleChange = (event) => {
        setChecked(event.target.value);
        event.preventDefault();
        const { value} = event.target;

        setActivity(`bookingPolicy.value`, value);
    }

    return(
        <FormSectionRow title={"Politique de la réservation "} isRequired={true} description={"À remplir"} isRow={true}>
            <Radio name={"bookingPolicy"} label={"Instantanée"} value={"instant"} checked={bookingPolicy.value === "instant" || checked === "instant"} onChange={handleChange} errorEmpty={bookingPolicy.error} errorMessage={bookingPolicy.errorMessage} disabled={isDisabled}/>
            <Radio name={"bookingPolicy"} label={"En attente de validation par le partenaire"} value={"pending"} checked={bookingPolicy.value === "pending" || checked === "pending"} onChange={handleChange} errorEmpty={bookingPolicy.error} errorMessage={bookingPolicy.errorMessage} disabled={isDisabled}/>
        </FormSectionRow>
    )
}

export default BookingPolicy;