import React, {useState} from "react";
import check from "../../../../assets/images/svg/check_round_green.svg";
import Button from "../../../../components/button/button";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../../../context/loaderContext";
import BookingPartnersServices from "../../../../api/services/bookingPartners";
import { userPartnerUpdateBooking, updatePartnerConnectedAccount } from "../../../../redux/slice/authSlice";
import '../../../../styles/views/payments.css';
import {useStep} from "../../../../context/login/stepContext";
import StepBar from "../../../../components/stepBar/stepBar";
import BookingPaymentServices from "../../../../api/services/bookingPayment";

const StripeAccountSuccess = () => {

    const { connectedAccountId } = useParams();
    const { setLoading } = useLoader();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const { step, stepArray, nextStep, goBackToStep, prevStep, setStep } = useStep();

    const [remainingPartners, setRemainingPartners] = useState(
        user.partnerID.filter(
            (partner) => !partner.subscriptionID.connectedAccountId // Filter partners without a connectedAccountId
        )
    );

    const handleCreateBookingPartners = async () => {
        setLoading(true);

        try {
            // Group partners by `connectedAccountId`
            const groupedPartners = user.partnerID.reduce((acc, partner) => {
                const accountId = partner.subscriptionID?.connectedAccountId;
                if (!acc[accountId]) {
                    acc[accountId] = [];
                }
                acc[accountId].push(partner);
                return acc;
            }, {});

            // Iterate through each group and process
            const responses = await Promise.all(
                Object.entries(groupedPartners).map(async ([accountId, partners]) => {
                    // Check Stripe account status for this group
                    const responseStripe = await BookingPaymentServices.checkStatusStripeAccount({
                        connectedAccountId: accountId,
                    });

                    // Get Stripe account status
                    const stripeStatus =
                        responseStripe.status === 200 && responseStripe.data.account.charges_enabled;

                    // Create booking partners for all partners in this group
                    const partnerResponses = await Promise.all(
                        partners.map(async (partner) => {
                            const response = await BookingPartnersServices.createBookingPartner(partner._id, {
                                bookingType: "internal",
                            });

                            if (response.status === 201 || response.status === 200) {
                                return {
                                    partnerId: partner._id,
                                    bookingPartner: response.data.bookingPartner,
                                };
                            } else {
                                throw new Error(`Failed to create booking partner for partner ${partner._id}`);
                            }
                        })
                    );

                    return {
                        connectedAccountId: accountId,
                        partners: partnerResponses,
                        stripeStatus,
                    };
                })
            );

            // Update Redux for all partners in the groups
            responses.forEach(({ connectedAccountId, partners, stripeStatus }) => {
                const partnerIDs = partners.map(({ partnerId }) => partnerId);

                // Update booking partners in Redux
                partners.forEach(({ partnerId, bookingPartner }) => {
                    dispatch(userPartnerUpdateBooking({ partnerID: partnerId, bookingPartner }));
                });

                // Update `subscriptionID.isStripeCompleted` for the relevant partners
                dispatch(
                    updatePartnerConnectedAccount({
                        partnerIDs,
                        connectedAccountId,
                        isStripeCompleted: stripeStatus, // Update Stripe completion status
                    })
                );
            });

            const remainingPartners = user.partnerID.filter(
                (partner) => !partner.subscriptionID.connectedAccountId
            );

            if (remainingPartners.length > 0) {
                setStep(4);
                navigate(`/login/first-login/stripe/remaining-partners/${true}`);
            } else {
                nextStep(user, navigate);
            }
        } catch (error) {
            console.error("Error creating booking partners:", error);
            alert("Une erreur s'est produite lors de la configuration. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main stripeBooking">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(item => item.label)}/>
            </div>
            <div className="main__container__full">
                <div className="icon__image">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__content">
                    <h1 className={"title-25 center"}>Configuration de votre compte de paiement Stripe réussie</h1>
                    <p className={"text-16 center"}>
                        Vous avez été correctement connecté à notre partenaire de paiement Stripe. Vous avez maintenant
                        accès à la gestion de votre système de réservation et recevoir des réservations des utilisateurs
                        de notre application
                    </p>
                </div>
                <div className="main__container__col32 marginTop">
                    <Button
                        text={remainingPartners.length > 0 ?  "Continuer" : "Accéder au tableau de bord"}
                        isPrimary={true}
                        isBig={true}
                        onClick={handleCreateBookingPartners}
                    />
                </div>
            </div>
        </div>
    );
};

export default StripeAccountSuccess;
