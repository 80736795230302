import React from 'react';
import Button from "../../button/button";
import close from "../../../assets/icones/global/close-stroke.svg";
import deleting from "../../../assets/images/svg/rocket_icon.svg";

const modalAccept = ({isOpen, closeModal, addOffer, title, message}) => {

    const handleSend = () => {
        addOffer();
        closeModal();
    }

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal offerAdd ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <div className={"modal__delete__icon"}>
                            <img src={deleting} alt={"deleting"}/>
                        </div>
                        <div className={"modal__header__text"}>
                            <p className={"title-16"}>{ title ? title : "Êtes-vous sûr de poursuivre cette action ?"}</p>
                            <p className={"text-14"}>{message ? message : "Attention, cette action aura un impact sur l'espace partenaire."}</p>
                        </div>
                    </div>
                    <div className={"modal__header__col"}>
                        <div className={"modal__close"} onClick={closeModal}>
                            <img src={close} alt={"close"}/>
                        </div>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content__actions"}>
                        <Button text={"Annuler"} isPrimary={false} onClick={closeModal} />
                        <Button text={"Valider"} isPrimary={true} onClick={handleSend} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default modalAccept