import React, {useState, useEffect, useContext} from 'react';
import mail from '../../../../assets/images/svg/mail_round_yellow.svg';
import Button from "../../../../components/button/button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import arrow from '../../../../assets/icones/arrows/left.svg';
import '../../../../styles/views/verifications.css';
import InputCode from "../../../../components/form/inputText/InputCode";
import VerificationServices from "../../../../api/services/verification";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {ErrorContext} from "../../../../context/errorContext";
import {useLoader} from "../../../../context/loaderContext";
import {useDispatch} from "react-redux"
import {userUpdateToken} from "../../../../redux/slice/authSlice";
import StepBar from "../../../../components/stepBar/stepBar";
import {useStep} from "../../../../context/login/stepContext";


const VerificationUserCode = () => {

    const { email: emailUrl, code: codeUrl } = useParams();
    const user = useSelector((state) => state.auth.user);
    const email = user ? user.email : null;
    const [code, setCode] = useState(Array(4).fill(""));
    const [focusIndex, setFocusIndex] = useState(0);
    const {verifyVerificationCode, sendVerificationCode} = VerificationServices;
    const navigate = useNavigate();

    const {showError} = useContext(ErrorContext);
    const {setLoading} = useLoader();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const dispatch = useDispatch();

    const { step, stepArray, nextStep, setStep } = useStep();



    const handleChange = (e, index) => {
        const inputCode = e.target.value;
        setCode((prevState) => {
            const newState = [...prevState];
            newState[index] = inputCode;
            return newState;
        });
        if (inputCode && index < 3) {
            setFocusIndex(index + 1);
        }
    };

    useEffect(() => {
        if (emailUrl && codeUrl) {
            // Automatically submit the verification if emailUrl and codeUrl are present
            autoSubmitVerification(emailUrl, codeUrl);
        }
    }, [emailUrl, codeUrl]);

    useEffect(() => {
        const inputRefs = Array.from({ length: 4 }, () => React.createRef());
        const handleKeyPress = (event) => {
            if (event.key >= 0 && event.key <= 9) {
                const index = parseInt(event.key);
                if (focusIndex < 4) {
                    setCode((prevState) => {
                        const newState = [...prevState];
                        newState[focusIndex] = index.toString();
                        return newState;
                    });
                    setFocusIndex(focusIndex + 1);
                }
            }
        };

        window.addEventListener("keypress", handleKeyPress);

        return () => {
            window.removeEventListener("keypress", handleKeyPress);
        };
    }, [focusIndex]);

    const handleSubmit = () => {
        setLoading(true);
        const codeString = code.join("");

        const data = {
            email: email,
            code: codeString,
            needToken: !user.hasTemporaryPassword
        }

        verifyVerificationCode(data)
        .then((response) => {
            if (response.status === 200 || response.status === 201){
                if(!user.hasTemporaryPassword){
                    dispatch(userUpdateToken(response.data.token));
                }
                navigate("/login/first-login/verify-email/check");
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const autoSubmitVerification = (email, code) => {
        setLoading(true);
        const data = {
            email: email,
            code: code,
            isSignIn: false
        }
        verifyVerificationCode(data)
            .then(response => {
                if (response.status === 200 || response.status === 201){
                    if(!user.hasTemporaryPassword){
                        dispatch(userUpdateToken(response.data.token));
                    }
                    navigate("/login/first-login/verify-email/check");
                }
            })
            .catch(error => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleResend = () => {
        setLoading(true);

        const data = {
            email: user.email,
            name: user.firstname,
            isSignIn: false
        }
        sendVerificationCode(data)
        .then((response) => {
            if (response.status === 200) {
                setIsEmailSent(true);
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(step => step.label)} />
            </div>
            <div className="main__container__col has-margin-top">
                <div className="main__container__col32">
                    <img src={mail} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Vérifiez votre email</h1>
                    <p className={"text-14 light"}>Nous avons envoyé un lien de vérification à {email}</p>
                </div>

                <div className="main__container__col32 digit_code">
                    {
                        code.map((value, index) => (
                            <InputCode
                                key={index}
                                onChange={handleChange}
                                index={index}
                                code={code[index]}
                                focusNextInput={focusIndex === index}
                            />
                        ))
                    }
                </div>

                <div className="main__container__col32">
                    <Button text={"Vérifier"} isPrimary={true}
                            isBig={true} onClick={handleSubmit}/>
                </div>
                <div className="main__container__col32" onClick={handleResend}>
                    {
                        isEmailSent ?
                            <p className={"text-14 light"}>L'email a été renvoyé à {user.email}</p>
                            :
                            <p className={"text-14 light pointer"}>Vous n'avez pas reçu l'email ? Cliquez pour
                                renvoyer</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default VerificationUserCode;