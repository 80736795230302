import React, {useState, useEffect, useContext, useMemo, useCallback} from 'react';
import HeaderView from "../components/header/headerView";
import RoundStat from "../components/stats/roundStat";
import IconButton from "../components/button/iconButton";
import Table from "../components/table/table";
import Tag from "../components/tag/tag";
import add from '../assets/icones/global/add.svg';
import '../styles/views/partners.css';
import Dot from "../components/dot/dot";
import PartnersServices from "../api/services/partners";
import useStatusName from "../hooks/parseText/useStatusName";
import Button from "../components/button/button";
import useOpenModal from '../hooks/useOpenModal';
import ModalStatus from "../components/modal/modalTable/modalStatus";
import FilterTable from "../components/table/filterTable";
import useFilter from "../hooks/useFilter";
import {useLoader} from "../context/loaderContext";
import {ErrorContext} from "../context/errorContext";
import ActivitiesServices from "../api/services/activities";
import {useParams} from "react-router";
import offer from "../assets/icones/global/sale.svg";
import AnalyticsSection from "../components/analytics/analyticsSection";
import {useSelector} from "react-redux";
import AnalyticsServices from "../api/services/analytics";



const Dashboard = () => {

    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);
    //get partners list from api
    const partnerId = useParams().partnerId;
    const [activities, setActivities] = useState([]);
    const [nbOffers, setNbOffers] = useState(0);
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const [analyticsData, setAnalyticsData] = useState({});
    const [previousAnalyticsData, setPreviousAnalyticsData] = useState({});

    const [selectedPeriod, setSelectedPeriod] = useState('monthly');
    const [customDateRange, setCustomDateRange] = useState(null);

    const currentFilter = useMemo(() => customDateRange || getDateRange(selectedPeriod), [selectedPeriod, customDateRange]);
    const previousFilter = useMemo(() => calculatePreviousDateRange(currentFilter), [currentFilter]);

    const user = useSelector(state => state.auth.user);

    const [isModalOpen, openModal, closeModal] = useOpenModal(activities.map(() => false));

    const { updateFilters, filteredData } = useFilter([], activities, (activity, filters, query) => {
        const matchesFilters = filters.length === 0 || filters.includes(activity.status);
        const matchesQuery = query === '' || activity.name.toLowerCase().includes(query.toLowerCase());
        return matchesFilters && matchesQuery;
    });

    const fetchAnalytics = useCallback(async () => {
        try {
            const currentData = await AnalyticsServices.getAnalytics({ partnerId, ...currentFilter });
            const processedCurrentData = processData(currentData.data);
            setAnalyticsData(processedCurrentData);

            const previousData = await AnalyticsServices.getAnalytics({ partnerId, ...previousFilter });
            const processedPreviousData = processData(previousData.data);
            setPreviousAnalyticsData(processedPreviousData);

        } catch (error) {
            console.error('Failed to fetch analytics data', error);
        }
    }, [partnerId, currentFilter, previousFilter]);

    useEffect(() => {
        setRefresh(false)
        setLoading(true);
        ActivitiesServices.getActivitiesByPartnerId(partnerId)
            .then((response) => {
                if(response.status === 200){
                    setActivities(response.data);
                    setRefresh(false);
                    setFilterList([...new Set(response.data.map((activity) => activity.status))]);
                    setNbOffers(response.data.map((activity) => activity.offers.length).reduce((a, b) => a + b, 0));
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
            setLoading(false);
        })

        fetchAnalytics();
    }, [refresh]);

    const handleStatusChange = (partnerId, status) => {
        const data = {
            status: status
        }

        PartnersServices.updatePartner(partnerId, data)
            .then((response) => {
                if(response.status === 200){
                    setRefresh(true);
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
            setLoading(false);
        });

    }


    return (
        <div className={"main-container"}>
            <HeaderView title={`Bonjour, ${user.firstname} 👋`} isGoBack={false} actions={
                <>
                    {
                        activities && activities.length > 0 ?
                            <IconButton icon={offer} text={"Créer une offre promotionelle"} isPrimary={false}
                                        link={`/partners/${partnerId}/offers/add`}/>
                            : null
                    }

                    <IconButton icon={add} text={"Ajouter une activité"} isPrimary={false}
                                link={`/partners/${partnerId}/activities/add`}/>
                </>
            }/>

            <AnalyticsSection
                analyticsData={analyticsData}
                previousAnalyticsData={previousAnalyticsData}
            />

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h2 className={"title-18"}>Listes de vos activités</h2>
                    <p className={"text-14"}>Vous pouvez voir ici l’état des activités que vous avez renseigné</p>
                </div>
            </div>


            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={(data) => updateFilters(data)}
                            typeValue="activity"
                            searchData={activities}
                        />
                        :
                        null
                }
                {
                    filteredData && filteredData.length > 0 ?
                        <Table
                            columns={[
                                {name: "Référence", align: "left", width: "15%", field: "reference"},
                                {name: "Activité", align: "left", width: "15%", field: "activity"},
                                {name: "Statut", align: "left", width: "20%", field: "status"},
                                {
                                    name: "Actions", align: "center", width: "10%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "actions"
                                },
                                {
                                    name: "Voir plus", align: "center", width: "15%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "link"
                                },
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.map((activity, index) => {
                                        return {
                                            reference: activity.reference,
                                            activity: activity.name,
                                            status: <Tag text={getStatusName(activity.status)}
                                                         status={activity.status}/>,
                                            isVerified: activity.isVerified,
                                            actions: (
                                                <Dot size={"big"} click={() => openModal(index)}>
                                                    <ModalStatus isOpen={isModalOpen[index]}
                                                                 closeModal={() => closeModal(index)}
                                                                 statusActif={activity.status}
                                                                 handleChange={handleStatusChange.bind(this, activity._id)}
                                                                 type={"activity"}/>
                                                </Dot>
                                            ),
                                            link: <Button text={"Voir l'activité"}
                                                          link={`/partners/${partnerId}/activities/${activity._id}`}/>
                                        }
                                    }) : []
                            }
                        />
                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Vous n'avez pas encore d'activité</p>
                            <Button text={"Ajouter une activité"} link={`/partners/${partnerId}/activities/add`}
                                    isPrimary={true}/>
                        </div>

                }
            </div>
        </div>
    );
}


// Helper function to process the raw data from the API response
const processData = (data) => {
    const result = {
        booking: [],
        call: [],
        mapClick: [],
        websiteClick: []
    };

    data.forEach(item => {
        const date = new Date(item._id.date).toISOString(); // Preserve full date and time
        const actionType = item._id.actionType;

        if (result[actionType]) {
            result[actionType].push({
                date,
                count: item.count
            });
        }
    });

    return result;
};

// Helper function to get the start and end dates based on the selected period
const getDateRange = (period) => {
    const today = new Date();
    let startDate, endDate;

    switch (period) {
        case 'yesterday':
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setUTCHours(0, 0, 0, 0);
            startDate = yesterday.toISOString();
            yesterday.setUTCHours(23, 59, 59, 999);
            endDate = yesterday.toISOString();
            break;

        case 'weekly':
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay() + 1);
            startDate = startOfWeek.toISOString().split('T')[0];
            endDate = today.toISOString().split('T')[0];
            break;

        case 'monthly':
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1, 0, 0, 0));
            endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59)); // Last day of the current month
            break;

        case 'quarterly':
            const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
            startDate = new Date(today.getFullYear(), (currentQuarter - 1) * 3, 1).toISOString().split('T')[0];
            endDate = today.toISOString().split('T')[0];
            break;

        case 'yearly':
            startDate = new Date(today.getFullYear(), 0, 1).toISOString().split('T')[0];
            endDate = today.toISOString().split('T')[0];
            break;

        default:
            startDate = endDate = today.toISOString().split('T')[0];
            break;
    }

    return { startDate, endDate };
};

// Helper function to calculate the previous date range based on the current range
const calculatePreviousDateRange = (currentRange) => {
    const currentStart = new Date(currentRange.startDate);
    const currentEnd = new Date(currentRange.endDate);

    if (isNaN(currentStart.getTime()) || isNaN(currentEnd.getTime())) {
        return { startDate: null, endDate: null };
    }

    const daysDiff = Math.ceil((currentEnd - currentStart) / (1000 * 60 * 60 * 24));

    const previousStart = new Date(currentStart);
    previousStart.setDate(currentStart.getDate() - daysDiff);

    const previousEnd = new Date(currentEnd);
    previousEnd.setDate(currentEnd.getDate() - daysDiff);

    return {
        startDate: previousStart.toISOString().split('T')[0],
        endDate: previousEnd.toISOString().split('T')[0]
    };
};

export default Dashboard;