import React, {useState} from 'react';
import check from '../../../../assets/images/svg/check_round_green.svg';
import Button from "../../../../components/button/button";
import '../../../../styles/views/verifications.css';
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import StepBar from "../../../../components/stepBar/stepBar";
import {useStep} from "../../../../context/login/stepContext";
import {useNavigate} from "react-router-dom";

const PasswordCheck = () => {

    const user = useSelector((state) => state.auth.user);

    const { step, stepArray, nextStep, setStep } = useStep();
    const navigate  = useNavigate();

    const handleNextStep = () => {
        nextStep(user, navigate);
    }

    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(step => step.label)} />
            </div>
            <div className="main__container__col password-half has-margin-top">
                <div className="main__container__col32">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Mot de passe modifié</h1>
                    <p className={"text-14 light"}>Votre mot de passe a été modifié avec succès. Cliquez ci-dessous pour
                        continuer.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Continuer"} isPrimary={true} isBig={true}
                            onClick={handleNextStep}/>
                </div>

            </div>
        </div>
    );
}

export default PasswordCheck;