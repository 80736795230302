const userParseUser = () => {

    const parseUser = (user) => {

        const firstname = {
            name: "firstname",
            value: user && user.firstname ? user.firstname : "",
            type: "text",
            error: false,
            errorMessage: false,
            placeholder: "Prénom",
            validationType: "text",
            isRequired: true
        }

        const lastname = {
            name: "lastname",
            value: user && user.lastname ? user.lastname : "",
            type: "text",
            error: false,
            errorMessage: false,
            placeholder: "Nom",
            validationType: "text",
            isRequired: true
        }

        const email = {
            name: "email",
            value: user && user.email ? user.email : "",
            type: "email",
            error: false,
            errorMessage: false,
            placeholder: "Email",
            validationType: "email",
            isRequired: true
        }

        const phoneNumber = {
            name: "phoneNumber",
            value: user && user.phoneNumber ? user.phoneNumber : "",
            type: "phone",
            error: false,
            errorMessage: false,
            placeholder: "Téléphone",
            validationType: "phone",
            isRequired: false
        }

        const password = {
            name: "password",
            value: "",
            type: "password",
            error: false,
            errorMessage: false,
            placeholder: "Mot de passe",
            validationType: "password",
            isRequired: true
        }

        const passwordConfirm = {
            name: "passwordConfirm",
            value: "",
            type: "password",
            error: false,
            errorMessage: false,
            placeholder: "Confirmation mot de passe",
            validationType: "password",
            isRequired: true
        }

        const partnerID = {
            name: "partner",
            value: user && user.partnerID ? user.partnerID : [],
            error: false,
            errorMessage: false,
            placeholder: "Partenaire",
            validationType: "text",
            isRequired: false
        }


        const parsedUser = {
            firstname,
            lastname,
            email,
            phoneNumber,
            password,
            passwordConfirm,
            partnerID
        }

        return parsedUser;
    }

    const parseUserUpdate = (user) => {

        const firstname = {
            name: "firstname",
            value: user && user.firstname ? user.firstname : "",
            type: "text",
            error: false,
            errorMessage: false,
            placeholder: "Prénom",
            validationType: "text",
            isRequired: true
        }

        const lastname = {
            name: "lastname",
            value: user && user.lastname ? user.lastname : "",
            type: "text",
            error: false,
            errorMessage: false,
            placeholder: "Nom",
            validationType: "text",
            isRequired: true
        }

        const email = {
            name: "email",
            value: user && user.email ? user.email : "",
            type: "email",
            error: false,
            errorMessage: false,
            placeholder: "Email",
            validationType: "email",
            isRequired: true
        }

        const phoneNumber = {
            name: "phoneNumber",
            value: user && user.phoneNumber ? user.phoneNumber : "",
            type: "phone",
            error: false,
            errorMessage: false,
            placeholder: "Téléphone",
            validationType: "phone",
            isRequired: false
        }

        const partnerID = {
            name: "partner",
            value: user && user.partnerID ? user.partnerID : [],
            error: false,
            errorMessage: false,
            placeholder: "Partenaire",
            validationType: "text",
            isRequired: false
        }


        const parsedUser = {
            firstname,
            lastname,
            email,
            phoneNumber,
            partnerID
        }

        return parsedUser;
    }

    const parseUserForApi = (user) => {

        const parsedUser = {
            firstname: user.firstname ? user.firstname.value : "",
            lastname: user.lastname ? user.lastname.value : "",
            email: user.email ? user.email.value : "",
            phoneNumber: user.phoneNumber ? user.phoneNumber.value : "",
            role: user.partnerID.length > 1 ? "partners-admin" : "partner",
            password: user.password ? user.password.value : "",
            hasTemporaryPassword: user.hasTemporaryPassword ? user.hasTemporaryPassword : false,
            partnerID: user.partnerID ? user.partnerID.value : []
        }
        return parsedUser;
    }


    const parseUserForApiUpdate = (user) => {

        const parsedUser = {
            firstname: user.firstname ? user.firstname.value : "",
            lastname: user.lastname ? user.lastname.value : "",
            email: user.email ? user.email.value : "",
            phoneNumber: user.phoneNumber ? user.phoneNumber.value : "",
            partnerID: user.partnerID ? user.partnerID.value : []
        }

        //filter empty values
        Object.keys(parsedUser).forEach(key => parsedUser[key] === "" && delete parsedUser[key]);

        return parsedUser;
    }


    return {
        parseUser,
        parseUserForApi,
        parseUserUpdate,
        parseUserForApiUpdate
    }

}

export default userParseUser;