import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import BookingPaymentServices from "../../api/services/bookingPayment";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {updatePartnerConnectedAccount} from "../../redux/slice/authSlice";

// Load your Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const useStripeAccountToken = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const createAccountToken = async (partner, separateToken = false) => {
        setIsLoading(true);
        setError(null);

        try {
            const stripe = await stripePromise;

            const tokenData = {
                business_type: "company",
                company: {
                    name: partner.name,
                    address: {
                        line1: partner.address,
                        postal_code: partner.postalCode.toString(),
                    },
                },
                tos_shown_and_accepted: true,
            };

            // Generate the token
            const result = await stripe.createToken("account", tokenData);

            if (result.error) {
                setError(result.error.message);
                console.error(result.error.message);
                setIsLoading(false);
                return null; // Return null to indicate failure
            }

            setToken(result.token.id);

            if (!separateToken) {
                // If not returning a token, call createAccount directly
                await createAccount(result.token.id, partner.engineID.bookingEmail, partner._id);
            }

            // Return the token if separateToken is true
            return separateToken ? result.token.id : null;
        } catch (err) {
            console.error("An unexpected error occurred:", err);
            setError("An unexpected error occurred");
            setIsLoading(false);
            return null; // Return null to indicate failure
        }
    };

    const createAccount = async (accountToken, email, partnerIDs) => {
        try {
            const response = await BookingPaymentServices.createConnectAccount({
                email,
                accountToken,
                partnerIDs,
                refreshUrl: partnerIDs.length > 1
                    ? `${process.env.REACT_APP_URL}/login/first-login/stripe/multi-partner`
                    : `${process.env.REACT_APP_URL}/login/first-login/stripe/config`,
                returnUrl: `${process.env.REACT_APP_URL}/login/first-login/stripe/success`,
            });

            if (response.status === 200) {
                const connectedAccountId = response.data.connectedAccountId;

                // Update Redux for all selected partners
                dispatch(updatePartnerConnectedAccount({ partnerIDs, connectedAccountId }));

                // Redirect to Stripe onboarding
                window.location.href = response.data.url;
            } else {
                throw new Error("Failed to create account onboarding link.");
            }
        } catch (error) {
            console.error("Error creating Stripe account:", error);
            setError("Error creating Stripe account");
        } finally {
            setIsLoading(false);
        }
    };

    return {
        createAccountToken,
        createAccount,
        isLoading,
        error,
        token
    };
};
