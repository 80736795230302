//api config
const  apiConfig = {
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 100000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
}

export {apiConfig}