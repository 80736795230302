import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const UsersServices = {

        getUsers: async () => {
            return await getRequest('/users');
        },

        getUserById: async (userId) => {
            return await getRequest(`/users/${userId}`);
        },

        getUsersByPartner : async (partnerID) => {
            return await getRequest(`/users/partner/${partnerID}`);
        },

        associateUsersWithPartner: async (partnerID, data) => {
            return await postRequestJson(`/users/associate/${partnerID}`, data);
        },

        createUser: async (data) => {
            return await postRequestJson('/register', data);
        },

        updateUser: async (userId, data) => {
            return await patchRequest(`/users/${userId}`, data);
        },

        deleteUser: async (userId) => {
            return await deleteRequest(`/users/${userId}`);
        }
}

export default UsersServices;