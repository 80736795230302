import React, {useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import Form from "../../components/form/form";
import Company from "./formPartner/company";
import Personal from "./formPartner/personal";
import LegalDocument from "./formPartner/legalDocument";
import '../../styles/views/singlePartner.css';
import useParsePartner from "../../hooks/parseDataApi/useParsePartner";
import PartnersServices from "../../api/services/partners";
import PartnersDocuments from "../../api/services/partnersDocuments";
import {useNavigate} from "react-router-dom";
import useFormValidation from "../../hooks/form/useFormValidation";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import Booking from "./formPartner/booking";
import SocialNetworks from "./formPartner/socialNetworks";
import {usePartner} from "../../context/partnerContext";
import {useSelector} from "react-redux";
import PartnersSocialNetworks from "../../api/services/partnersSocialNetworks";


const AddPartner = () => {

    const navigate = useNavigate();
    const { parsePartnerForApi, parsePartner, callAllPartners } = useParsePartner();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { setPartnerId } = usePartner();

    const { formState, setFormState, handleFormChange } = useFormUpdate(parsePartner([]));

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isFormValid = validateForm(formState, setFormState);
        if (!isFormValid.isFormValid) return;

        setLoading(true);

        try {
            const partnerForApi = parsePartnerForApi(formState);
            const parsePartnerFiles = new FormData();

            const appendFilesToFormData = async (files, key) => {
                for (const file of files) {
                    const blob = await fetch(file.src || file.preview).then((res) => {
                        if (!res.ok) throw new Error('Failed to fetch file');
                        return res.blob();
                    });
                    parsePartnerFiles.append(key, new File([blob], file.name, { type: file.type || 'image/webp' }));
                }
            };

            const localDocuments = partnerForApi.legalInfoID?.legalDocuments?.filter(doc => doc.isLocal) || [];
            const socialNetworks = partnerForApi.partnerSocialNetworksID?.logo?.filter(doc => doc.isLocal) || [];

            if (localDocuments.length) await appendFilesToFormData(localDocuments, 'documents');
            if (socialNetworks.length) await appendFilesToFormData(socialNetworks, 'logo');

            delete partnerForApi.legalInfoID.legalDocuments;
            delete partnerForApi.partnerSocialNetworksID.logo;

            const response = await PartnersServices.createPartner(partnerForApi);
            if (response.status === 200) {
                const partnerId = response.data._id;
                setPartnerId(partnerId);

                if (parsePartnerFiles.has('documents') || parsePartnerFiles.has('logo')) {
                    const uploadResponse = parsePartnerFiles.has('documents')
                        ? await PartnersDocuments.create(partnerId, parsePartnerFiles)
                        : await PartnersSocialNetworks.create(partnerId, parsePartnerFiles);

                    if (uploadResponse.status !== 200) throw new Error('File upload failed');
                }

                navigate(`/partners/${partnerId}`);
                window.scroll(0, 0);
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Ajouter un partenaire"} actions={
                <></>
            }/>


            <div className={"header__container"}>
                <h2 className={"title-16"}>Informations sur le partenaire</h2>
                <p className={"text-14"}>Vous pouvez ajouter ici les informations du partenaire</p>
            </div>

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h1 className={"title-20"}>{"Nouveau partenaire"}</h1>
                </div>
            </div>

            <Form
                title={"Informations sur le partenaire"}
                children={
                    <>
                        <Company setPartner={handleFormChange} company={formState.company} />
                        <SocialNetworks setPartner={handleFormChange} socialNetworks={formState.socialNetworks} />
                        <Booking setPartner={handleFormChange} booking={formState.booking} />
                        <Personal mode={"add"} setPartner={handleFormChange} personal={formState.personal} />
                        <LegalDocument setPartner={handleFormChange} documents={formState.legalDocuments} type={"local"} />
                    </>
                }
                mode={"add"}
                onSubmit={handleSubmit}
            />

        </div>
    )

}

export default AddPartner;