import { useState } from 'react';
import Authentification from '../api/services/authentification'; // Import your authentication service
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {userLogin, userLogout} from '../redux/slice/authSlice';
import { useContext } from 'react';
import { ErrorContext } from '../context/errorContext';

import {usePartner} from "../context/partnerContext";
import { useStep } from '../context/login/stepContext';



const useAuth = () => {
    const { clearPartnerId, setPartnerId, currentPartnerId } = usePartner();
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showError } = useContext(ErrorContext);
    const { setStepsConfig } = useStep(); // Access the global stepsConfig setter

    const login = async (credentials) => {
        //erase context partnerId
        clearPartnerId();
        try {
            const response = await Authentification.login(credentials);

            if(response.status === 200){
                setUser(response.data);
                dispatch(userLogin(response.data));

                const config = {
                    verifyEmail: !response.data.user.isEmailVerified || response.data.user.hasTemporaryPassword,
                    changePassword: response.data.user.hasTemporaryPassword,
                    yourCommission: response.data.user.partnerID &&
                        response.data.user.partnerID.every(partner => !partner.subscriptionID || partner.subscriptionID === null),
                    createStripeProfile: response.data.user.partnerID &&
                        response.data.user.partnerID.every(partner =>
                            !partner.subscriptionID ||
                            !partner.subscriptionID.connectedAccountId ||
                            partner.subscriptionID.connectedAccountId === null
                        ),
                };


                //check is at least config has a true value
                if(response.data.user.role === "super-admin" || response.data.user.role === "admin"){
                    navigate('/');
                } else if (Object.values(config).some((value) => value === true)){
                    setStepsConfig(config);
                    navigate('/login/first-login');
                }else if (response.data.user.role === "partner" && response.data.user.partnerID !== null){
                    setPartnerId(response.data.user.partnerID[0]._id);
                    //get media query
                    const mediaQuery = window.matchMedia('(max-width: 991px)').matches;

                    if (mediaQuery){
                        navigate(`/partners/${response.data.user.partnerID[0]._id}/bookings`);
                    } else{
                        navigate(`/dashboard/${response.data.user.partnerID[0]._id}`);
                    }
                }else if(response.data.user.role === "partners-admin" && response.data.user.partnerID !== null){
                    navigate('/dashboard-partners');
                }else {
                    navigate('/');
                }
            }else {
                setError(response.data);
                showError(response.data);
            }
        } catch (error) {
            console.log(error);
            showError(error);
        }
    };

    const logout = async () => {
        try {
            dispatch(userLogout());
            clearPartnerId();
            navigate('/login');
            //erase context partnerId
        } catch (error) {
            setError(error);
        }
    };

    return { user, error, login, logout };
};

export default useAuth;
