import React, {useEffect, useState} from 'react';
import mail from '../../../../assets/images/svg/mail_round_yellow.svg';
import Button from "../../../../components/button/button";
import { Link } from "react-router-dom";
import arrow from '../../../../assets/icones/arrows/left.svg';
import '../../../../styles/views/verifications.css';
import {useParams} from "react-router";
import VerificationServices from "../../../../api/services/verification";
import {useSelector} from "react-redux";
import {useContext} from "react";
import {ErrorContext} from "../../../../context/errorContext";
import {useLoader} from "../../../../context/loaderContext";
import StepBar from "../../../../components/stepBar/stepBar";
import {useStep} from "../../../../context/login/stepContext";


const VerificationUserEmail = () => {

    const user = useSelector((state) => state.auth.user);
    const email = user ? user.email : null;
    const {sendVerificationCode} = VerificationServices;
    const { email: emailUrl } = useParams();
    const { step, stepArray, nextStep, setStep } = useStep();

    const {setLoading} = useLoader();
    const {showError} = useContext(ErrorContext);


    useEffect(() => {
        if (!user || !email) {
            console.warn("No user or email provided.");
            return;
        }

        const firstname = user.firstname;
        const emailSent = localStorage.getItem('emailSent');
        const emailSentBoolean = JSON.parse(emailSent);

        if (!emailSentBoolean) {
            setLoading(true); // Add loading state for better UX
            const data = { email, name: firstname, isSignIn: false };

            localStorage.setItem('emailSent', true); // Optimistically set the emailSent flag
            sendVerificationCode(data)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("Email sent successfully");
                    }
                })
                .catch((error) => {
                    showError(error);
                    console.error("Error sending email:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [user, email, sendVerificationCode, showError, setLoading]);


    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(step => step.label)} />
            </div>
            <div className="main__container__col has-margin-top">
                <div className="main__container__col32">
                    <img src={mail} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Vérifiez votre boîte mail</h1>
                    <p className={"text-14 light"}>Nous avons envoyé un lien de vérification à {email}. Veuillez
                        vérifier votre boîte mail et cliquer sur le lien pour continuer.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Entrez le code manuellement"} link={"code"} isPrimary={true}
                            isBig={true}/>
                </div>
            </div>
        </div>
    );
}

export default VerificationUserEmail;