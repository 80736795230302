import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import HeaderView from "../../../components/header/headerView";
import "../../../styles/views/payments.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePartner } from "../../../context/partnerContext";
import { useLoader } from "../../../context/loaderContext";
import BookingStripeServices from "../../../api/services/bookingStripe";
import { ErrorContext } from "../../../context/errorContext";
import Table from "../../../components/table/table";
import Tag from "../../../components/tag/tag";
import useStatusName from "../../../hooks/parseText/useStatusName";
import Avatar from "../../../components/avatar/avatar";
import check from "../../../assets/icones/global/check_circle.svg";
import time from "../../../assets/icones/global/time_circle_yellow.svg";
import bankIcon from "../../../assets/icones/global/home_yellow.svg";
import Input from "../../../components/form/inputText/input";
import InputSelect from "../../../components/form/inputSelect/inputSelect";
import IconButton from "../../../components/button/iconButton";
import alertIcon from "../../../assets/icones/global/alert-circle.svg";
import BookingPaymentServices from "../../../api/services/bookingPayment";
import downloadIcon from '../../../assets/icones/global/download.svg'; // Add an appropriate download icon

const BookingPayment = () => {
    const { partnerId } = useParams();
    const { setLoading } = useLoader();
    const { showError } = useContext(ErrorContext);
    const [refresh, setRefresh] = useState(true);

    const navigate = useNavigate();
    const disptach = useDispatch();
    const { getStatusName } = useStatusName();

    const [stripeTransfers, setStripeTransfers] = useState(null);
    const [filteredPayments, setFilteredPayments] = useState(null);
    const [stripeBankAccount, setStripeBankAccount] = useState(null);
    const [stripeAmount, setStripeAmount] = useState(null);
    const [stripePayouts, setStripePayouts] = useState(null);
    const [stripeAccount, setStripeAccount] = useState(null);
    const [totalVolume, setTotalVolume] = useState(0);

    const [filterPayments, setFilterPayments] = useState(null);

    const user = useSelector(state => state.auth.user);
    const { currentPartnerId, allPartners } = usePartner();
    const currentPartner = (() => {
        if (user.role === "partner") {
            // Safely find the partner in user's `partnerID`
            return user.partnerID?.find((partner) => partner._id === currentPartnerId) || null;
        }

        // For other roles, find the partner in `allPartners`
        return allPartners?.find((partner) => partner._id === currentPartnerId) || null;
    })();

    useEffect(() => {
        const fetchStripePayments = async () => {
            setLoading(true);
            try {
                const connectedAccountId = currentPartner.subscriptionID.connectedAccountId;

                if (connectedAccountId) {
                    const accountResponse = await BookingStripeServices.getStripeAccount({ connectedAccountId });
                    setStripeAccount(accountResponse.data.account);

                    const transfersResponse = await BookingStripeServices.getTransfers({ connectedAccountId });
                    setStripeTransfers(transfersResponse.data.transfers.data);

                    const payoutsResponse = await BookingStripeServices.getPayouts({ connectedAccountId });
                    setStripePayouts(payoutsResponse.data.payouts);

                    const bankAccountResponse = await BookingStripeServices.getBankAccounts({ connectedAccountId });
                    setStripeBankAccount(bankAccountResponse.data.bankAccounts);

                    const amountsResponse = await BookingStripeServices.getAmounts({ connectedAccountId });
                    setStripeAmount(amountsResponse.data.amounts);

                    // Calculate the total volume directly in the frontend
                    const total = transfersResponse.data.transfers.data.reduce((acc, transfer) => acc + transfer.amount, 0);
                    setTotalVolume(total);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        if (refresh) {
            fetchStripePayments();
            setRefresh(false);
        }

    }, []); // Add currentPartner as a dependency if it could change

    const handleUpdateStripe = () => {
        setLoading(true);
        BookingPaymentServices.updateConnectAccount({ partnerID: partnerId, connectedAccountId: currentPartner.subscriptionID.connectedAccountId })
            .then((response) => {
                window.location.href = response.data.url;
            }).catch((error) => {
            showError(error);
            setLoading(false);
        });
    };

    const handleDownloadBilling = async (identifier, amountTransfered, type) => {
        try {
            // Replace # from the identifier
            const cleanedIdentifier = identifier.replace("#", "");

            // Fetch the PDF from the backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/booking-stripe/download-billing-report/${partnerId}/${cleanedIdentifier}`, {
                method: 'POST', // Change method to POST
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    // Pass any additional data needed by the backend
                    type: type,
                    amountOfTransfer: amountTransfered,
                }),
            });

            // Check if the response is okay
            if (!response.ok) {
                throw new Error('Failed to download the billing report.');
            }

            // Convert the response to a blob
            const blob = await response.blob();

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([blob]));

            // Create a link element, trigger the download, and remove the link
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `facture_${cleanedIdentifier}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Revoke the URL to free up memory
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading billing report:', error);
        }
    };


    return (
        <div className={"main-container is__grey"}>
            <HeaderView title={`Mes transactions`} isGoBack={false} actions={
                <>
                    {stripeAccount && !stripeAccount.payouts_enabled && <IconButton icon={alertIcon} onClick={handleUpdateStripe} text={"Compléter mes informations Stripe"} className={"red_light"} />}
                </>
            } />
            <div className={"main-container__split"}>
                <div className={"container__right"}>
                    <div className={"main-container__soldes"}>
                        <div className={"main-container__row"}>
                            <p className={"title-18"}>Mon solde</p>
                        </div>
                        <div className={"main-container__row"}>
                            <div className={"main-container__column card__solde__container"}>
                                <p className={"text-14"}>Montant des réservations</p>
                                <div className={"main-container__row"}>
                                    <div className={"icon__succeed"}>
                                        <img src={check} alt={"pending"} width={30} height={30} />
                                    </div>
                                    <p className={"solde_title"}>{new Intl.NumberFormat('fr-FR', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format(totalVolume / 100)}</p>
                                </div>
                            </div>
                            <div className={"main-container__column card__solde__container"}>
                                <p className={"text-14"}>Solde en attente de transfert</p>
                                <div className={"main-container__row"}>
                                    <div className={"icon__waiting"}>
                                        <img src={time} alt={"pending"} width={25} height={25}/>
                                    </div>
                                    <p className={"solde_title"}>
                                        {stripeAmount && stripeAmount.pending
                                            ? new Intl.NumberFormat('fr-FR', {
                                                style: 'currency',
                                                currency: stripeAmount.pending[0].currency
                                            }).format(Math.abs(stripeAmount.pending[0].amount / 100))
                                            : "0€"}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"main-container__row list"}>
                        <div className={"main-container__row__header"}>
                            <p className={"title-18"}>Mes transactions</p>
                        </div>
                        {
                            stripeTransfers && stripeTransfers.length > 0 ?
                                <Table
                                    columns={[
                                        { name: "Montant", align: "left", width: "15%", field: "amount" },
                                        { name: "Réservation", align: "left", width: "25%", field: "bookingId" },
                                        { name: "Date", align: "left", width: "20%", field: "date" },
                                        { name: "Statut", align: "left", width: "15%", field: "status" },
                                        { name: "Facture", align: "left", width: "15%", field: "billing" },
                                    ]}
                                    data={
                                        stripeTransfers && stripeTransfers.length > 0 ?
                                            stripeTransfers.map((payment, index) => {
                                                const identifier = payment.metadata.bookingId ? payment.metadata.bookingId : "non-renseigné";
                                                return {
                                                    amount: `${payment.isRefunded ? "-" : ""}${new Intl.NumberFormat('fr-FR', {
                                                        style: 'currency',
                                                        currency: payment.currency
                                                    }).format(payment.amount / 100)}`,
                                                    bookingId: identifier,
                                                    date: new Intl.DateTimeFormat('fr-FR', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    }).format(new Date(payment.created * 1000)), // Assuming payment.created is a Unix timestamp
                                                    status: <Tag text={getStatusName(payment.reversed ? "reversed" :  payment.metadata.type ? "compensation" : "succeeded")} status={payment.reversed ? "reversed" :  payment.metadata.type ? "compensation" : "succeeded"} />,
                                                    billing: identifier === "non-renseigné" ? "Facture non disponible" : <IconButton icon={downloadIcon} onClick={() =>
                                                        handleDownloadBilling(identifier, payment.amount, payment.metadata.type ? "compensation" : "transfer")} text={"Télécharger"} className={"blue_light"} />
                                                }

                                            }) : []
                                         }
                                    />
                                    :
                                <div className={"container__empty"}>
                                    {
                                        stripeAccount && !stripeAccount.payouts_enabled ?
                                            <>
                                                <p className={"text-14"}>Vous n'avez pas rempli toutes les informations de votre compte connecté chez notre partenaire Stripe</p>
                                                <IconButton icon={alertIcon} onClick={handleUpdateStripe} text={"Compléter mes informations Stripe"} className={"red_light"} />
                                            </>
                                            :
                                            <p className={"text-14"}>Vous n'avez pas encore de paiements</p>
                                    }
                                </div>
                        }
                    </div>
                </div>
                <div className={"container__left"}>
                    <div className={"payment__bank__container"}>
                        <div className={"payment__bank__container__header"}>
                            <p className={"title-18"}>Mes informations bancaires</p>
                        </div>
                        <div className={"payment__bank__container"}>
                            <div className={"bank_card"}>
                                <div className={"bank__card__header"}>
                                    <div className={"bank__card__icon"}>
                                        <img src={bankIcon} alt={"bank"} width={30} height={30} />
                                    </div>
                                    {stripeBankAccount ? <p className={"bank__name"}>{stripeBankAccount.bank_name}</p> : <p className={"bank__name"}>Compte bancaire</p>}
                                </div>
                                {
                                    stripeBankAccount ?
                                        <div className={"bank__card__column"}>
                                            <p className={"bank__text"}>Compte bancaire</p>
                                            <p className={"bank__iban"}> •••• •••• •••• {stripeBankAccount.last4}</p>
                                        </div>
                                        :
                                        <div className={"bank__card__column"}>
                                            <p className={"bank__text"}>Aucun compte bancaire enregistré</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BookingPayment;
