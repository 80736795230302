import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Radio from "../../../components/form/inputRadio/radio";
import InputSelect from "../../../components/form/inputSelect/inputSelect";
import {Link} from "react-router-dom";

const CancelPolicy = ({cancel, setActivity, isDisabled}) => {

    const cancelPolicyList = [
        {
            id: 1,
            value: "24h",
            name: "24h avant l'activité"
        },
        {
            id: 2,
            value: "48h",
            name: "48h avant l'activité"
        },
        {
            id: 3,
            value: "72h",
            name: "72h avant l'activité"
        }
    ]

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        setActivity(`cancel.${name}.value`, value);
    }

    return(
        <FormSectionRow title={"Annulation"} classContainer={"cancel"} isRequired={true}>
            <div className={"form__container__col grid"}>
                <InputSelect
                    id={1}
                    name={"cancelPolicy"}
                    placeholder={"Annulation sous"}
                    options={cancelPolicyList}
                    onChange={handleChange}
                    value={cancel.cancelPolicy.value}
                    valueSelected={cancel.cancelPolicy.value}
                    errorEmpty={cancel.cancelPolicy.errorEmpty}
                    errorMessage={cancel.cancelPolicy.errorMessage}
                    subtitle={"Conditions d’annulation"}
                    isDisabled={isDisabled}
                />
            </div>
            <div className={"form__container__col grid"}>
                <Radio
                    id={4}
                    value={"no-fundable"}
                    label={"Non remboursable"}
                    name={"refundPolicy"}
                    checked={cancel.refundPolicy.value === "no-fundable"}
                    onChange={handleChange}
                    errorEmpty={cancel.refundPolicy.error}
                    errorMessage={cancel.refundPolicy.errorMessage}
                    disabled={isDisabled}
                />
                <Radio
                    id={5}
                    value={"partial-refundable"}
                    label={"Remboursement partiel"}
                    name={"refundPolicy"}
                    checked={cancel.refundPolicy.value === "partial-refundable"}
                    onChange={handleChange}
                    errorEmpty={cancel.refundPolicy.error}
                    errorMessage={cancel.refundPolicy.errorMessage}
                    disabled={isDisabled}
                />
            </div>

            <p className={"text-14"}>Pour plus d’informations rendez-vous sur notre
                <Link to={"/faq"}> FAQ</Link>
            </p>
        </FormSectionRow>
    )
}

export default CancelPolicy;