
import { postRequestFormData, deleteRequest } from '../request/apiRequest';

const PartnersSocialNetworks = {

    compressImages: async (partnerId, data, config) => {
        return await postRequestFormData(`/partners/social-networks/compress/${partnerId}`, data, config);
    },

    create: async (partnerId, data) => {
        return await postRequestFormData(`/partners/social-networks/logo/${partnerId}`, data);
    },

    delete: async (partnerId, documentFilename) => {
        return await deleteRequest(`/partners/social-networks/${partnerId}/${documentFilename}`);
    }
}

export default PartnersSocialNetworks;