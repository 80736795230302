import React from "react";
import '../../styles/components/stepbar.css';

const StepBar = ({ step, textArray }) => {
    const steps = textArray || ['Inscription', 'Informations personnelles', 'Information de votre entreprise'];

    return (
        <div className="step-bar">
            <div
                className="step-bar__container"
                style={{
                    gap: `${100 / steps.length}%` // Adjust gap based on step count
                }}
            >
                {steps.map((stepText, index) => {
                    const isActive = step >= index + 1;
                    return (
                        <div
                            key={index}
                            className={`step-bar__container__step step_${index} ${isActive ? 'active' : ''}`}
                        >
                            <div
                                className={`step-bar__container__step__circle ${step > index ? 'active' : ''}`}
                            >
                                <div className="step-bar__container__step__circle__step"></div>
                            </div>
                            <p className="text-12">{stepText}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default StepBar;
