import { useState, useEffect } from 'react';

const useFilter = (initialFilters, data, filterFunction) => {
    const [filters, setFilters] = useState(initialFilters);
    const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        if (data) {
            setFilteredData(
                data.filter((item) =>
                    filterFunction(item, filters, query)
                )
            );
        }
    }, [data, filters, query]);

    const updateFilters = (newFilters) => {
        setFilters(newFilters.filters || []);
        setQuery(newFilters.query || '');
    };

    return { filters, updateFilters, filteredData };
};

export default useFilter;


