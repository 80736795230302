import React, { useState, useEffect } from "react";
import RadioMultiple from "../../../components/form/inputRadio/radioMultiple";
import FormSectionRow from "../../../components/form/formSectionRow";
import ActivitiesTypes from "../../../api/services/activitiesTypes";
import Button from "../../../components/button/button";
import Radio from "../../../components/form/inputRadio/radio";
import ActivitiesTypesServices from "../../../api/services/activitiesTypes";
import Input from "../../../components/form/inputText/input";
import { useSelector } from "react-redux";

const Types = ({ type, setActivity, isDisabled }) => {
    const [typeList, setTypeList] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [newItemName, setNewItemName] = useState("");
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        ActivitiesTypes.getActivitiesTypes()
            .then((response) => {
                if (response.status === 200) {
                    //set item "Autre" at the end of the list
                    const otherItem = response.data.find((item) => item.name === "🤷️ Autre");
                    const otherItemIndex = response.data.indexOf(otherItem);
                    response.data.splice(otherItemIndex, 1);
                    response.data.push(otherItem);

                    setTypeList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [type]);

    const handleTypeChange = (event) => {
        const value = event.target.value;
        setActivity('type.value', value);
    };

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const handleAddTypeActivity = async () => {
        try {
            const response = await ActivitiesTypesServices.createActivitiesType({ name: newItemName });
            if (response.status === 200) {let updatedList = [...typeList];

                // Add the new item before the item "Autre"
                updatedList.splice(updatedList.length - 1, 0, response.data);
                setTypeList(updatedList);

                // Select the new item
                setActivity("type.value", response.data.name);

                // Clear the input AFTER successful response
                setNewItemName("");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <FormSectionRow title={"Quel est votre type d’activité"}
                        classname={"category"} isRequired={true}>
            {
                user?.role === "admin" || user?.role === "super-admin" &&
                <div className={"form__container__row"}>
                    {/*add input to dd new one with handleAddTypeActivity()*/}
                    <Input
                        key={"open"}
                        name={"included"}
                        type={"text"}
                        valueInput={newItemName || ""}
                        onChangeForm={(e) => setNewItemName(e.target.value)}
                        placeholder={"Ex : 🛹 Skateboard"}
                    />
                    <Button
                        text={"Ajouter"}
                        isPrimary={true}
                        onClick={() => {
                            handleAddTypeActivity();
                            setNewItemName("");
                        }}
                        disabled={!newItemName.trim()} // Button disabled for empty input
                    />
                </div>
            }
            <div className={"form__container__col grid"}>
                {
                    typeList.slice(0, showAll ? typeList.length : 12).map((item) => (
                        <Radio
                            key={item.id}
                            name="type"
                            value={item.name}
                            onChange={handleTypeChange}
                            label={item.name}
                            checked={type.value === item.name}
                            errorEmpty={type.error}
                            disabled={isDisabled}
                            className={"type__item"}
                        />
                    ))
                }
            </div>
            {typeList.length > 12 && (
                <Button
                    text={showAll ? "Voir moins" : "Voir plus ..."}
                    onClick={handleShowMore}
                    className={"button__link"}
                />
            )}
        </FormSectionRow>
    );
};

export default Types;
