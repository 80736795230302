

const useParsePartner = () => {

    const parsePartner = (partner) => {

        const status = partner.status ? partner.status : "no-status";

        const company =
        {
            comment: {
                value: partner.comment ? partner.comment : "",
                error: false,
                errorMessage: false,
                label: "Note sur le dossier",
                placeholder: "Une note à ajouter ?",
                validationType: "text",
                isRequired: false,
            },
            name: {
                value: partner.name ? partner.name : "",
                error: false,
                errorMessage: false,
                label: "Nom de l'établissement",
                placeholder: "Speed Park",
                validationType: "text",
                isRequired: true,
                isLoadEmpty: !partner.name || partner.name === "" ? true : false,
            },
            address: {
                value: partner.address ? partner.address : "",
                error: false,
                errorMessage: false,
                label: "Adresse de l'établissement",
                placeholder: "1 rue de la paix",
                validationType: "text",
                isRequired: true,
                isLoadEmpty: !partner.address || partner.address === "" ? true : false,
            },
            postalCode: {
                value: partner.postalCode ? partner.postalCode : "",
                error: false,
                errorMessage: false,
                label: "Code postal",
                placeholder: "75000",
                validationType: "postalCode",
                isRequired: true,
                isLoadEmpty: partner.postalCode && partner.postalCode === "" ? true : false,
            },
            email: {
                value: partner.email ? partner.email : "",
                error: false,
                errorMessage: false,
                label: "Adresse e-mail de l’entreprise",
                placeholder: "speedpark91@gmail.com",
                validationType: "email",
                isRequired: true,
                isLoadEmpty: !partner.email || partner.email === "" ? true : false,
            },
            website: {
                value: partner.website ? partner.website : "",
                error: false,
                errorMessage: false,
                label: "Site web",
                placeholder: "https://www.speedpark.fr",
                validationType: "website",
                isRequired: false,
                isLoadEmpty: !partner.website || partner.website === "" ? true : false,
            },
            phoneNumber: {
                value: partner.phoneNumber ? partner.phoneNumber : "",
                error: false,
                errorMessage: false,
                label: "Numéro de téléphone de l'entreprise",
                placeholder: "0123456789",
                validationType: "phone",
                isRequired: true,
                isLoadEmpty: !partner.phoneNumber || partner.phoneNumber === "" ? true : false,
            },
            siretNumber: {
                value: partner.legalInfoID && partner.legalInfoID.siretNumber ? partner.legalInfoID.siretNumber : "",
                error: false,
                errorMessage: false,
                label: "Numéro de siret",
                placeholder: "123 456 789 00012",
                validationType: "siret",
                isRequired: true,
                isLoadEmpty: !partner.legalInfoID || partner.legalInfoID.siretNumber === "" ? true : false,
            },

        }

        const socialNetworks = {
            //description, logo, array of social networks
            logo: {
                value: partner.partnerSocialNetworksID && partner.partnerSocialNetworksID.logo ? partner.partnerSocialNetworksID.logo : "",
                error: false,
                errorMessage: false,
                label: "Logo",
                placeholder: "Logo de l'entreprise",
                validationType: "text",
                isRequired: false,
                type: partner.partnerSocialNetworksID && partner.partnerSocialNetworksID.logo ? "remote-logo" : "local",
            },
            description: {
                value: partner.partnerSocialNetworksID && partner.partnerSocialNetworksID.description ? partner.partnerSocialNetworksID.description : "",
                error: false,
                errorMessage: false,
                label: "Description de votre établissement",
                placeholder: "Venez découvrir Speadpark, l'endroit parfait pour faire le plein de sensations fortes et de fun entre amis ou en famille.",
                validationType: "text",
                isRequired: false,
            },
            socialNetworks: {
                value: partner.partnerSocialNetworksID && partner.partnerSocialNetworksID.socialNetworks ? partner.partnerSocialNetworksID.socialNetworks : [],
                error: false,
                errorMessage: false,
                label: "Réseaux sociaux",
                placeholder: "Réseaux sociaux",
                validationType: "text",
                isRequired: false,
            }
        }

        const booking = {
            bookingPolicy: {
                value: partner && partner.engineID ? partner.engineID.bookingPolicy : "",
                error: false,
                errorMessage: false,
                label: "Politique de réservation",
                placeholder: "Politique de réservation",
                validationType: "text",
                isLoadEmpty: !partner.engineID || partner.engineID.bookingPolicy === "" ? true : false,
            },

            bookingEmail: {
                value: partner && partner.engineID ? partner.engineID.bookingEmail : "",
                error: false,
                errorMessage: false,
                label: "Adresse e-mail de réservation",
                placeholder: "Avenueparc@gmail.com",
                validationType: "email",
                isRequired: false,
                isLoadEmpty: !partner.engineID || partner.engineID.bookingEmail === "" ? true : false,
            },

            managementSoftware: {
                value: partner && partner.engineID ? partner.engineID.managementSoftware : "",
                error: false,
                errorMessage: false,
                label: "Logiciel de réservation",
                placeholder: "SpeedParkIo",
                validationType: "text",
                isRequired: false,
                isLoadEmpty: !partner.engineID || partner.engineID.managementSoftware === "" ? true : false,
            },

            engineAPI: {
                value: partner && partner.engineID ? partner.engineID.engineAPI : "",
                error: false,
                errorMessage: false,
                label: "Clé API de votre logiciel",
                placeholder: "https://www.speedpark.fr/api",
                validationType: "text",
                isRequired: false,
                isLoadEmpty: !partner.engineID || partner.engineID.engineAPI === "" ? true : false,
            }
        }

        const personal = {
            lastname: {
                value: partner.managerID && partner.managerID.lastname ? partner.managerID.lastname : "",
                error: false,
                errorMessage: false,
                label: "Nom",
                placeholder: "Dupont",
                validationType: "text",
                isRequired: true,
            },
            firstname: {
                value: partner.managerID && partner.managerID.firstname ? partner.managerID.firstname : "",
                error: false,
                errorMessage: false,
                label: "Prénom",
                placeholder: "Jean",
                validationType: "text",
                isRequired: true
            },
            email: {
                value: partner.managerID && partner.managerID.email ? partner.managerID.email : "",
                error: false,
                errorMessage: false,
                label: "Adresse e-mail personnelle",
                placeholder: "jean.dupont@gmail.com",
                validationType: "email",
                isRequired: true
            },
            phoneNumber: {
                value: partner.managerID && partner.managerID.phoneNumber ? partner.managerID.phoneNumber : "",
                error: false,
                errorMessage: false,
                label: "Numéro de téléphone",
                placeholder: "06 34 23 83 81",
                validationType: "phone",
                isRequired: true
            },
            communicationPreference: {
                value: partner.managerID && partner.managerID.communicationPreference ? partner.managerID.communicationPreference : [],
                error: false,
                errorMessage: false,
                label: "Préférence de communication",
                placeholder: "",
                validationType: "text",
                isRequired: true
            },
            position: {
                value: partner.managerID && partner.managerID.position ? partner.managerID.position : "",
                error: false,
                errorMessage: false,
                label: "Poste dans l’entreprise",
                placeholder: "PDG",
                validationType: "text",
                isRequired: true
            }
        }

        const legalDocument = partner.legalInfoID && partner.legalInfoID.legalDocuments ? partner.legalInfoID.legalDocuments : [];

        const activity = partner.activityID ? partner.activityID : [];
        const offers = partner.offersID ? partner.offersID : [];

        //create object partner
        const parsePartner = {
            status: status,
            company: company,
            booking: booking,
            socialNetworks: socialNetworks,
            personal: personal,
            legalDocuments: legalDocument,
            activity: activity,
            offers: offers,
        }

        return parsePartner;
    }

    const parsePartnerForApi = (partner) => {

        const parsePartner = {
            comment: partner.company && partner.company.comment ? partner.company.comment.value : "",
            name: partner.company && partner.company.name ? partner.company.name.value : "",
            address: partner.company && partner.company.address ? partner.company.address.value : "",
            postalCode: partner.company && partner.company.postalCode ? partner.company.postalCode.value : "",
            email: partner.company && partner.company.email ? partner.company.email.value : "",
            website: partner.company && partner.company.website ? partner.company.website.value : "",
            phoneNumber: partner.company && partner.company.phoneNumber ? partner.company.phoneNumber.value : "",
            isVerified: true,
            engineID: {
                engineAPI: partner.booking && partner.booking.engineAPI ? partner.booking.engineAPI.value : "",
                managementSoftware: partner.booking && partner.booking.managementSoftware ? partner.booking.managementSoftware.value : "",
                bookingPolicy: partner.booking && partner.booking.bookingPolicy ? partner.booking.bookingPolicy.value : "",
                bookingEmail: partner.booking && partner.booking.bookingEmail ? partner.booking.bookingEmail.value : "",
            },
            legalInfoID: {
                siretNumber: partner.company && partner.company.siretNumber ? partner.company.siretNumber.value : "",
                legalDocuments: partner.legalDocuments ? partner.legalDocuments : [],
            },
            managerID: {
                lastname: partner.personal && partner.personal.lastname ? partner.personal.lastname.value : "",
                firstname: partner.personal && partner.personal.firstname ? partner.personal.firstname.value : "",
                email: partner.personal && partner.personal.email ? partner.personal.email.value : "",
                phoneNumber: partner.personal && partner.personal.phoneNumber ? partner.personal.phoneNumber.value : "",
                communicationPreference: partner.personal && partner.personal.communicationPreference ? partner.personal.communicationPreference.value : "",
                position: partner.personal && partner.personal.position ? partner.personal.position.value : "",
            },
            partnerSocialNetworksID: {
                description: partner.socialNetworks && partner.socialNetworks.description ? partner.socialNetworks.description.value : "",
                logo: partner.socialNetworks && partner.socialNetworks.logo ? partner.socialNetworks.logo.value : "",
                socialNetworks: partner.socialNetworks && partner.socialNetworks.socialNetworks ? partner.socialNetworks.socialNetworks.value : [],
            },
            status: "review",
        };

        return parsePartner;
    }


    const parsePartnerForUpdate = (partner) => {

        const parsePartner = {
            comment: partner.company && partner.company.comment ? partner.company.comment.value : "",
            name: partner.company && partner.company.name ? partner.company.name.value : "",
            address: partner.company && partner.company.address ? partner.company.address.value : "",
            postalCode: partner.company && partner.company.postalCode ? partner.company.postalCode.value : "",
            email: partner.company && partner.company.email ? partner.company.email.value : "",
            website: partner.company && partner.company.website ? partner.company.website.value : "",
            phoneNumber: partner.company && partner.company.phoneNumber ? partner.company.phoneNumber.value.toString() : "",
            isVerified: true,
            engineID: {
                engineAPI: partner.booking && partner.booking.engineAPI ? partner.booking.engineAPI.value : "",
                managementSoftware: partner.booking && partner.booking.managementSoftware ? partner.booking.managementSoftware.value : "",
                bookingPolicy: partner.booking && partner.booking.bookingPolicy ? partner.booking.bookingPolicy.value : "",
                bookingEmail: partner.booking && partner.booking.bookingEmail ? partner.booking.bookingEmail.value : "",
            },
            legalInfoID: {
                siretNumber: partner.company && partner.company.siretNumber ? partner.company.siretNumber.value : "",
                legalDocuments: partner.legalDocuments ? partner.legalDocuments : [],
            },
            managerID: {
                lastname: partner.personal && partner.personal.lastname ? partner.personal.lastname.value : "",
                firstname: partner.personal && partner.personal.firstname ? partner.personal.firstname.value : "",
                email: partner.personal && partner.personal.email ? partner.personal.email.value : "",
                phoneNumber: partner.personal && partner.personal.phoneNumber ? partner.personal.phoneNumber.value.toString() : "",
                communicationPreference: partner.personal && partner.personal.communicationPreference ? partner.personal.communicationPreference.value : "",
                position: partner.personal && partner.personal.position ? partner.personal.position.value : "",
            },
            partnerSocialNetworksID: {
                description: partner.socialNetworks && partner.socialNetworks.description ? partner.socialNetworks.description.value : "",
                logo: partner.socialNetworks && partner.socialNetworks.logo ? partner.socialNetworks.logo.value : "",
                socialNetworks: partner.socialNetworks &&
                partner.socialNetworks.socialNetworks &&
                Array.isArray(partner.socialNetworks.socialNetworks.value)
                    ? partner.socialNetworks.socialNetworks.value
                    : [], // Ensure it's an array
            },

        }

        const filterNonEmptyValues = (obj) => {
            const filteredObj = {};

            Object.entries(obj).forEach(([key, value]) => {
                // Check for non-empty nested objects
                if (value && typeof value === 'object' && !Array.isArray(value)) {
                    const nestedFilteredObj = filterNonEmptyValues(value);
                    if (Object.keys(nestedFilteredObj).length > 0) {
                        filteredObj[key] = nestedFilteredObj;
                    }
                    // Check for non-empty, non-false, and non-null/undefined values
                } else if (value !== "" && value !== null && value !== undefined && value !== false) {
                    filteredObj[key] = value;
                }
            });

            return filteredObj;
        };

        const nonEmptyValues = filterNonEmptyValues(parsePartner);
        return nonEmptyValues;

    };


    return {parsePartner, parsePartnerForApi, parsePartnerForUpdate};
}

export default useParsePartner