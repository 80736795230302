import {postRequestJson, postRequestJsonNotToken} from '../request/apiRequest';

const PasswordResetServices = {

    sendPasswordCode: async (data) => {
        return await postRequestJsonNotToken('/password-reset/request', data);
    },

    checkPasswordCode: async (data) => {
        return await postRequestJsonNotToken('/password-reset/verify', data);
    },

    resetPassword: async (data) => {
        return await postRequestJsonNotToken('/password-reset/reset', data);
    },

    resetPasswordLogin: async (data) => {
        return await postRequestJson('/password-reset/reset-password-login', data);
    }
}

export default PasswordResetServices;