import React, {useState} from 'react';
import '../../styles/views/login.css';
import frimityStars from '../../assets/images/svg/stars_illustration.svg';
import frimityMockup from '../../assets/images/png/mockup_desktop.png';
import logo from '../../assets/images/svg/logo_row.svg';
import mail from '../../assets/icones/global/mail_light.svg';
import SubmitButton from "../../components/button/submitButton";
import Input from "../../components/form/inputText/input";
import useAuth from '../../hooks/useAuth';
import {Link} from "react-router-dom";
import Button from "../../components/button/button";
import useParseErrorMessage from "../../hooks/parseText/useParseErrorMessage";
import InputPassword from "../../components/form/inputText/inputPassword";

const Login = () => {

    const { login, error } = useAuth();
    const {parseErrorMessage} = useParseErrorMessage();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleChangeForm = (e) => {
        if(e.target.name === 'email') {
            setEmail(e.target.value);
        } else {
            setPassword(e.target.value);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const credentials = {
            email: email,
            password: password
        };
        login(credentials);
    }

    return(
        <div className="main">
            <div className="main__container login">
                <div className="main__container__section__login">
                    <div className="main__container__form__login">
                        <img src={logo} alt="logo"/>
                        <div className={"main__container__form__col"}>
                            <div className={"main__container__form__col__title"}>
                                <h1 className={"title-25"}>Se connecter</h1>
                                <p className={"text-16 light"}>Content de vous revoir ! Veuillez entrer vos coordonnées.</p>
                            </div>


                            <form className={"form__login"} onSubmit={handleSubmit}>
                                <Input
                                    label={"Email"}
                                    name={"email"}
                                    placeholder={"Entrez votre email"}
                                    type={"email"}
                                    validationType={"email"}
                                    isColumn={true}
                                    onChangeForm={handleChangeForm}
                                />

                                <InputPassword
                                    label={"Mot de passe"}
                                    name={"password"}
                                    placeholder={"Entrez votre mot de passe"}
                                    type={"password"}
                                    isColumn={true}
                                    onChangeForm={handleChangeForm}

                                />

                                <Link to={"/password-reset"} className={"text-14 bold"}>Mot de passe oublié ?</Link>
                                <SubmitButton text={"Se connecter"} isFull={true}/>

                                <div className={"separation-line"}></div>

                                <div className={"main__container__form__col__footer"}>
                                    <p className={"text-14"}>Vous n'avez pas de compte ? <Link
                                        to={"/signin"}>S'inscrire</Link>
                                    </p>
                                </div>
                            </form>


                            {
                                error && <div className={"is__error"}>{parseErrorMessage(error.error)}</div>
                            }
                        </div>


                    </div>
                    <div className="main__footer">
                        <p className={"text-12"}>© Frimity 2023</p>
                        <p className={"text-12"}>v {process.env.REACT_APP_VERSION}</p>
                        <div className={"main__footer__links"}>
                            <img src={mail} alt="logo"/>
                            <a href={"mailto:contact@frimity.fr"}><p className={"text-12"}>contact@frimity.fr</p></a>
                        </div>
                    </div>
                </div>
                <div className="main__container__image__login">
                    <div className={"main__container__quote"}>
                        <p className={"text-20"}>“Le concept et l'approche de Frimity m'ont séduit. J'ai choisi de m'engager avec eux car je souhaite ne plus dépendre des recherches sur internet. Merci à l'équipe pour cette initiative !”</p>
                        <div className={"main-container__row"}>
                        <div className={"main-container__col"}>
                                <p className={"title-18"}>Anthony Lanto</p>
                                <p className={"text-16"}>Fondateur, Accro Parc</p>
                            </div>

                            <img src={frimityStars} alt={"stars"}/>
                        </div>
                    </div>

                    <img src={frimityMockup} alt="logo"/>
                </div>
            </div>

        </div>

    )
}
export default Login;