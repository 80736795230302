import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import ActivitiesTagsServices from "../../../api/services/activitiesTags";
import useParseCategories from "../../../hooks/parseText/useParseCategories";
import Checkbox from "../../../components/form/inputCheckbox/checkbox";
import { ErrorContext } from "../../../context/errorContext";
import Input from "../../../components/form/inputText/input";
import InputSelect from "../../../components/form/inputSelect/inputSelect";
import { useLoader } from "../../../context/loaderContext";
import Radio from "../../../components/form/inputRadio/radio";
import IconButton from "../../../components/button/iconButton";
import add from "../../../assets/icones/global/add.svg";
import Button from "../../../components/button/button";
import close from "../../../assets/icones/global/close-stroke.svg";


const ToKnow = ({ toKnow = [], setActivity, toKnowInfos, toKnowOther, isDisabled}) => {
    const { parseCategories } = useParseCategories();
    const [toKnowList, setToKnowList] = useState([]);
    const [showInputForCategory, setShowInputForCategory] = useState({});
    const [inputValues, setInputValues] = useState({}); // Consolidated tempOptions here
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const optionAge = useMemo(() => Array.from({ length: 25 }, (_, i) => ({ name: `${i + 1} ans`, value: i + 1 })), []);
    const optionExperience = useMemo(() => ([
        { name: "Débutant", value: "beginner" },
        { name: "Intermédiaire", value: "intermediate" },
        { name: "Confirmé", value: "advanced" },
        { name: "Expert", value: "expert" }
    ]), []);

    useEffect(() => {
        setLoading(true);
        ActivitiesTagsServices.getActivitiesTags()
            .then((response) => {
                if (response.status === 200) {
                    setToKnowList(sortToKnow(response.data));
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            })
    }, [
        setToKnowList,
        setLoading
    ]);

    const sortToKnow = useCallback(toKnowList => {
        return toKnowList.reduce((sorted, item) => {
            (sorted[item.category] = sorted[item.category] || []).push(item);
            return sorted;
        }, {});
    }, []);

    const handleToKnowChange = useCallback((e) => {
        const value = e.target.value;
        //check if radio or checkbox
        if(e.target.type === "radio"){
            // Filter out the previous animal selection, if any
            const newToKnow = toKnow.filter(item => item.category !== 'animals');
            // Add the new animal selection
            newToKnow.push({ name: value, category: 'animals' });
            setActivity('toKnow', newToKnow);
        }else{
            const isChecked = e.target.checked;
            if (isChecked) {
                setActivity('toKnow', [...toKnow, { name: value }]);
            } else {
                setActivity('toKnow', toKnow.filter((item) => item.name !== value));
                //empty input value
                setActivity(`toKnowInfos.${value}.value`, "");
            }
        }
    }, [toKnow, setActivity]);

    const handleAddOptionClick = useCallback((category) => {
        setShowInputForCategory(prev => ({ ...prev, [category]: !prev[category] }));
    }, []);

    const handleInputChange = useCallback((category, value) => {
        setInputValues(prev => ({ ...prev, [category]: value }));
    }, []);

    const handleAddOption = useCallback((category) => {
        const option = inputValues[category].trim();
        if (!option) return;

        setInputValues(prev => ({ ...prev, [category]: '' }));
        setShowInputForCategory(prev => ({ ...prev, [category]: false }));
        //it need to iterate
        setActivity(`toKnowOther.${category}`, toKnowOther[category] ? [...toKnowOther[category], option] : [option]);
    }, [inputValues, toKnowInfos, setActivity]);

    // Remove option from toKnowInfos
    const removeOption = useCallback((category, index) => {
        setActivity(`toKnowOther.${category}`, toKnowOther[category].filter((_, i) => i !== index));
    }, [toKnowInfos, setActivity]);


    const isToKnowItemSelected = useCallback((itemName) => {
        return toKnow.some((item) => item.name === itemName);
    }, [toKnow]);

    const renderInput = useCallback((item) => {

        if (!isToKnowItemSelected(item.name)) {
            return null;
        }

        switch (item.name) {
            case "minAge":
                return <InputSelectComponent key={item.name} name={item.name} toknowItem={toKnowInfos[item.name]} options={optionAge} setActivity={setActivity} isDisabled={isDisabled}/>;
            case "experience":
                return <InputSelectComponent key={item.name} name={item.name} toknowItem={toKnowInfos[item.name]} options={optionExperience} setActivity={setActivity} isDisabled={isDisabled}/>;
            case "clothingRecommendation":
                return <InputComponent key={item.name} name={item.name} toknowItem={toKnowInfos[item.name]} setActivity={setActivity} isDisabled={isDisabled}/>;
            case "otherRecommendation":
                return <InputComponent key={item.name} name={item.name} toknowItem={toKnowInfos[item.name]} setActivity={setActivity} isDisabled={isDisabled}/>;
            default:
                return null;
        }
    }, [toKnowInfos, optionAge, optionExperience, setActivity, isToKnowItemSelected]);

    return (
        <FormSectionRow title={"Bon à savoir"} description={"Sélectionnez les informations pertinentes pour l'activité"} classname={"to-know"}>
            {toKnowList && Object.keys(toKnowList).map((key) => (
                //check if key is "animals" and set radio button
                <div className={"form__container__col categories"} key={key}>
                    <h3 className={"form__title"}>{parseCategories(key)}</h3>
                    <div className={"form__row grid"}>
                        {
                            key === "animals" ?
                                toKnowList[key].map((item) => (
                                    <Radio
                                        key={item.id}
                                        name={"toKnow"}
                                        value={item.name}
                                        label={parseCategories(item.name)}
                                        onChange={handleToKnowChange}
                                        checked={isToKnowItemSelected(item.name)}
                                        disabled={isDisabled}
                                    />
                                ))
                                :
                                toKnowList[key].map((item) => (
                                    <Checkbox
                                        key={item.id}
                                        name={"toKnow"}
                                        value={item.name}
                                        label={parseCategories(item.name)}
                                        onChange={handleToKnowChange}
                                        isChecked={isToKnowItemSelected(item.name)}
                                        disabled={isDisabled}
                                    />
                                ))
                        }
                    </div>
                    {
                        Object.keys(toKnowOther).map((category) => (
                            category === key &&
                            <div className={"form__container__col categories"} key={category}>
                                <div className="add-option-container">
                                    {showInputForCategory[category] ? (
                                        <div className="form_input_row">
                                            <Input
                                                type="text"
                                                valueInput={inputValues[category] || ''}
                                                onChangeForm={(e) => handleInputChange(category, e.target.value)}
                                                placeholder={"Option"}
                                                disabled={isDisabled}
                                            />
                                            { !isDisabled && <div className={"modal__content__actions"}>
                                                <Button text={"Ajouter"} isPrimary={true} onClick={() => handleAddOption(category)}/>
                                            </div>}
                                        </div>
                                    ) : isDisabled ? null : (
                                        <IconButton icon={add} text="Ajouter une option" onClick={() => handleAddOptionClick(category)} />
                                    )}
                                    {/* Display added options with a delete button */}
                                    <div className="options-list">
                                        {toKnowOther[category]?.map((option, index) => (
                                            <div key={`${category}-option-${index}`} className="option-item">
                                                {option}
                                                <div className={"option-item__delete"}>
                                                    <img src={close} alt={"delete"}
                                                         onClick={() => removeOption(category, index)}/>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <div className={"form__row grid_more"}>
                        {toKnowList[key].map(renderInput)}
                    </div>
                </div>
            ))}
        </FormSectionRow>
    );
};

const InputSelectComponent = React.memo(({toknowItem, name, options, setActivity, isDisabled}) => (

    <div className={"form__row grid"}>
        <InputSelect
            name={name}
            label={toknowItem.label}
            value={toknowItem.value}
            valueSelected={toknowItem.value}
            onChange={(e) => setActivity(`toKnowInfos.${name}.value`, e.target.value)}
            placeholder={"Sélectionnez"}
            errorMessage={toknowItem.errorMessage}
            options={options}
            isColumn={true}
            isDisabled={isDisabled}
        />
    </div>
));

const InputComponent = React.memo(({toknowItem, name, setActivity, isDisabled}) => (
    <div className={"form__row grid text-grid"}>
        <Input
            name={name}
            label={toknowItem.label}
            valueInput={toknowItem.value}
            onChangeForm={(e) => setActivity(`toKnowInfos.${name}.value`, e.target.value)}
            placeholder={"Sélectionnez"}
            errorMessage={toknowItem.errorMessage}
            isColumn={true}
            disabled={isDisabled}
        />
    </div>
));

export default ToKnow;
