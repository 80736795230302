import React, {useState} from 'react';
import check from '../../../../assets/images/svg/check_round_green.svg';
import Button from "../../../../components/button/button";
import {Link, useNavigate} from "react-router-dom";
import arrow from '../../../../assets/icones/arrows/left.svg';
import '../../../../styles/views/verifications.css';
import {useDispatch, useSelector} from "react-redux";
import {userSetEmailVerificationStatus} from "../../../../redux/slice/authSlice";
import StepBar from "../../../../components/stepBar/stepBar";
import {useParams} from "react-router";
import {useStep} from "../../../../context/login/stepContext";

const VerificationUserCheck = () => {

    //get local storage email
    const user = useSelector((state) => state.auth.user);
    const email = user ? user.email : null;
    const navigate  = useNavigate();
    const dispatch = useDispatch();

    const { step, stepArray, nextStep, setStep } = useStep();

    const handleVerification = () => {
        // Remove emailSent flag from local storage
        localStorage.removeItem('emailSent');

        // Dispatch email verification status update
        dispatch(userSetEmailVerificationStatus());

        // Move to the next step using context
        nextStep(user, navigate);
    };


    return (
        <div className="main verification">
            <div className="main__container">
                <StepBar step={step} textArray={stepArray.map(step => step.label)} />
            </div>
            <div className="main__container__col has-margin-top">
                <div className="main__container__col32">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Email verifié</h1>
                    <p className={"text-14 light"}>Votre email {email} a été vérifié avec succès.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Continuer"} isPrimary={true} isBig={true} onClick={handleVerification}/>
                </div>
            </div>
        </div>
    );
}

export default VerificationUserCheck;