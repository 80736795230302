import React, {useContext, useEffect, useState} from 'react';
import '../../styles/views/singleActivity.css';
import HeaderView from "../../components/header/headerView";
import TabBar from "../../components/navbar/tabBar";
import Tag from "../../components/tag/tag";
import IconButton from "../../components/button/iconButton";
import trash from '../../assets/icones/global/trash_can_red.svg';
import save from '../../assets/icones/global/save.svg';
import Form from "../../components/form/form";
import Images from "./formActivity/images";
import Name from "./formActivity/name";
import Subtitle from "./formActivity/subtitle";
import Schedule from "./formActivity/schedule";
import Category from "./formActivity/category";
import NbPersons from "./formActivity/nbPersons";
import Duration from "./formActivity/duration";
import Description from "./formActivity/description";
import PraticalInfos from "./formActivity/praticalInfos";
import Expected from "./formActivity/expected";
import CancelPolicy from "./formActivity/cancelPolicy";
import Included from "./formActivity/included";
import BookingPolicy from "./formActivity/bookingPolicy";
import Access from "./formActivity/access";
import PricingAndExtras from "./formActivity/pricingAndExtras";
import {useParams} from "react-router";
import ActivitiesServices from "../../api/services/activities";
import useParseActivity from "../../hooks/parseDataApi/useParseActivity";
import ToKnow from "./formActivity/toKnow";
import useStatusName from "../../hooks/parseText/useStatusName";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import {useNavigate} from "react-router-dom";
import useFormValidation from "../../hooks/form/useFormValidation";
import ActivitiesImages from "../../api/services/activitiesImages";
import ModalVerification from "../../components/modal/modalInfos/modalVerification";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import RadioRound from "../../components/form/inputRadio/radioRound";
import Insurance from "./formActivity/insurance";
import {useSelector} from "react-redux";
import Types from "./formActivity/types";
import {usePartner} from "../../context/partnerContext";
import check from "../../assets/icones/global/check_stroke.svg";
import useVerificationHandlers from '../../hooks/verification/entitiesVerification';
import ModalDeleting from "../../components/modal/modalDeleting/modalDeleting";
import useActivitiesHandler from "../../hooks/serverActions/activities/useActivitiesHandler";
import ModalAccept from "../../components/modal/modalInfos/modalAccept";

const SingleActivity = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [draftModal, setDraftModal] = useState(false);
    const partnerId = useParams().partnerId;
    const activityId = useParams().activityId;

    const [subscription, setSubscription] = useState(false);
    const navigate = useNavigate();
    const { parseActivity, parseActivityforUpdate, parseDraftActivity} = useParseActivity();
    const { getStatusName } = useStatusName();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { handleActivitiesVerification } = useVerificationHandlers();

    const [isUpdated, setIsUpdated] = useState(false);
    const [errorIsUpdated, setErrorIsUpdated] = useState(false);

    const { currentPartnerId, allPartners } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = allPartners.find(partner => partner._id === currentPartnerId) || null;

    const { formState, setFormState, handleFormChange, getFormChanges } = useFormUpdate(parseActivity([]));
    const {
        addActivity,
        updateActivity,
        saveDraftActivity,
        deleteActivity,
    } = useActivitiesHandler({ partnerId, user, setIsModalOpen, showError, setLoading });

    const isDisabled  = formState.status === "draft" ? false : user && user.role === "partner" || user.role === "partners-admin" ? true : false;



    useEffect(() => {
        setLoading(true);
        try {
            PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId)
            .then((response) => {
                if(response.status === 200){
                    if(response.data && response.data.subscriptionPlan !== null) {
                        setSubscription(response.data.subscriptionPlan);
                    }else{
                        setSubscription(false);
                    }
                }else {
                    setSubscription(false);
                }
            })

           ActivitiesServices.getActivityById(activityId)
           .then((response) => {
               response.status === 200 ? setFormState(parseActivity(response.data)) : navigate(`/partners/${partnerId}/activities`);
           })
       } catch (error) {
           showError(error);
       }
        finally {
           setLoading(false);
       }
    }, [isUpdated]);

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        setDraftModal(true); // Show the custom modal
        e.returnValue = ""; // Trigger the default browser confirmation (required for some browsers)
    };

    useEffect(() => {
        // Attach the `beforeunload` event
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup event on unmount
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateForm(formState, setFormState);

        if (isValid.isFormValid) {
            const newActivityChanges = getFormChanges();
            const activityForApi = parseActivityforUpdate(newActivityChanges);

            // Filter local images to upload
            const localImages = activityForApi.activityImages?.images?.filter(img => img.isLocal) || [];
            const isAlreeadyWaiting = formState.status === "waiting";

            await updateActivity(activityId, activityForApi, localImages, isAlreeadyWaiting);
        }
    };

    const handleSubmitDraft = async () => {
        const newActivityChanges = getFormChanges();
        const activityForApi = parseActivityforUpdate(newActivityChanges);

        //set status to draft
        activityForApi.status = "draft";

        const localImages = activityForApi.activityImages?.images?.filter((img) => img.isLocal);
        await updateActivity(activityId, activityForApi, localImages, true, true);
    };

    const handleDelete = async (activityId) => {
        await deleteActivity(activityId);
    };

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Activité proposé"} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`,
                    },
                    {
                        name: "Utilisateurs",
                        link: `/partners/${partnerId}/users`,

                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={1}
            />


            <div className={"header__container"}>
                <div className={"header__container__col"}>
                    <h2 lassName={"text-18 bold"}>Détail de vos activités</h2>
                    <p className={"text-14"}>Vous pouvez voir et modifier les informations concernant vos activités</p>
                </div>
            </div>

            <div className={"main-activity__container"}>
                <div className={"main-activity__container__row"}>
                    <div className={"container__row"}>
                        <h1>{formState.reference.value}</h1>
                        <Tag text={getStatusName(formState.status)} status={formState.status} />
                    </div>
                    <div className={"main-activity__container__buttons"}>
                        <IconButton icon={trash} text={"Supprimer"} isRed={true} onClick={() => setDeleteModal(true)} />
                        {
                            formState.status !== "draft" ? <IconButton icon={save} text={"Enregistrer en brouillon"} isPrimary={false} onClick={() => setDraftModal(true)} /> : <IconButton icon={save} text={"Sauvegarder"} isPrimary={false} onClick={() => handleSubmitDraft()} />
                        }
                        {user && (user.role === "admin" || user.role === "super-admin") && formState.status === "waiting" && <IconButton icon={check} text={"Vérifier l'activitée"} isPrimary={true} onClick={() => handleActivitiesVerification(activityId, partnerId, "active", setIsUpdated)} />}
                    </div>
                </div>


                <Form
                    children={
                        formState &&
                        <>
                            <Images images={formState.images} setActivity={handleFormChange} type={"remote"}
                                    activityId={activityId} partnerId={partnerId} isImageChecked={true} />
                            <Name name={formState.name} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            { user?.role === "admin" || user?.role === "super-admin" && <Subtitle subtitle={formState.subtitle} setActivity={handleFormChange} isDisabled={isDisabled}/> }
                            <Types type={formState.type} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <Schedule mode={"edit"} schedule={formState.schedule} setActivity={handleFormChange}/>
                            <Category category={formState.category} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <NbPersons nbPersons={formState.nbPersons} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <Duration duration={formState.duration} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <Description description={formState.description} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <PraticalInfos practicalInfo={formState.practicalInfo} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <Expected expected={formState.expected} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <ToKnow toKnow={formState.toKnow} setActivity={handleFormChange}
                                    toKnowInfos={formState.toKnowInfos} toKnowOther={formState.toKnowOther} isDisabled={isDisabled}/>
                            <BookingPolicy bookingPolicy={formState.bookingPolicy} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <PricingAndExtras pricingAndExtras={formState.pricingAndExtras}
                                              setActivity={handleFormChange}
                                              commission={currentPartner?.subscriptionID?.commission}/>
                            <Access access={formState.access} setActivity={handleFormChange} isDisabled={isDisabled}/>
                            <CancelPolicy cancel={formState.cancel} setActivity={handleFormChange} isAdmin={user.role === "admin"} isDisabled={isDisabled}/>
                            <Included included={formState.included} setActivity={handleFormChange}/>
                            <Insurance insurance={formState.hasInsurance} setActivity={handleFormChange} isUpdated={true} setIsUpdated={setIsUpdated} errorIsUpdated={errorIsUpdated}/>
                        </>
                    }
                    onSubmit={handleSubmit}
                    mode={"sendValidation"}
                    moreSubmitOptions={ formState.status !== "draft" ? [
                        { label: "Enregistrer en brouillon", onClick: () => setDraftModal(true) }
                    ] : [
                        { label: "Sauvegarder", onClick: () => handleSubmitDraft() }
                    ]}
                />
            </div>

            <ModalVerification isOpen={isModalOpen} link={`/partners/${partnerId}/activities`} type={"activitée"}
                               action={"modifiée"}/>
            <ModalDeleting isOpen={deleteModal} closeModal={setDeleteModal} deleteItem={() => handleDelete(activityId)} itemName={"votre activitée"}/>

            <ModalAccept
                isOpen={draftModal}
                closeModal={() => {
                    setDraftModal(false);
                    navigate(`/partners/${partnerId}/activities`);
                }
                }
                addOffer={handleSubmitDraft}
                title={"Quitter sans sauvegarder ?"}
                message={"Souhaitez-vous sauvegarder votre fiche en brouillon pour y revenir plus tard ?"}
            />
        </div>
    )
}

export default SingleActivity;