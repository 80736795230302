import React, {useState} from 'react';
import '../../../styles/components/modal.css';
import error from "../../../assets/icones/global/shield_error.svg";
import validate from "../../../assets/icones/global/shield_validate.svg";
import close from "../../../assets/icones/global/close-stroke.svg";
import ModalVerifiedStatus from "./modalVerifiedStatus";
import useOpenModal from "../../../hooks/useOpenModal";
import {useNavigate} from "react-router-dom";

import { usePartner } from "../../../context/partnerContext";

const ModalBookingManager = ({isOpen, closeModal, partnerId}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const { setPartnerId } = usePartner();
    const navigate = useNavigate();

    const handlerClick = (type) => {
        setPartnerId(partnerId);
        if(type === "schedule") {
            navigate(`/partners/${partnerId}/bookings/schedule`);
        } else {
            navigate(`/partners/${partnerId}/bookings`);
        }

        closeModal();
    }

    return(
        <>
            <div className={`modal offer activity ${isOpen ? "open" : ""}`}>
                <div className={"modal__content"}>
                    <div className={"modal__content"}>
                        <p className={"text-14 offer_div"} onClick={() => handlerClick("booking")}>Voir les réservations</p>

                        <p className={"text-14 offer_div"} onClick={() => handlerClick("schedule")}>Voir les disponibilités</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalBookingManager;
