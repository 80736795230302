import React, { useState, useEffect } from 'react';
import FormSection from "../../../components/form/formSection";
import InputIcon from "../../../components/form/inputText/inputIcon";
import InputImages from "../../../components/form/inputFile/inputImages";
import Textarea from "../../../components/form/InputTextarea/textarea";

import instagramIcon from "../../../assets/images/png/instagram_icon.png";
import facebookIcon from "../../../assets/images/png/facebook_icon.png";
import tiktokIcon from "../../../assets/images/png/tiktok_icon.png";
import linkedinIcon from "../../../assets/images/png/linkedin_icon.png";

const SocialNetworks = ({ mode, socialNetworks, setPartner, partnerId }) => {
    // State to manage social network URLs
    const [networks, setNetworks] = useState([]);

    // Predefined options for social networks
    const socialNetworkOptions = [
        {
            label: "Instagram",
            value: "instagram",
            icon: instagramIcon,
            placeholder: "https://www.instagram.com/your_profile",
        },
        {
            label: "Facebook",
            value: "facebook",
            icon: facebookIcon,
            placeholder: "https://www.facebook.com/your_profile",
        },
        {
            label: "TikTok",
            value: "tiktok",
            icon: tiktokIcon,
            placeholder: "https://www.tiktok.com/@your_profile",
        },
        {
            label: "LinkedIn",
            value: "linkedin",
            icon: linkedinIcon,
            placeholder: "https://www.linkedin.com/in/your_profile",
        },
    ];

    // Default values for socialNetworks fields
    const defaultSocialNetworks = {
        logo: { value: [], label: "Logo" },
        description: { value: "", label: "Description", placeholder: "Add a description..." },
        socialNetworks: { value: [] },
    };

    const effectiveSocialNetworks = {
        ...defaultSocialNetworks,
        ...socialNetworks,
    };

    useEffect(() => {
        if (socialNetworks?.socialNetworks?.value?.length > 0) {
            // Use the `value` array directly to initialize `networks`
            setNetworks(socialNetworks.socialNetworks.value);
        } else {
            // If no value exists, initialize with default social network options
            const initialNetworks = socialNetworkOptions.map((option) => ({
                type: option.value,
                url: "",
                isValid: true,
            }));

            setNetworks(initialNetworks);
        }
    }, [socialNetworks, setPartner]);



    // Function to validate URL based on social network type
    const validateLink = (type, url) => {
        const patterns = {
            instagram: /https:\/\/(www\.)?instagram\.com\/.+/,
            facebook: /https:\/\/(www\.)?facebook\.com\/.+/,
            tiktok: /https:\/\/(www\.)?tiktok\.com\/.+/,
            linkedin: /https:\/\/(www\.)?linkedin\.com\/.+/,
        };
        return patterns[type] ? patterns[type].test(url) : true;
    };

    // Update state and propagate changes to parent via `setPartner`
    const updateNetworks = (updatedNetworks) => {
        setNetworks(updatedNetworks);
        setPartner("socialNetworks.socialNetworks.value", updatedNetworks);
    };

    // Handle URL change for a specific network
    const handleUrlChange = (index, value) => {
        const updatedNetworks = [...networks];
        updatedNetworks[index].url = value;

        updateNetworks(updatedNetworks);

        const isValid = validateLink(updatedNetworks[index].type, value);
        setPartner(`socialNetworks.socialNetworks[${index}].isValid`, isValid); // Optional: set validity in parent state
    };

    return (
        <FormSection title={"Réseaux sociaux"}>
            {Object.keys(socialNetworks).map((field) => (
                field === "logo" ? (
                    <div key={field} className={`form__row row__input`} id={"socialNetworks"}>
                        <label>{socialNetworks[field].label}</label>
                        <InputImages
                            name={field}
                            label={socialNetworks[field].label}
                            imagesFiles={socialNetworks[field].value}
                            onChange={(event) => setPartner(`socialNetworks.logo.value`, event)}
                            type={socialNetworks[field].type}
                            required={socialNetworks[field].required}
                            useCase={"socialNetworks"}
                            partnerId={partnerId}
                        />
                    </div>
                ) : field === "description" ? (
                    <div key={field} className={`form__row row__input`} id={"socialNetworks"}>
                        <label>{socialNetworks[field].label}</label>
                        <Textarea
                            subtitle={socialNetworks[field].subtitle}
                            placeholder={socialNetworks[field].error ? "Remplissez ce champ" : socialNetworks[field].placeholder}
                            value={socialNetworks[field].value}
                            name={field}
                            onChange={(event) => setPartner(`socialNetworks.${field}.value`, event.target.value)}
                            errorEmpty={socialNetworks[field].error}
                            errorMessage={socialNetworks[field].errorMessage}
                            validationType={socialNetworks[field].validationType}
                        />
                    </div>
                ) : field === "socialNetworks" ? (
                    <div key={field} className={`form__row row__input`} id={"socialNetworks"}>
                        <label>{socialNetworks[field].label}</label>
                        <div className={`inputs__container-social-media`}>
                            {networks.map((network, index) => (
                                <div key={index} className="row__input__radio social-network">
                                    <InputIcon
                                        type="text"
                                        name={`socialNetworkUrl-${index}`}
                                        placeholder={
                                            socialNetworkOptions.find((option) => option.value === network.type)?.placeholder
                                        }
                                        valueInput={network.url}
                                        icon={socialNetworkOptions.find((option) => option.value === network.type)?.icon}
                                        onChangeForm={(e) => handleUrlChange(index, e.target.value)}
                                        isRow={true}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null
            ))}
        </FormSection>
    );
};

export default SocialNetworks;
