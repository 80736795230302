import React, { createContext, useContext, useMemo, useState } from 'react';
import {usePartner} from "../partnerContext";
// Create the context
const StepContext = createContext();

// Provide the context
export const StepProvider = ({ children}) => {
    const [stepsConfig, setStepsConfig] = useState({});
    const [currentStepKey, setCurrentStepKey] = useState(null); // Track the current step key
    const [step, setStep] = useState(0); // Start at 0 for the "Info" page
    const { clearPartnerId, setPartnerId, currentPartnerId } = usePartner();

    // Generate the step array based on `stepsConfig`
    const stepArray = useMemo(() => {
        const steps = [];
        if (stepsConfig.verifyEmail) steps.push({ key: 'verifyEmail', label: 'Vérification de votre email' });
        if (stepsConfig.changePassword) steps.push({ key: 'changePassword', label: 'Changement de mot de passe' });
        if (stepsConfig.yourCommission) steps.push({ key: 'yourCommission', label: 'Choix de votre tarification' });
        if (stepsConfig.createStripeProfile) steps.push({ key: 'createStripeProfile', label: 'Création de votre compte Stripe' });
        return steps;
    }, [stepsConfig]);

    // Determine the next step
    const resolveNextStep = () => {
        const currentIndex = step - 1; // Adjust for "Info" page being Step 0
        if (currentIndex < 0) return stepArray[0]; // If on Info page, move to first step
        return stepArray.find((_, index) => index > currentIndex); // Find the next step
    };

    // Handle dashboard navigation based on partners
    const handleDashboardNavigation = (user, navigate) => {
        if (user.partnerID && user.partnerID.length === 1) {
            setPartnerId(user.partnerID[0]._id);
            navigate(`/dashboard/${user.partnerID[0]._id}`);
        } else if (user.partnerID && user.partnerID.length > 1) {
            navigate('/dashboard-partners');
        } else {
            navigate('/dashboard');
        }
    };

    // Handle the transition to the next step or dashboard
    const nextStep = (user, navigate) => {
        const nextStep = resolveNextStep();

        if (nextStep || nextStep !== undefined) {
            setCurrentStepKey(nextStep.key);
            setStep((prev) => prev + 1); // Move to the next step

            switch (nextStep.key) {
                case 'verifyEmail':
                    navigate('/login/first-login/verify-email');
                    break;
                case 'changePassword':
                    navigate('/login/first-login/password/change');
                    break;
                case 'yourCommission':
                    navigate('/login/first-login/subscription');
                    break;
                case 'createStripeProfile':
                    navigate('/login/first-login/stripe/config');
                    break;
                default:
                    break;
            }
        } else {
            handleDashboardNavigation(user, navigate); // Navigate to dashboard when all steps are complete
        }
    };

    const goBackToStep = (stepKey, navigate) => {
        const stepIndex = stepArray.findIndex((step) => step.key === stepKey);
        if (stepIndex >= 0) {
            setCurrentStepKey(stepKey);
            setStep(stepIndex + 1); // Move to the next step
            switch (stepKey) {
                case 'verifyEmail':
                    navigate('/login/first-login/verify-email');
                    break;
                case 'changePassword':
                    navigate('/login/first-login/password/change');
                    break;
                case 'yourCommission':
                    navigate('/login/first-login/subscription');
                    break;
                case 'createStripeProfile':
                    navigate('/login/first-login/stripe/config');
                    break;
                default:
                    break;
            }
        }
    };

    const resetSteps = () => {
        setCurrentStepKey(null);
        setStep(0); // Reset to the first step
    };

    return (
        <StepContext.Provider
            value={{
                stepsConfig,
                setStepsConfig,
                currentStepKey,
                setCurrentStepKey,
                stepArray,
                step, // Provide `step` for UI tracking
                setStep, // Provide `setStep` for manual control
                nextStep,
                goBackToStep,
                resetSteps,
            }}
        >
            {children}
        </StepContext.Provider>
    );
};

// Use the context
export const useStep = () => {
    return useContext(StepContext);
};
