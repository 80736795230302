import React, { useState, useEffect } from "react";
import '../../../styles/components/form/inputSelect.css';
import Tooltip from "../../tooltips/tooltip";

const InputSelect = ({ label, name, value, valueSelected, placeholder, onChange, options, errorMessage = false, subtitle = "", isColumn = false, isDisabled = false, hasTooltip = false, tooltipContent, isRequired = false, style }) => {

    return (
        <div className={`form__row ${isColumn ? 'column' : ''}`}>
            {
                hasTooltip ?
                    <div className={`form__notFull`}>
                        {
                            label ? <label className="form__row__label">{label}</label> : null
                        }
                        {
                            hasTooltip ?
                                <Tooltip children={
                                    tooltipContent
                                }/>
                                : null
                        }
                    </div>
                    :
                    label ? <label className="form__row__label">{label} {isRequired ? <span className={"is__required"}>*</span> : null}</label> : null
            }

            <div className={`form__col`}>
                <select name={name} className={`${errorMessage ? "error" : ""} ${isDisabled ? "disabled" : ""}`} value={valueSelected} onChange={onChange}
                        selected={valueSelected} disabled={isDisabled}>
                    <option value="">{placeholder}</option>
                    {options.map((option) => (
                        <option key={option._id} value={option.value}>{option.name}</option>
                    ))}
                </select>
                {subtitle && <p className={"text-12 subtitle"}>{subtitle}</p>}
                {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
            </div>
        </div>
    );
}

export default InputSelect;