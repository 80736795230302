import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import HeaderView from "../../components/header/headerView";
import Form from "../../components/form/form";
import Activity from "./formOffer/activity";
import Name from "./formOffer/name";
import '../../styles/views/singleOffer.css';
import useParseOffer from "../../hooks/parseDataApi/useParseOffer";
import ActivitiesServices from "../../api/services/activities";
import Modalities from "./formOffer/modalities";
import Validity from "./formOffer/validity";
import OffersServices from "../../api/services/offers";
import {useNavigate} from "react-router-dom";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import useFormValidation from "../../hooks/form/useFormValidation";
import ModalVerification from "../../components/modal/modalInfos/modalVerification";
import HelpCard from "../../components/card/helpCard";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import {useSelector} from "react-redux";
import {userPartnerUpdateOffers, userUpdateSubscription} from "../../redux/slice/authSlice";
import {useDispatch} from "react-redux";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import Conditions from "./formOffer/conditions";
import RadioRound from "../../components/form/inputRadio/radioRound";
import ModalAddOffer from "../../components/modal/modalSending/modalAddOffer";
import LastMinute from "./formOffer/lastMinute";
import {usePartner} from "../../context/partnerContext";


const AddOffer = () => {
    const partnerId = useParams().partnerId;
    const {parseOfferForApi, parseOffer} = useParseOffer();
    const { validateForm } = useFormValidation();
    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const { formState, setFormState, handleFormChange, getFormChanges } = useFormUpdate(parseOffer([]));
    const [activities, setActivities] = useState([]);

    const dispatch = useDispatch();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);

    const [insurance, setInsurance] = useState(false);


    useEffect(() => {
        setLoading(true);
        ActivitiesServices.getActivitiesByPartnerId(partnerId)
        .then((response) => {
            if(response.status === 200){
                setActivities(response.data);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    },[])


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsModalConfirmationOpen(true);
    };

    const handleAddOffer = () => {
        const isFormValid = validateForm(formState, setFormState);
        if(isFormValid.isFormValid){
            if (!insurance){
                setIsModalConfirmationOpen(false);
                showError("Accept conditions");
            }else {
                setLoading(true);
                setIsModalConfirmationOpen(false);
                const offerForApi = parseOfferForApi(getFormChanges());

                OffersServices.createOffer(partnerId, offerForApi)
                .then(response => {
                    if (response.status === 200) {
                        setIsModalAddOpen(true);
                        if(user && user.role === "partner" || user.role === "partners-admin"){
                            dispatch(userPartnerUpdateOffers({
                                partnerID: partnerId, // Assuming currentPartner has _id property
                                offers: response.data.offer._id
                            }));
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                    setIsModalConfirmationOpen(false);
                });
            }
        }
    }

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Ajouter une offre"} />


            <div className={"main-activity__container offer"}>
                <Form
                    children={
                        <>
                            <LastMinute isLastMinute={formState.isLastMinute.value} setOffer={handleFormChange}/>
                            <Activity activities={activities} setOffer={handleFormChange}
                                      offerActivity={formState.activities.value}
                                      errorEmpty={formState.activities.error}/>
                            <Name name={formState.name} setOffer={handleFormChange}/>
                            <Conditions conditionsList={formState.conditions} setOffer={handleFormChange}
                                        offerActivity={formState.activities.value}/>
                            <Modalities modalities={formState.modalities} setOffer={handleFormChange}/>
                            <Validity validity={formState.validity} isLastMinute={formState.isLastMinute.value} setOffer={handleFormChange}/>
                            <div className={"form-section__row"}>
                                <div className={"form__col__input__container__radio"}>
                                    <p className={"title-14"}>Confirmation de création d'offre <span
                                        className={"is__required"}>*</span></p>
                                    <p className={"text-14"}>Assurez-vous de bien comprendre et d'accepter les termes de
                                        votre offre promotionnelle. Cette confirmation est essentielle pour maintenir la
                                        qualité et la conformité de nos services sur Frimity</p>
                                    <div className="form__col radio">
                                        <RadioRound
                                            label={"En cochant cette case, je confirme avoir créé cette offre, je comprends les termes et m'engage à respecter les règles de Frimity, assurant l'exactitude et la conformité des informations fournies."}
                                            name={"insurance"}
                                            value={"accept"}
                                            checked={insurance}
                                            isLabel={false}
                                            onChange={(e) => {
                                                setInsurance(true)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    mode={"offer"}
                    onSubmit={handleSubmit}
                />
                <HelpCard
                    text={"Si vous avez la moindre question ou besoin d'aide, n'hésitez pas à nous contacter. Nous sommes disponibles 24/7 pour vous offrir le support dont vous avez besoin."}/>
            </div>
            <ModalAddOffer isOpen={isModalConfirmationOpen} closeModal={() => setIsModalConfirmationOpen(false)} addOffer={handleAddOffer}/>
            <ModalVerification isOpen={isModalAddOpen} link={`/partners/${partnerId}/offers`} type={"offre"}
                               action={"ajoutée"}/>
        </div>
    )
}

export default AddOffer;