import React, {useEffect, useState} from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import InputSelect from "../../../components/form/inputSelect/inputSelect";

const NbPersons = ({nbPersons, setActivity, isDisabled}) => {
    const [options, setOptions] = useState(false);

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        setActivity(`nbPersons.${name}.value`, value);
    }

    useEffect(() => {
        let options = [];

        for (let i = 1; i <= 75; i++) {
            let name = i + " personne";
            if (i > 1) {
                name += "s";
            }
            options.push({name: name, value: i});
        }
        setOptions(options);
    }, []);

    return options &&(
        <FormSectionRow title={"Nombre de personnes pouvant réaliser l'activité"} isRequired={true}>
            <div className={'form__container__row'}>
                <InputSelect
                    label={"Minimum"}
                    name={"nbPersonsMin"}
                    subtitle={"Minimum pour réaliser l’activité"}
                    value={nbPersons.nbPersonsMin.value}
                    valueSelected={nbPersons.nbPersonsMin.value}
                    onChange={handleChange}
                    errorMessage={nbPersons.nbPersonsMin.errorMessage}
                    placeholder={nbPersons.nbPersonsMin.placeholder}
                    options={options}
                    isColumn={true}
                    isDisabled={isDisabled}
                />
                <InputSelect
                    label={"Maximum"}
                    name={"nbPersonsMax"}
                    subtitle={"Maximum pour réaliser l’activité"}
                    value={nbPersons.nbPersonsMax.value}
                    valueSelected={nbPersons.nbPersonsMax.value}
                    onChange={handleChange}
                    errorMessage={nbPersons.nbPersonsMax.errorMessage}
                    placeholder={nbPersons.nbPersonsMax.placeholder}
                    options={options}
                    isColumn={true}
                    isDisabled={isDisabled}
                />
            </div>
        </FormSectionRow>
    )
}

export default NbPersons;