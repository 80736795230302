import React, { useState } from 'react';
import IconButton from "../../button/iconButton";
import useImagesHandler from "../../../hooks/parseFiles/useImagesHandler";
import trash from "../../../assets/icones/global/trash_can_red.svg";
import add from "../../../assets/icones/global/add_full.svg";
import eye from "../../../assets/icones/global/eye.svg";
import ModalFilesDeleting from "../../modal/modalDeleting/modalFilesDeleting";
import ModalImagePreview from "../../modal/modalImagePreview";
import Loader from "../../loader/loader";
import importFile from '../../../assets/icones/global/import.svg';
import LoaderAbsolute from "../../loader/loaderAbsolute";
import ActivitiesImages from "../../../api/services/activitiesImages";
import PartnersSocialNetworks from "../../../api/services/partnersSocialNetworks";

const InputImages = ({ name, onChange, type, imagesFiles, activityId, isCheck, partnerId, error, useCase }) => {
    const { images, handleChange, deleteImage, hiddenInputRef, resetInput, isLoading, addCompressedImages } = useImagesHandler({ imagesFiles, onChange, type, useCase });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [isModalImageOpen, setIsModalImageOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);

    const [isImageCompressing, setIsImageCompressing] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);


    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setSelectedFiles(selectedFiles);
        handleCompressing(selectedFiles);
    };

    const handleCompressing = async (files) => {
        if (!files || files.length === 0) {
            console.log('No files provided for compression');
            return;
        }
        const formData = new FormData();

        files.forEach((file) => {

            // Validate MIME type before appending
            if (!['image/png', 'image/jpeg', 'image/webp'].includes(file.type)) {
                console.warn('Skipping unsupported file type:', file.type);
                return;
            }

            if (useCase === "socialNetworks") {
                formData.append('logo', file); // Append as 'logo' for socialNetworks
            } else {
                formData.append('images', file); // Append as 'images' for activities
            }
        });

        for (let [key, value] of formData.entries()) {
            console.log(`FormData Key: ${key}, Value:`, value);
        }

        if (!formData.has('logo') && !formData.has('images')) {
            console.error('FormData is empty after appending files');
            return;
        }

        setIsImageCompressing(true);

        try {
            let response = null;

            if (useCase === "socialNetworks") {
                // Single file case
                response = await PartnersSocialNetworks.compressImages(partnerId, formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    },
                });

                const { file } = response.data; // Extract single file response
                if (file && file.path) {
                    // Generate preview for single file
                    const compressedImage = {
                        preview: `${process.env.REACT_APP_API_URL}/uploads/${file.path}`,
                        name: file.path.split('/').pop(),
                        size: file.size ? (file.size / 1024).toFixed(2) : "0.00", // Convert size to KB
                        isLocal: true,
                    };

                    addCompressedImages([compressedImage]); // Wrap in an array for consistency
                } else {
                    console.error('Invalid response for logo compression:', response.data);
                }
            } else {
                // Multiple file case
                response = await ActivitiesImages.compressImages(partnerId, activityId, formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    },
                });

                const filePaths = response.data.filePaths;

                if (filePaths && Array.isArray(filePaths)) {
                    // Map over filePaths and generate previews
                    const compressedImages = filePaths.map((filePath) => {
                        const { path, size } = filePath;

                        if (!path) {
                            console.warn('Invalid path in compressed images:', filePath);
                            return null; // Skip invalid entries
                        }

                        return {
                            preview: `${process.env.REACT_APP_API_URL}/uploads/${path}`,
                            name: path.split('/').pop(),
                            size: size ? (size / 1024).toFixed(2) : "0.00", // Convert size to KB
                            isLocal: true,
                        };
                    }).filter((image) => image !== null); // Filter out null entries

                    addCompressedImages(compressedImages);
                } else {
                    console.error('Invalid filePaths response from backend:', filePaths);
                }
            }
        } catch (error) {
            console.error('Error uploading and compressing images:', error);
        } finally {
            setIsImageCompressing(false);
        }
    };

    return (
        <div className={`input__images__container ${error ? 'errorImg' : ''} ${isLoading ? "center" : null}`}>
            <div className={"input__images__custom"}>
                <img src={importFile}/>
                <div className={"input__files__custom__text"}>
                    {
                        isImageCompressing ? (
                            <>
                                <progress value={uploadProgress} max="100"
                                          className={"progress-compression"}>{uploadProgress}%
                                </progress>
                                <p className="text-14">Compression... {uploadProgress}%</p>
                            </>
                        ) : (
                            <>
                                <p className={"text-14"}>
                                    <span className={"text-14 bold"}>Cliquez</span> ou glisser-déposer
                                </p>
                                <span className={"text-14"}>PNG, JPG (max 10 Mo)</span>
                            </>
                        )
                    }
                </div>
                <input
                    type="file"
                    name="images"
                    ref={hiddenInputRef}
                    onChange={handleFileChange}
                    multiple={true}
                    accept={'image/*'}
                    disabled={isLoading}
                />
            </div>
            {isLoading ? (
                <LoaderAbsolute/>
            ) : (
                <div className={"images-added"}>
                    {images && images.length > 0 && (
                        images.map((image, index) => {
                            return (
                                <div className={"image-container__col"} key={index}>
                                    <div className={"image-container download"}>
                                        <div className={"image-container__col__icons"}>
                                            <IconButton icon={eye}
                                                        onClick={() => {
                                                            setIsModalImageOpen(true);
                                                            setImagePreview(image.preview);
                                                        }}
                                                        disabled={isLoading} // Disable button if loading
                                            />
                                            <IconButton icon={trash}
                                                        onClick={() => {
                                                            setIsModalOpen(true);
                                                            setImagesToDelete(index);
                                                        }}
                                                        disabled={isLoading} // Disable button if loading
                                            />
                                        </div>
                                        <img
                                            src={image.preview}
                                            alt={image.name}
                                            onLoad={() => console.log("Image loaded successfully", image.preview)}
                                            onError={() => console.error("Error loading image", image.preview)}
                                        />
                                    </div>
                                    <span className={"text-14"}>
                                        {image.size} KB {image.dimensions ? ` - ${image.dimensions.width} x ${image.dimensions.height}px` : null}
                                    </span>
                                </div>
                            );
                        })
                    )}
                </div>
            )}


            <ModalFilesDeleting
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                deleteFile={() => {
                    deleteImage(activityId, imagesToDelete, partnerId);
                }}
            />

            <ModalImagePreview
                isOpen={isModalImageOpen}
                closeModal={() => setIsModalImageOpen(false)}
                image={imagePreview}
            />
        </div>
    );
};

export default InputImages;
