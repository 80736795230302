import React, {useContext, useEffect} from 'react';
import check from '../../../../assets/images/svg/check_round_green.svg';
import Button from "../../../../components/button/button";
import {Link, useNavigate} from "react-router-dom";
import arrow from '../../../../assets/icones/arrows/left.svg';
import '../../../../styles/views/verifications.css';
import {useDispatch, useSelector} from "react-redux";
import {setEmailVerificationStatus} from "../../../../redux/slice/signInSlice";
import Authentification from "../../../../api/services/authentification";
import {setSignInToken} from "../../../../redux/slice/signInSlice";
import {ErrorContext} from "../../../../context/errorContext";
import {useLoader} from "../../../../context/loaderContext";

const VerificationCheck = () => {

    //get local storage email
    const user = useSelector((state) => state.signIn.user);
    const email = user ? user.email : null;
    const navigate  = useNavigate();
    const dispatch = useDispatch();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    const handleVerification = () => {
        setLoading(true);
        //dispatch singIn action user.isEmailVerified = true
        dispatch(setEmailVerificationStatus());
        Authentification.generateTemporarySignInToken({userID: user._id})
        .then((response) => {
            localStorage.removeItem('emailSent');
            dispatch(setSignInToken({token: response.data.token}));
            navigate('/signin/partner');
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="main verification">
            <div className="main__container__col">
                <div className="main__container__col32">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Email verifié</h1>
                    <p className={"text-14 light"}>Votre email {email} a été vérifié avec succès.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Continuer"} isPrimary={true} isBig={true} onClick={handleVerification} />
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/signin"} className={"text-14 bold"}>Retour à l'inscription</Link>
                </div>
            </div>
        </div>
    );
}

export default VerificationCheck;