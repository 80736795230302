import React, {useState, useEffect, useContext} from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import people from "../../../assets/images/png/people_illustration.png";
import star from "../../../assets/images/svg/star_illustration.svg";
import '../../../styles/views/signin.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import StepBar from "../../../components/stepBar/stepBar";
import Inscription from "./form/inscription";
import Authentification from "../../../api/services/authentification";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {eraseAll, setEmailSent, setUserSignIn} from "../../../redux/slice/signInSlice";
import VerificationServices from "../../../api/services/verification";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";


const SignInUser = () => {

    const [step, setStep] = useState(1);
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errorEmpty, setErrorEmpty] = useState(false);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const [isUsageChecked, setIsUsageChecked] = useState(false);
    const [isConfidentialityChecked, setIsConfidentialityChecked] = useState(false);
    const [errorCheckbox, setErrorCheckbox] = useState(false);


    const [error, setError] = useState(null);

    const {register} = Authentification;
    const {sendVerificationCode} = VerificationServices;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeForm = (e) => {
        const inputName = e.target.name;
        const stateUpdaters = {
            'lastname': setLastname,
            'firstname': setFirstname,
            'email': setEmail,
            'password': setPassword,
            'passwordConfirm': setPasswordConfirm
        };
        if (stateUpdaters[inputName]) {
            stateUpdaters[inputName](e.target.value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorEmpty(false);
        setErrorCheckbox(false);

        const data = {
            lastname: lastname,
            firstname: firstname,
            email: email,
            password: password,
            role: "partner"
        }

        //check if data are not empty adn if not return error
        const errorEmpty = Object.values(data).some((x) => x === null || x === '');
        setErrorEmpty(errorEmpty);

        if (errorEmpty) {
            setLoading(false);
        } else if(password !== passwordConfirm){
            setLoading(false);
            setError("Les mots de passe ne sont pas identiques");
        } else if (!isUsageChecked || !isConfidentialityChecked){
            setLoading(false);
            setError("Veuillez accepter les conditions générales d'utilisation");
            setErrorCheckbox(true);
        }else {
            register(data).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    //dispatch signIn user
                    dispatch(setUserSignIn({user: response.data.data}));
                    setLoading(true);

                    const data = {
                        email: response.data.data.email,
                        name: response.data.data.firstname,
                        isSignIn: true,
                    };

                    sendVerificationCode(data)
                        .then(res => {
                            dispatch(setEmailSent());
                            navigate('/signin/verification/');
                        })
                        .catch(err => {
                            showError(err.response.data.message);
                        }).finally(() => {
                        setLoading(false);
                    })
                }
            }).catch((error) => {
                setError(error);
                showError(error);
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return (
        <div className="main signin">
            <div className="main__container">
                <StepBar step={step}/>
            </div>
            <div className="main__container infos">
                <div className="main__container__section">
                    <div className="main__container__form user_signin">
                        <img src={logo} alt="logo"/>
                        <Inscription
                            handleChangeForm={handleChangeForm}
                            handleSubmit={handleSubmit}
                            error={error}
                            errorEmpty={errorEmpty}
                            isUsageChecked={isUsageChecked}
                            setIsUsageChecked={setIsUsageChecked}
                            isConfidentialityChecked={isConfidentialityChecked}
                            setIsConfidentialityChecked={setIsConfidentialityChecked}
                            errorCheckbox={errorCheckbox}
                        />
                    </div>
                </div>
                <div className="main__container__image">
                    <div className={"main__container__infos"}>
                        <img src={star} alt={"stars"} className={'stars'}/>
                        <h1 className={"title-25"}>Votre visibilité ne connaît pas de limites avec Frimity</h1>
                        <p className={"text-16"}>Rejoignez Frimity pour propulser votre établissement vers de nouveaux horizons. Augmentez votre visibilité, attirez plus de clients et boostez votre activité dès aujourd'hui.</p>
                        <div className={"main-container__row invite"}>
                            <img src={people} alt={"logo"}/>
                            <p className={"text-16"}>+380 partenaires ont rejoint l'aventure 🙌🏻</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInUser;