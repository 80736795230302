import React, {useEffect, useState} from 'react';
import '../../styles/components/filtertable.css';
import '../../styles/components/button.css';
import Searchbar from "../searchbar/searchbar";
import IconButton from "../button/iconButton";
import close_stroke from "../../assets/icones/global/close-stroke.svg";
import filterIcon from "../../assets/icones/global/filter.svg";
import useOpenModal from "../../hooks/useOpenModal";
import ModalFilter from "../modal/modalTable/modalFilter";
import useStatusName from "../../hooks/parseText/useStatusName";

const FilterTable = ({filterArray, onFilterChange, typeValue, searchData, isFilter = true}) => {

    const {getStatusName} = useStatusName();
    const [filterList, setFilterList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleAddFilter = (filter) => {
        if (!filterList.includes(filter)) {
            setFilterList([...filterList, filter]);
        }
    };

    const handleRemoveFilter = (filter) => {
        const newList = filterList.filter((item) => item !== filter);
        setFilterList(newList);
    };

    useEffect(() => {
        onFilterChange({ filters: filterList, query: searchQuery });
    }, [filterList, searchQuery]);


    return(
        <div className={"filterTable"}>
            <div className={"filterTable__search"}>
                <Searchbar
                    searchData={searchData}
                    typeValue={typeValue}
                    onSearchChange={(query) => setSearchQuery(query)}
                />
            </div>
            <div className={"filterTable__table"}>
                <div className={"filterTable__table__filter_container"}>
                    {
                        filterList.map((filter, index) => {
                            return (
                                <div className={`btn icon-btn is__secondary`}>
                                    <p className={"title-14"}>{getStatusName(filter)}</p>
                                    <img src={close_stroke} alt="icon" onClick={() => handleRemoveFilter(filter)}/>
                                </div>
                            )
                        }).reverse()
                    }
                </div>
                {
                    isFilter &&
                    <div className={"filterTable__table__filter"}>
                        <div className={`btn icon-btn is__secondary`} onClick={() => setIsModalOpen(true)}>
                            <img src={filterIcon} alt="icon"/>
                            <p className={"title-14"}>Filtrer</p>
                        </div>
                        <ModalFilter isModalOpen={isModalOpen} setIsModalClose={setIsModalOpen} listFilter={filterArray} handleAddFilter={handleAddFilter}/>
                    </div>
                }
            </div>
        </div>
    );
}

export default FilterTable;


